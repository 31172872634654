import { FC, useEffect, useState } from "react"
import api from "api"
import { IAccountant } from "api/types"
import { useParams, useNavigate } from "react-router"
import LoadedAccountantProfilePage from "./Loaded"
import { ArrowBackIcon } from "assets/icons"

const AccountantProfilePage: FC = () => {
  const [user, setUser] = useState<IAccountant>()
  const id = useParams().id
  const navigate = useNavigate()

  useEffect(() => {
    if (!id) return navigate(-1)
    api.getProfile(id).then(u => u ? setUser(u as any) : navigate(-1))
  }, [id])

  if (user) return <LoadedAccountantProfilePage user={user} />

  return (
    <div className="w-full h-full overflow-hidden p-5 flex flex-col">
      <div className="flex items-center pb-5 gap-2">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 p-2 hover:bg-white
           transition-all hover:drop-shadow-sm rounded-full text-primary-blue"
        >
          <ArrowBackIcon />
        </button>
        <span className="font-semibold text-2xl text-primary-blue">Accountant Profile</span>
      </div>
      <div className="grow w-full overflow-y-auto border-t-[0.3px] border-t-[#D3E0EB] pt-4 relative">
        <div
          className="flex grow w-full gap-4 overflow-x-hidden"
        >
          <div className="rounded-2.5 bg-slate-200 p-3 w-[16.875rem] gap-2.5 h-[30rem] sticky top-0 animate-pulse" />
          <div className="grid grid-cols-2 2xl:grid-cols-4 gap-4 grow h-max pb-10">
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[10rem] rounded-2.5 bg-slate-200 animate-pulse" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2 2xl:col-span-4" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
            <div className="h-[24rem] rounded-2.5 bg-slate-200 animate-pulse col-span-2" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountantProfilePage
