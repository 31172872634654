/* eslint-disable max-len */
import { InfoIcon } from "assets/icons"
import { FC } from "react"

type Level = "low" | "average" | "high"

interface props {
    knowledge: Level;
    activity: Level; // consistency
    speed: Level;
}

export const TOP_TALENT_COLORS = {
  low: "linear-gradient(316.04deg, #FB6900 3.82%, #FB9E5B 89.78%)",
  average: "linear-gradient(316.04deg, rgb(239 212 38) 3.82%, rgb(255 232 78) 89.78%)",
  high: "rgba(29, 187, 113, 1)",
}

export const TopTalentIconsByColor = {
  [TOP_TALENT_COLORS.low]: (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="basil:user-solid" clipPath="url(#clip0_209_5502)">
        <path id="Vector" d="M13 3.75C12.0054 3.75 11.0516 4.14509 10.3483 4.84835C9.64509 5.55161 9.25 6.50544 9.25 7.5C9.25 8.49456 9.64509 9.44839 10.3483 10.1517C11.0516 10.8549 12.0054 11.25 13 11.25C13.9946 11.25 14.9484 10.8549 15.6517 10.1517C16.3549 9.44839 16.75 8.49456 16.75 7.5C16.75 6.50544 16.3549 5.55161 15.6517 4.84835C14.9484 4.14509 13.9946 3.75 13 3.75ZM9 13.25C8.00544 13.25 7.05161 13.6451 6.34835 14.3483C5.64509 15.0516 5.25 16.0054 5.25 17V18.188C5.25 18.942 5.796 19.584 6.54 19.705C10.818 20.404 15.182 20.404 19.46 19.705C19.8201 19.6464 20.1476 19.4616 20.3839 19.1837C20.6202 18.9057 20.75 18.5528 20.75 18.188V17C20.75 16.0054 20.3549 15.0516 19.6517 14.3483C18.9484 13.6451 17.9946 13.25 17 13.25H16.66C16.475 13.25 16.291 13.28 16.116 13.336L15.25 13.619C13.788 14.0962 12.212 14.0962 10.75 13.619L9.884 13.336C9.70865 13.2789 9.5254 13.2499 9.341 13.25H9Z" fill="#FB9E5B" />
        <g id="solar:star-bold" clipPath="url(#clip1_209_5502)">
          <g id="Vector_2">
            <mask id="path-2-outside-1_209_5502" maskUnits="userSpaceOnUse" x="6.83301" y="13.8333" width="12" height="11" fill="black">
              <rect fill="white" x="6.83301" y="13.8333" width="12" height="11" />
              <path d="M11.8135 16.2533C12.3414 15.3066 12.6052 14.8333 12.9998 14.8333C13.3944 14.8333 13.6581 15.3066 14.186 16.2533L14.3227 16.4983C14.4727 16.7674 14.5477 16.902 14.6644 16.9908C14.781 17.0795 14.9269 17.1124 15.2185 17.1783L15.4835 17.2383C16.5085 17.4703 17.0206 17.5862 17.1427 17.9783C17.2644 18.3699 16.9152 18.7787 16.2164 19.5958L16.0356 19.807C15.8373 20.0391 15.7377 20.1553 15.6931 20.2987C15.6485 20.4424 15.6635 20.5974 15.6935 20.907L15.721 21.1891C15.8264 22.2795 15.8794 22.8245 15.5602 23.0666C15.241 23.3091 14.761 23.0878 13.8019 22.6462L13.5531 22.532C13.2806 22.4062 13.1444 22.3437 12.9998 22.3437C12.8552 22.3437 12.7189 22.4062 12.4464 22.532L12.1981 22.6462C11.2385 23.0878 10.7585 23.3087 10.4398 23.067C10.1202 22.8245 10.1731 22.2795 10.2785 21.1891L10.306 20.9074C10.336 20.5974 10.351 20.4424 10.306 20.2991C10.2619 20.1553 10.1623 20.0391 9.96394 19.8074L9.78311 19.5958C9.08436 18.7791 8.73519 18.3703 8.85686 17.9783C8.97894 17.5862 9.49144 17.4699 10.5164 17.2383L10.7814 17.1783C11.0727 17.1124 11.2181 17.0795 11.3352 16.9908C11.4519 16.902 11.5269 16.7674 11.6769 16.4983L11.8135 16.2533Z" />
            </mask>
            <path d="M11.8135 16.2533C12.3414 15.3066 12.6052 14.8333 12.9998 14.8333C13.3944 14.8333 13.6581 15.3066 14.186 16.2533L14.3227 16.4983C14.4727 16.7674 14.5477 16.902 14.6644 16.9908C14.781 17.0795 14.9269 17.1124 15.2185 17.1783L15.4835 17.2383C16.5085 17.4703 17.0206 17.5862 17.1427 17.9783C17.2644 18.3699 16.9152 18.7787 16.2164 19.5958L16.0356 19.807C15.8373 20.0391 15.7377 20.1553 15.6931 20.2987C15.6485 20.4424 15.6635 20.5974 15.6935 20.907L15.721 21.1891C15.8264 22.2795 15.8794 22.8245 15.5602 23.0666C15.241 23.3091 14.761 23.0878 13.8019 22.6462L13.5531 22.532C13.2806 22.4062 13.1444 22.3437 12.9998 22.3437C12.8552 22.3437 12.7189 22.4062 12.4464 22.532L12.1981 22.6462C11.2385 23.0878 10.7585 23.3087 10.4398 23.067C10.1202 22.8245 10.1731 22.2795 10.2785 21.1891L10.306 20.9074C10.336 20.5974 10.351 20.4424 10.306 20.2991C10.2619 20.1553 10.1623 20.0391 9.96394 19.8074L9.78311 19.5958C9.08436 18.7791 8.73519 18.3703 8.85686 17.9783C8.97894 17.5862 9.49144 17.4699 10.5164 17.2383L10.7814 17.1783C11.0727 17.1124 11.2181 17.0795 11.3352 16.9908C11.4519 16.902 11.5269 16.7674 11.6769 16.4983L11.8135 16.2533Z" fill="#FB9E5B" />
            <path d="M11.8135 16.2533C12.3414 15.3066 12.6052 14.8333 12.9998 14.8333C13.3944 14.8333 13.6581 15.3066 14.186 16.2533L14.3227 16.4983C14.4727 16.7674 14.5477 16.902 14.6644 16.9908C14.781 17.0795 14.9269 17.1124 15.2185 17.1783L15.4835 17.2383C16.5085 17.4703 17.0206 17.5862 17.1427 17.9783C17.2644 18.3699 16.9152 18.7787 16.2164 19.5958L16.0356 19.807C15.8373 20.0391 15.7377 20.1553 15.6931 20.2987C15.6485 20.4424 15.6635 20.5974 15.6935 20.907L15.721 21.1891C15.8264 22.2795 15.8794 22.8245 15.5602 23.0666C15.241 23.3091 14.761 23.0878 13.8019 22.6462L13.5531 22.532C13.2806 22.4062 13.1444 22.3437 12.9998 22.3437C12.8552 22.3437 12.7189 22.4062 12.4464 22.532L12.1981 22.6462C11.2385 23.0878 10.7585 23.3087 10.4398 23.067C10.1202 22.8245 10.1731 22.2795 10.2785 21.1891L10.306 20.9074C10.336 20.5974 10.351 20.4424 10.306 20.2991C10.2619 20.1553 10.1623 20.0391 9.96394 19.8074L9.78311 19.5958C9.08436 18.7791 8.73519 18.3703 8.85686 17.9783C8.97894 17.5862 9.49144 17.4699 10.5164 17.2383L10.7814 17.1783C11.0727 17.1124 11.2181 17.0795 11.3352 16.9908C11.4519 16.902 11.5269 16.7674 11.6769 16.4983L11.8135 16.2533Z" stroke="white" strokeWidth="2" mask="url(#path-2-outside-1_209_5502)" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_209_5502">
          <rect width="26" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_209_5502">
          <rect width="12" height="12" fill="white" transform="translate(7 13)" />
        </clipPath>
      </defs>
    </svg>
  ),
  [TOP_TALENT_COLORS.average]: (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="basil:user-solid" clipPath="url(#clip0_209_6377)">
        <path id="Vector" d="M13 3.75C12.0054 3.75 11.0516 4.14509 10.3483 4.84835C9.64509 5.55161 9.25 6.50544 9.25 7.5C9.25 8.49456 9.64509 9.44839 10.3483 10.1517C11.0516 10.8549 12.0054 11.25 13 11.25C13.9946 11.25 14.9484 10.8549 15.6517 10.1517C16.3549 9.44839 16.75 8.49456 16.75 7.5C16.75 6.50544 16.3549 5.55161 15.6517 4.84835C14.9484 4.14509 13.9946 3.75 13 3.75ZM9 13.25C8.00544 13.25 7.05161 13.6451 6.34835 14.3483C5.64509 15.0516 5.25 16.0054 5.25 17V18.188C5.25 18.942 5.796 19.584 6.54 19.705C10.818 20.404 15.182 20.404 19.46 19.705C19.8201 19.6464 20.1476 19.4616 20.3839 19.1837C20.6202 18.9057 20.75 18.5528 20.75 18.188V17C20.75 16.0054 20.3549 15.0516 19.6517 14.3483C18.9484 13.6451 17.9946 13.25 17 13.25H16.66C16.475 13.25 16.291 13.28 16.116 13.336L15.25 13.619C13.788 14.0962 12.212 14.0962 10.75 13.619L9.884 13.336C9.70865 13.2789 9.5254 13.2499 9.341 13.25H9Z" fill="#FCE54F" />
        <g id="solar:star-bold" clipPath="url(#clip1_209_6377)">
          <g id="Vector_2">
            <mask id="path-2-outside-1_209_6377" maskUnits="userSpaceOnUse" x="1.83301" y="13.8333" width="12" height="11" fill="black">
              <rect fill="white" x="1.83301" y="13.8333" width="12" height="11" />
              <path d="M6.81352 16.2533C7.34144 15.3066 7.60519 14.8333 7.99977 14.8333C8.39436 14.8333 8.65811 15.3066 9.18602 16.2533L9.32269 16.4983C9.47269 16.7674 9.54769 16.902 9.66436 16.9908C9.78102 17.0795 9.92686 17.1124 10.2185 17.1783L10.4835 17.2383C11.5085 17.4703 12.0206 17.5862 12.1427 17.9783C12.2644 18.3699 11.9152 18.7787 11.2164 19.5958L11.0356 19.807C10.8373 20.0391 10.7377 20.1553 10.6931 20.2987C10.6485 20.4424 10.6635 20.5974 10.6935 20.907L10.721 21.1891C10.8264 22.2795 10.8794 22.8245 10.5602 23.0666C10.241 23.3091 9.76102 23.0878 8.80186 22.6462L8.55311 22.532C8.28061 22.4062 8.14436 22.3437 7.99977 22.3437C7.85519 22.3437 7.71894 22.4062 7.44644 22.532L7.19811 22.6462C6.23852 23.0878 5.75852 23.3087 5.43977 23.067C5.12019 22.8245 5.17311 22.2795 5.27852 21.1891L5.30602 20.9074C5.33602 20.5974 5.35102 20.4424 5.30602 20.2991C5.26186 20.1553 5.16227 20.0391 4.96394 19.8074L4.78311 19.5958C4.08436 18.7791 3.73519 18.3703 3.85686 17.9783C3.97894 17.5862 4.49144 17.4699 5.51644 17.2383L5.78144 17.1783C6.07269 17.1124 6.21811 17.0795 6.33519 16.9908C6.45186 16.902 6.52686 16.7674 6.67686 16.4983L6.81352 16.2533Z" />
            </mask>
            <path d="M6.81352 16.2533C7.34144 15.3066 7.60519 14.8333 7.99977 14.8333C8.39436 14.8333 8.65811 15.3066 9.18602 16.2533L9.32269 16.4983C9.47269 16.7674 9.54769 16.902 9.66436 16.9908C9.78102 17.0795 9.92686 17.1124 10.2185 17.1783L10.4835 17.2383C11.5085 17.4703 12.0206 17.5862 12.1427 17.9783C12.2644 18.3699 11.9152 18.7787 11.2164 19.5958L11.0356 19.807C10.8373 20.0391 10.7377 20.1553 10.6931 20.2987C10.6485 20.4424 10.6635 20.5974 10.6935 20.907L10.721 21.1891C10.8264 22.2795 10.8794 22.8245 10.5602 23.0666C10.241 23.3091 9.76102 23.0878 8.80186 22.6462L8.55311 22.532C8.28061 22.4062 8.14436 22.3437 7.99977 22.3437C7.85519 22.3437 7.71894 22.4062 7.44644 22.532L7.19811 22.6462C6.23852 23.0878 5.75852 23.3087 5.43977 23.067C5.12019 22.8245 5.17311 22.2795 5.27852 21.1891L5.30602 20.9074C5.33602 20.5974 5.35102 20.4424 5.30602 20.2991C5.26186 20.1553 5.16227 20.0391 4.96394 19.8074L4.78311 19.5958C4.08436 18.7791 3.73519 18.3703 3.85686 17.9783C3.97894 17.5862 4.49144 17.4699 5.51644 17.2383L5.78144 17.1783C6.07269 17.1124 6.21811 17.0795 6.33519 16.9908C6.45186 16.902 6.52686 16.7674 6.67686 16.4983L6.81352 16.2533Z" fill="#FCE54F" />
            <path d="M6.81352 16.2533C7.34144 15.3066 7.60519 14.8333 7.99977 14.8333C8.39436 14.8333 8.65811 15.3066 9.18602 16.2533L9.32269 16.4983C9.47269 16.7674 9.54769 16.902 9.66436 16.9908C9.78102 17.0795 9.92686 17.1124 10.2185 17.1783L10.4835 17.2383C11.5085 17.4703 12.0206 17.5862 12.1427 17.9783C12.2644 18.3699 11.9152 18.7787 11.2164 19.5958L11.0356 19.807C10.8373 20.0391 10.7377 20.1553 10.6931 20.2987C10.6485 20.4424 10.6635 20.5974 10.6935 20.907L10.721 21.1891C10.8264 22.2795 10.8794 22.8245 10.5602 23.0666C10.241 23.3091 9.76102 23.0878 8.80186 22.6462L8.55311 22.532C8.28061 22.4062 8.14436 22.3437 7.99977 22.3437C7.85519 22.3437 7.71894 22.4062 7.44644 22.532L7.19811 22.6462C6.23852 23.0878 5.75852 23.3087 5.43977 23.067C5.12019 22.8245 5.17311 22.2795 5.27852 21.1891L5.30602 20.9074C5.33602 20.5974 5.35102 20.4424 5.30602 20.2991C5.26186 20.1553 5.16227 20.0391 4.96394 19.8074L4.78311 19.5958C4.08436 18.7791 3.73519 18.3703 3.85686 17.9783C3.97894 17.5862 4.49144 17.4699 5.51644 17.2383L5.78144 17.1783C6.07269 17.1124 6.21811 17.0795 6.33519 16.9908C6.45186 16.902 6.52686 16.7674 6.67686 16.4983L6.81352 16.2533Z" stroke="white" strokeWidth="2" mask="url(#path-2-outside-1_209_6377)" />
          </g>
        </g>
        <g id="solar:star-bold_2" clipPath="url(#clip2_209_6377)">
          <g id="Vector_3">
            <mask id="path-3-outside-2_209_6377" maskUnits="userSpaceOnUse" x="11.833" y="13.8333" width="12" height="11" fill="black">
              <rect fill="white" x="11.833" y="13.8333" width="12" height="11" />
              <path d="M16.8135 16.2533C17.3414 15.3066 17.6052 14.8333 17.9998 14.8333C18.3944 14.8333 18.6581 15.3066 19.186 16.2533L19.3227 16.4983C19.4727 16.7674 19.5477 16.902 19.6644 16.9908C19.781 17.0795 19.9269 17.1124 20.2185 17.1783L20.4835 17.2383C21.5085 17.4703 22.0206 17.5862 22.1427 17.9783C22.2644 18.3699 21.9152 18.7787 21.2164 19.5958L21.0356 19.807C20.8373 20.0391 20.7377 20.1553 20.6931 20.2987C20.6485 20.4424 20.6635 20.5974 20.6935 20.907L20.721 21.1891C20.8264 22.2795 20.8794 22.8245 20.5602 23.0666C20.241 23.3091 19.761 23.0878 18.8019 22.6462L18.5531 22.532C18.2806 22.4062 18.1444 22.3437 17.9998 22.3437C17.8552 22.3437 17.7189 22.4062 17.4464 22.532L17.1981 22.6462C16.2385 23.0878 15.7585 23.3087 15.4398 23.067C15.1202 22.8245 15.1731 22.2795 15.2785 21.1891L15.306 20.9074C15.336 20.5974 15.351 20.4424 15.306 20.2991C15.2619 20.1553 15.1623 20.0391 14.9639 19.8074L14.7831 19.5958C14.0844 18.7791 13.7352 18.3703 13.8569 17.9783C13.9789 17.5862 14.4914 17.4699 15.5164 17.2383L15.7814 17.1783C16.0727 17.1124 16.2181 17.0795 16.3352 16.9908C16.4519 16.902 16.5269 16.7674 16.6769 16.4983L16.8135 16.2533Z" />
            </mask>
            <path d="M16.8135 16.2533C17.3414 15.3066 17.6052 14.8333 17.9998 14.8333C18.3944 14.8333 18.6581 15.3066 19.186 16.2533L19.3227 16.4983C19.4727 16.7674 19.5477 16.902 19.6644 16.9908C19.781 17.0795 19.9269 17.1124 20.2185 17.1783L20.4835 17.2383C21.5085 17.4703 22.0206 17.5862 22.1427 17.9783C22.2644 18.3699 21.9152 18.7787 21.2164 19.5958L21.0356 19.807C20.8373 20.0391 20.7377 20.1553 20.6931 20.2987C20.6485 20.4424 20.6635 20.5974 20.6935 20.907L20.721 21.1891C20.8264 22.2795 20.8794 22.8245 20.5602 23.0666C20.241 23.3091 19.761 23.0878 18.8019 22.6462L18.5531 22.532C18.2806 22.4062 18.1444 22.3437 17.9998 22.3437C17.8552 22.3437 17.7189 22.4062 17.4464 22.532L17.1981 22.6462C16.2385 23.0878 15.7585 23.3087 15.4398 23.067C15.1202 22.8245 15.1731 22.2795 15.2785 21.1891L15.306 20.9074C15.336 20.5974 15.351 20.4424 15.306 20.2991C15.2619 20.1553 15.1623 20.0391 14.9639 19.8074L14.7831 19.5958C14.0844 18.7791 13.7352 18.3703 13.8569 17.9783C13.9789 17.5862 14.4914 17.4699 15.5164 17.2383L15.7814 17.1783C16.0727 17.1124 16.2181 17.0795 16.3352 16.9908C16.4519 16.902 16.5269 16.7674 16.6769 16.4983L16.8135 16.2533Z" fill="#FCE54F" />
            <path d="M16.8135 16.2533C17.3414 15.3066 17.6052 14.8333 17.9998 14.8333C18.3944 14.8333 18.6581 15.3066 19.186 16.2533L19.3227 16.4983C19.4727 16.7674 19.5477 16.902 19.6644 16.9908C19.781 17.0795 19.9269 17.1124 20.2185 17.1783L20.4835 17.2383C21.5085 17.4703 22.0206 17.5862 22.1427 17.9783C22.2644 18.3699 21.9152 18.7787 21.2164 19.5958L21.0356 19.807C20.8373 20.0391 20.7377 20.1553 20.6931 20.2987C20.6485 20.4424 20.6635 20.5974 20.6935 20.907L20.721 21.1891C20.8264 22.2795 20.8794 22.8245 20.5602 23.0666C20.241 23.3091 19.761 23.0878 18.8019 22.6462L18.5531 22.532C18.2806 22.4062 18.1444 22.3437 17.9998 22.3437C17.8552 22.3437 17.7189 22.4062 17.4464 22.532L17.1981 22.6462C16.2385 23.0878 15.7585 23.3087 15.4398 23.067C15.1202 22.8245 15.1731 22.2795 15.2785 21.1891L15.306 20.9074C15.336 20.5974 15.351 20.4424 15.306 20.2991C15.2619 20.1553 15.1623 20.0391 14.9639 19.8074L14.7831 19.5958C14.0844 18.7791 13.7352 18.3703 13.8569 17.9783C13.9789 17.5862 14.4914 17.4699 15.5164 17.2383L15.7814 17.1783C16.0727 17.1124 16.2181 17.0795 16.3352 16.9908C16.4519 16.902 16.5269 16.7674 16.6769 16.4983L16.8135 16.2533Z" stroke="white" strokeWidth="2" mask="url(#path-3-outside-2_209_6377)" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_209_6377">
          <rect width="26" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_209_6377">
          <rect width="12" height="12" fill="white" transform="translate(2 13)" />
        </clipPath>
        <clipPath id="clip2_209_6377">
          <rect width="12" height="12" fill="white" transform="translate(12 13)" />
        </clipPath>
      </defs>
    </svg>
  ),
  [TOP_TALENT_COLORS.high]: (
    <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="basil:user-solid" clipPath="url(#clip0_209_2756)">
        <path id="Vector" d="M13 3.75C12.0054 3.75 11.0516 4.14509 10.3483 4.84835C9.64509 5.55161 9.25 6.50544 9.25 7.5C9.25 8.49456 9.64509 9.44839 10.3483 10.1517C11.0516 10.8549 12.0054 11.25 13 11.25C13.9946 11.25 14.9484 10.8549 15.6517 10.1517C16.3549 9.44839 16.75 8.49456 16.75 7.5C16.75 6.50544 16.3549 5.55161 15.6517 4.84835C14.9484 4.14509 13.9946 3.75 13 3.75ZM9 13.25C8.00544 13.25 7.05161 13.6451 6.34835 14.3483C5.64509 15.0516 5.25 16.0054 5.25 17V18.188C5.25 18.942 5.796 19.584 6.54 19.705C10.818 20.404 15.182 20.404 19.46 19.705C19.8201 19.6464 20.1476 19.4616 20.3839 19.1837C20.6202 18.9057 20.75 18.5528 20.75 18.188V17C20.75 16.0054 20.3549 15.0516 19.6517 14.3483C18.9484 13.6451 17.9946 13.25 17 13.25H16.66C16.475 13.25 16.291 13.28 16.116 13.336L15.25 13.619C13.788 14.0962 12.212 14.0962 10.75 13.619L9.884 13.336C9.70865 13.2789 9.5254 13.2499 9.341 13.25H9Z" fill="#1DB56E" />
        <g id="solar:star-bold" clipPath="url(#clip1_209_2756)">
          <g id="Vector_2">
            <mask id="path-2-outside-1_209_2756" maskUnits="userSpaceOnUse" x="-1.16699" y="13.833" width="12" height="11" fill="black">
              <rect fill="white" x="-1.16699" y="13.833" width="12" height="11" />
              <path d="M3.81352 16.253C4.34144 15.3063 4.60519 14.833 4.99977 14.833C5.39436 14.833 5.65811 15.3063 6.18602 16.253L6.32269 16.498C6.47269 16.7672 6.54769 16.9018 6.66436 16.9905C6.78102 17.0793 6.92686 17.1122 7.21852 17.178L7.48352 17.238C8.50852 17.4701 9.02061 17.5859 9.14269 17.978C9.26436 18.3697 8.91519 18.7784 8.21644 19.5955L8.0356 19.8068C7.83727 20.0388 7.73769 20.1551 7.69311 20.2984C7.64852 20.4422 7.66352 20.5972 7.69352 20.9068L7.72102 21.1888C7.82644 22.2793 7.87936 22.8243 7.56019 23.0663C7.24102 23.3088 6.76102 23.0876 5.80186 22.6459L5.55311 22.5318C5.28061 22.4059 5.14436 22.3434 4.99977 22.3434C4.85519 22.3434 4.71894 22.4059 4.44644 22.5318L4.19811 22.6459C3.23852 23.0876 2.75852 23.3084 2.43977 23.0668C2.12019 22.8243 2.17311 22.2793 2.27852 21.1888L2.30602 20.9072C2.33602 20.5972 2.35102 20.4422 2.30602 20.2988C2.26186 20.1551 2.16227 20.0388 1.96394 19.8072L1.78311 19.5955C1.08436 18.7788 0.735189 18.3701 0.856856 17.978C0.978939 17.5859 1.49144 17.4697 2.51644 17.238L2.78144 17.178C3.07269 17.1122 3.21811 17.0793 3.33519 16.9905C3.45186 16.9018 3.52686 16.7672 3.67686 16.498L3.81352 16.253Z" />
            </mask>
            <path d="M3.81352 16.253C4.34144 15.3063 4.60519 14.833 4.99977 14.833C5.39436 14.833 5.65811 15.3063 6.18602 16.253L6.32269 16.498C6.47269 16.7672 6.54769 16.9018 6.66436 16.9905C6.78102 17.0793 6.92686 17.1122 7.21852 17.178L7.48352 17.238C8.50852 17.4701 9.02061 17.5859 9.14269 17.978C9.26436 18.3697 8.91519 18.7784 8.21644 19.5955L8.0356 19.8068C7.83727 20.0388 7.73769 20.1551 7.69311 20.2984C7.64852 20.4422 7.66352 20.5972 7.69352 20.9068L7.72102 21.1888C7.82644 22.2793 7.87936 22.8243 7.56019 23.0663C7.24102 23.3088 6.76102 23.0876 5.80186 22.6459L5.55311 22.5318C5.28061 22.4059 5.14436 22.3434 4.99977 22.3434C4.85519 22.3434 4.71894 22.4059 4.44644 22.5318L4.19811 22.6459C3.23852 23.0876 2.75852 23.3084 2.43977 23.0668C2.12019 22.8243 2.17311 22.2793 2.27852 21.1888L2.30602 20.9072C2.33602 20.5972 2.35102 20.4422 2.30602 20.2988C2.26186 20.1551 2.16227 20.0388 1.96394 19.8072L1.78311 19.5955C1.08436 18.7788 0.735189 18.3701 0.856856 17.978C0.978939 17.5859 1.49144 17.4697 2.51644 17.238L2.78144 17.178C3.07269 17.1122 3.21811 17.0793 3.33519 16.9905C3.45186 16.9018 3.52686 16.7672 3.67686 16.498L3.81352 16.253Z" fill="#1DB56E" />
            <path d="M3.81352 16.253C4.34144 15.3063 4.60519 14.833 4.99977 14.833C5.39436 14.833 5.65811 15.3063 6.18602 16.253L6.32269 16.498C6.47269 16.7672 6.54769 16.9018 6.66436 16.9905C6.78102 17.0793 6.92686 17.1122 7.21852 17.178L7.48352 17.238C8.50852 17.4701 9.02061 17.5859 9.14269 17.978C9.26436 18.3697 8.91519 18.7784 8.21644 19.5955L8.0356 19.8068C7.83727 20.0388 7.73769 20.1551 7.69311 20.2984C7.64852 20.4422 7.66352 20.5972 7.69352 20.9068L7.72102 21.1888C7.82644 22.2793 7.87936 22.8243 7.56019 23.0663C7.24102 23.3088 6.76102 23.0876 5.80186 22.6459L5.55311 22.5318C5.28061 22.4059 5.14436 22.3434 4.99977 22.3434C4.85519 22.3434 4.71894 22.4059 4.44644 22.5318L4.19811 22.6459C3.23852 23.0876 2.75852 23.3084 2.43977 23.0668C2.12019 22.8243 2.17311 22.2793 2.27852 21.1888L2.30602 20.9072C2.33602 20.5972 2.35102 20.4422 2.30602 20.2988C2.26186 20.1551 2.16227 20.0388 1.96394 19.8072L1.78311 19.5955C1.08436 18.7788 0.735189 18.3701 0.856856 17.978C0.978939 17.5859 1.49144 17.4697 2.51644 17.238L2.78144 17.178C3.07269 17.1122 3.21811 17.0793 3.33519 16.9905C3.45186 16.9018 3.52686 16.7672 3.67686 16.498L3.81352 16.253Z" stroke="white" strokeWidth="2" mask="url(#path-2-outside-1_209_2756)" />
          </g>
        </g>
        <g id="solar:star-bold_2" clipPath="url(#clip2_209_2756)">
          <g id="Vector_3">
            <mask id="path-3-outside-2_209_2756" maskUnits="userSpaceOnUse" x="14.833" y="13.833" width="12" height="11" fill="black">
              <rect fill="white" x="14.833" y="13.833" width="12" height="11" />
              <path d="M19.8135 16.253C20.3414 15.3063 20.6052 14.833 20.9998 14.833C21.3944 14.833 21.6581 15.3063 22.186 16.253L22.3227 16.498C22.4727 16.7672 22.5477 16.9018 22.6644 16.9905C22.781 17.0793 22.9269 17.1122 23.2185 17.178L23.4835 17.238C24.5085 17.4701 25.0206 17.5859 25.1427 17.978C25.2644 18.3697 24.9152 18.7784 24.2164 19.5955L24.0356 19.8068C23.8373 20.0388 23.7377 20.1551 23.6931 20.2984C23.6485 20.4422 23.6635 20.5972 23.6935 20.9068L23.721 21.1888C23.8264 22.2793 23.8794 22.8243 23.5602 23.0663C23.241 23.3088 22.761 23.0876 21.8019 22.6459L21.5531 22.5318C21.2806 22.4059 21.1444 22.3434 20.9998 22.3434C20.8552 22.3434 20.7189 22.4059 20.4464 22.5318L20.1981 22.6459C19.2385 23.0876 18.7585 23.3084 18.4398 23.0668C18.1202 22.8243 18.1731 22.2793 18.2785 21.1888L18.306 20.9072C18.336 20.5972 18.351 20.4422 18.306 20.2988C18.2619 20.1551 18.1623 20.0388 17.9639 19.8072L17.7831 19.5955C17.0844 18.7788 16.7352 18.3701 16.8569 17.978C16.9789 17.5859 17.4914 17.4697 18.5164 17.238L18.7814 17.178C19.0727 17.1122 19.2181 17.0793 19.3352 16.9905C19.4519 16.9018 19.5269 16.7672 19.6769 16.498L19.8135 16.253Z" />
            </mask>
            <path d="M19.8135 16.253C20.3414 15.3063 20.6052 14.833 20.9998 14.833C21.3944 14.833 21.6581 15.3063 22.186 16.253L22.3227 16.498C22.4727 16.7672 22.5477 16.9018 22.6644 16.9905C22.781 17.0793 22.9269 17.1122 23.2185 17.178L23.4835 17.238C24.5085 17.4701 25.0206 17.5859 25.1427 17.978C25.2644 18.3697 24.9152 18.7784 24.2164 19.5955L24.0356 19.8068C23.8373 20.0388 23.7377 20.1551 23.6931 20.2984C23.6485 20.4422 23.6635 20.5972 23.6935 20.9068L23.721 21.1888C23.8264 22.2793 23.8794 22.8243 23.5602 23.0663C23.241 23.3088 22.761 23.0876 21.8019 22.6459L21.5531 22.5318C21.2806 22.4059 21.1444 22.3434 20.9998 22.3434C20.8552 22.3434 20.7189 22.4059 20.4464 22.5318L20.1981 22.6459C19.2385 23.0876 18.7585 23.3084 18.4398 23.0668C18.1202 22.8243 18.1731 22.2793 18.2785 21.1888L18.306 20.9072C18.336 20.5972 18.351 20.4422 18.306 20.2988C18.2619 20.1551 18.1623 20.0388 17.9639 19.8072L17.7831 19.5955C17.0844 18.7788 16.7352 18.3701 16.8569 17.978C16.9789 17.5859 17.4914 17.4697 18.5164 17.238L18.7814 17.178C19.0727 17.1122 19.2181 17.0793 19.3352 16.9905C19.4519 16.9018 19.5269 16.7672 19.6769 16.498L19.8135 16.253Z" fill="#1DB56E" />
            <path d="M19.8135 16.253C20.3414 15.3063 20.6052 14.833 20.9998 14.833C21.3944 14.833 21.6581 15.3063 22.186 16.253L22.3227 16.498C22.4727 16.7672 22.5477 16.9018 22.6644 16.9905C22.781 17.0793 22.9269 17.1122 23.2185 17.178L23.4835 17.238C24.5085 17.4701 25.0206 17.5859 25.1427 17.978C25.2644 18.3697 24.9152 18.7784 24.2164 19.5955L24.0356 19.8068C23.8373 20.0388 23.7377 20.1551 23.6931 20.2984C23.6485 20.4422 23.6635 20.5972 23.6935 20.9068L23.721 21.1888C23.8264 22.2793 23.8794 22.8243 23.5602 23.0663C23.241 23.3088 22.761 23.0876 21.8019 22.6459L21.5531 22.5318C21.2806 22.4059 21.1444 22.3434 20.9998 22.3434C20.8552 22.3434 20.7189 22.4059 20.4464 22.5318L20.1981 22.6459C19.2385 23.0876 18.7585 23.3084 18.4398 23.0668C18.1202 22.8243 18.1731 22.2793 18.2785 21.1888L18.306 20.9072C18.336 20.5972 18.351 20.4422 18.306 20.2988C18.2619 20.1551 18.1623 20.0388 17.9639 19.8072L17.7831 19.5955C17.0844 18.7788 16.7352 18.3701 16.8569 17.978C16.9789 17.5859 17.4914 17.4697 18.5164 17.238L18.7814 17.178C19.0727 17.1122 19.2181 17.0793 19.3352 16.9905C19.4519 16.9018 19.5269 16.7672 19.6769 16.498L19.8135 16.253Z" stroke="white" strokeWidth="2" mask="url(#path-3-outside-2_209_2756)" />
          </g>
        </g>
        <g id="solar:star-bold_3" clipPath="url(#clip3_209_2756)">
          <g id="Vector_4">
            <mask id="path-4-outside-3_209_2756" maskUnits="userSpaceOnUse" x="6.83301" y="13.833" width="12" height="11" fill="black">
              <rect fill="white" x="6.83301" y="13.833" width="12" height="11" />
              <path d="M11.8135 16.253C12.3414 15.3063 12.6052 14.833 12.9998 14.833C13.3944 14.833 13.6581 15.3063 14.186 16.253L14.3227 16.498C14.4727 16.7672 14.5477 16.9018 14.6644 16.9905C14.781 17.0793 14.9269 17.1122 15.2185 17.178L15.4835 17.238C16.5085 17.4701 17.0206 17.5859 17.1427 17.978C17.2644 18.3697 16.9152 18.7784 16.2164 19.5955L16.0356 19.8068C15.8373 20.0388 15.7377 20.1551 15.6931 20.2984C15.6485 20.4422 15.6635 20.5972 15.6935 20.9068L15.721 21.1888C15.8264 22.2793 15.8794 22.8243 15.5602 23.0663C15.241 23.3088 14.761 23.0876 13.8019 22.6459L13.5531 22.5318C13.2806 22.4059 13.1444 22.3434 12.9998 22.3434C12.8552 22.3434 12.7189 22.4059 12.4464 22.5318L12.1981 22.6459C11.2385 23.0876 10.7585 23.3084 10.4398 23.0668C10.1202 22.8243 10.1731 22.2793 10.2785 21.1888L10.306 20.9072C10.336 20.5972 10.351 20.4422 10.306 20.2988C10.2619 20.1551 10.1623 20.0388 9.96394 19.8072L9.78311 19.5955C9.08436 18.7788 8.73519 18.3701 8.85686 17.978C8.97894 17.5859 9.49144 17.4697 10.5164 17.238L10.7814 17.178C11.0727 17.1122 11.2181 17.0793 11.3352 16.9905C11.4519 16.9018 11.5269 16.7672 11.6769 16.498L11.8135 16.253Z" />
            </mask>
            <path d="M11.8135 16.253C12.3414 15.3063 12.6052 14.833 12.9998 14.833C13.3944 14.833 13.6581 15.3063 14.186 16.253L14.3227 16.498C14.4727 16.7672 14.5477 16.9018 14.6644 16.9905C14.781 17.0793 14.9269 17.1122 15.2185 17.178L15.4835 17.238C16.5085 17.4701 17.0206 17.5859 17.1427 17.978C17.2644 18.3697 16.9152 18.7784 16.2164 19.5955L16.0356 19.8068C15.8373 20.0388 15.7377 20.1551 15.6931 20.2984C15.6485 20.4422 15.6635 20.5972 15.6935 20.9068L15.721 21.1888C15.8264 22.2793 15.8794 22.8243 15.5602 23.0663C15.241 23.3088 14.761 23.0876 13.8019 22.6459L13.5531 22.5318C13.2806 22.4059 13.1444 22.3434 12.9998 22.3434C12.8552 22.3434 12.7189 22.4059 12.4464 22.5318L12.1981 22.6459C11.2385 23.0876 10.7585 23.3084 10.4398 23.0668C10.1202 22.8243 10.1731 22.2793 10.2785 21.1888L10.306 20.9072C10.336 20.5972 10.351 20.4422 10.306 20.2988C10.2619 20.1551 10.1623 20.0388 9.96394 19.8072L9.78311 19.5955C9.08436 18.7788 8.73519 18.3701 8.85686 17.978C8.97894 17.5859 9.49144 17.4697 10.5164 17.238L10.7814 17.178C11.0727 17.1122 11.2181 17.0793 11.3352 16.9905C11.4519 16.9018 11.5269 16.7672 11.6769 16.498L11.8135 16.253Z" fill="#1DB56E" />
            <path d="M11.8135 16.253C12.3414 15.3063 12.6052 14.833 12.9998 14.833C13.3944 14.833 13.6581 15.3063 14.186 16.253L14.3227 16.498C14.4727 16.7672 14.5477 16.9018 14.6644 16.9905C14.781 17.0793 14.9269 17.1122 15.2185 17.178L15.4835 17.238C16.5085 17.4701 17.0206 17.5859 17.1427 17.978C17.2644 18.3697 16.9152 18.7784 16.2164 19.5955L16.0356 19.8068C15.8373 20.0388 15.7377 20.1551 15.6931 20.2984C15.6485 20.4422 15.6635 20.5972 15.6935 20.9068L15.721 21.1888C15.8264 22.2793 15.8794 22.8243 15.5602 23.0663C15.241 23.3088 14.761 23.0876 13.8019 22.6459L13.5531 22.5318C13.2806 22.4059 13.1444 22.3434 12.9998 22.3434C12.8552 22.3434 12.7189 22.4059 12.4464 22.5318L12.1981 22.6459C11.2385 23.0876 10.7585 23.3084 10.4398 23.0668C10.1202 22.8243 10.1731 22.2793 10.2785 21.1888L10.306 20.9072C10.336 20.5972 10.351 20.4422 10.306 20.2988C10.2619 20.1551 10.1623 20.0388 9.96394 19.8072L9.78311 19.5955C9.08436 18.7788 8.73519 18.3701 8.85686 17.978C8.97894 17.5859 9.49144 17.4697 10.5164 17.238L10.7814 17.178C11.0727 17.1122 11.2181 17.0793 11.3352 16.9905C11.4519 16.9018 11.5269 16.7672 11.6769 16.498L11.8135 16.253Z" stroke="white" strokeWidth="2" mask="url(#path-4-outside-3_209_2756)" />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_209_2756">
          <rect width="26" height="24" fill="white" />
        </clipPath>
        <clipPath id="clip1_209_2756">
          <rect width="12" height="12" fill="white" transform="translate(-1 13)" />
        </clipPath>
        <clipPath id="clip2_209_2756">
          <rect width="12" height="12" fill="white" transform="translate(15 13)" />
        </clipPath>
        <clipPath id="clip3_209_2756">
          <rect width="12" height="12" fill="white" transform="translate(7 13)" />
        </clipPath>
      </defs>
    </svg>
  ),
}

const configByLevels = ({ knowledge, activity, speed }: props) => {
  if (activity === "low") {
    return ({
      color: TOP_TALENT_COLORS.low,
      text: "Inactive",
      description: "Low activity",
    })
  }
  if (activity === "average") {
    return ({
      color: TOP_TALENT_COLORS.average,
      text: "Inconsistent",
      description: "Not consistent in learning",
    })
  }

  if (knowledge === "high") {
    if (speed === "high") {
      return ({
        color: TOP_TALENT_COLORS.high,
        text: "Exceptional Talent!",
        description: "Activity + High Knowledge + High Speed",
      })
    }

    return ({
      color: TOP_TALENT_COLORS.high,
      text: "Top Talent",
      description: "Activity + High Knowledge",
    })
  }
  if (speed === "high") {
    return ({
      color: TOP_TALENT_COLORS.high,
      text: "Rising Talent",
      description: "Activity + High Speed",
    })
  }
  if (speed === "average" && knowledge === "average") {
    return ({
      color: TOP_TALENT_COLORS.high,
      text: "Above Average Learner",
      description: "Activity + Knowledge + Speed",
    })
  }
  if (speed === "low" && knowledge === "low") {
    return ({
      color: TOP_TALENT_COLORS.low,
      text: "Below Average Learner",
      description: "Activity",
    })
  }

  return ({
    color: TOP_TALENT_COLORS.average,
    text: "Average Learner",
    description: "Activity + Knowledge",
  })
}


const OverviewCard: FC<props> = ({ knowledge, activity, speed }) => {
  const { color, text, description } = configByLevels({ knowledge, activity, speed })

  return (
    <div className="flex flex-col gap-3 relative rounded-2.5 py-4 px-5 w-full drop-shadow-md" style={{ background: color }}>
      <div className="flex items-center gap-4 relative w-full">
        <div className="bg-white rounded-2.5 w-[3.25rem] h-[3.25rem] shrink-0 flex items-center justify-center">
          {TopTalentIconsByColor[color]}
        </div>
        <span className="text-lg font-semibold text-white drop-shadow-xl">
          {text}
        </span>
        <InfoIcon className="text-white absolute -right-3 -top-2" />
      </div>
      <span className="text-base text-white drop-shadow-md">
        {description}
      </span>
    </div>
  )
}

export default OverviewCard
