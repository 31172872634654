import { FirebaseOptions, initializeApp } from "firebase/app"
import { getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import { getAnalytics } from "firebase/analytics"

const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCxsop-NsNvKhbyl-LP7oZi9UAMsPB_3Ic",
  authDomain: "booksmatch-f6ac0.firebaseapp.com",
  projectId: "booksmatch-f6ac0",
  storageBucket: "booksmatch-f6ac0.appspot.com",
  messagingSenderId: "848382828758",
  appId: "1:848382828758:web:eba669864c7fb60c01e977",
  measurementId: "G-B12MJGJ2R3",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const analytics = getAnalytics(app)

const firebase = { app, auth, db, storage, analytics }

export default firebase
