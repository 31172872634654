import accountantApi from "api/accountant"
import { USCitiesByZipCode } from "assets/jsons"
import Modal from "components/Modal"
import Selector from "components/Selector"
import Tooltip from "components/Tooltip"
import useAccountant from "contexts/User/useAccountant"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import SoftwareExperience from "pages/accountant/Setup/components/SoftwareExperience"
import { useState } from "react"

interface props {
    name: string,
    bio: string,
    zip: string,
    employmentType: string
    softwareExperience: Record<string, boolean>
    linkedIn?: string
}

const EditProfile: AwaitableModal<props & {location: string}, props> = ({ initialData, open, onCancel, resolve }) => {
  const [name, setName] = useState(initialData.name)
  const [zip, setZip] = useState(initialData.zip)
  const { city, state } = USCitiesByZipCode[zip as keyof typeof USCitiesByZipCode] || {}
  const [bio, setBio] = useState(initialData.bio)
  const [linkedIn, setLinkedIn] = useState(initialData.linkedIn)
  const [employmentType, setEmploymentType] = useState<string>(initialData.employmentType)
  const [softwareExperience, setSoftwareExperience] = useState<string[]>(Object.keys(initialData.softwareExperience || {}))
  const [submitting, setSubmitting] = useState(false)
  const canHaveEmployment = !useAccountant().enterprise

  const onEdit = () => {
    if (submitting) return
    if (!name) return handleError("Please enter your name")
    if (!bio) return handleError("Please enter your biography")
    if (!zip) return handleError("Please enter your zip code")
    if (!city || !state) return handleError("Zip Code Not Found")
    if (!employmentType) return handleError("Please select your employment type")

    if (linkedIn && !linkedIn.includes("linkedin.com")) {
      return handleError("Please enter a valid LinkedIn Profile URL")
    }

    const softwareExperienceTransformed = softwareExperience.reduce((acc, item) => ({
      ...acc,
      [item]: true,
    }), {})

    setSubmitting(true)
    accountantApi.updateProfile({
      name,
      bio,
      state,
      city,
      zip,
      employmentType,
      softwareExperience: softwareExperienceTransformed,
      linkedIn,
    }).then(() => {
      const formattedState = state.toLowerCase().trim()
      const formattedCity = city.toLowerCase().trim()
      resolve({
        name,
        bio,
        zip,
        location: `${formattedCity}, ${formattedState}`,
        employmentType,
        softwareExperience: softwareExperienceTransformed,
        linkedIn,
      })
    }).finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div
        className="bg-white rounded-2.5 divide-y divide-y-slate-200
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <header className="flex items-center justify-start gap-2.5 p-4">
          <span className="font-semibold text-2xl text-slate-900">Edit Profile</span>
        </header>
        <main className="flex-cool p-4 gap-3 overflow-y-auto">
          <div className="flex-cool gap-3">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
            <textarea
              className="w-full h-[244px] resize-none"
              placeholder="Biography"
              value={bio}
              onChange={e => setBio(e.currentTarget.value)}
            />
            {canHaveEmployment && (
              <Tooltip
                position="top"
                text="Indicate whether you are looking for full time, part time, or contract work.
                 Choose “Flexible” to indicate any of the above."
                className="z-[100000000]"
              >
                <Selector value={employmentType || "Employment Type"}>
                  <div
                    onClick={e => {
                      setEmploymentType("Full Time")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Full Time
                  </div>
                  <div
                    onClick={e => {
                      setEmploymentType("Part Time")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Part Time
                  </div>
                  <div
                    onClick={e => {
                      setEmploymentType("Contract")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Contract
                  </div>
                  <div
                    onClick={e => {
                      setEmploymentType("Flexible")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Flexible
                  </div>
                </Selector>
              </Tooltip>
            )}
            <div className="relative">
              <input
                type="text"
                className="w-full"
                placeholder="Zip Code"
                value={zip}
                onChange={e => setZip(e.currentTarget.value.trim().replaceAll(" ", ""))}
              />
              <div className="absolute right-4 top-0 h-full flex items-center pointer-events-none text-slate-500">
                {!!city ? <><span>{city}</span>,&nbsp;<span>{state}</span></> : null}
              </div>
            </div>
            <SoftwareExperience experience={softwareExperience} setExperience={setSoftwareExperience} top />
          </div>
        </main>
        <footer className="p-4 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={!submitting ? onCancel : () => {}}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onEdit} className="btn-primary">Save Changes</button>
        </footer>
      </div>
    </Modal>
  )
}

export default EditProfile
