import { FC } from "react"
import CommonAppLayout from "./Common"
import { ProfileIcon, ProposalsIcon } from "assets/icons"

const AccountantAppLayout: FC = () => {
  const routes = [
    {
      name: "Profile",
      icon: ProfileIcon,
      path: "/profile",
    },
    {
      name: "Contact Tracker",
      icon: ProposalsIcon,
      path: "/proposals",
    },
  ]

  return <CommonAppLayout routes={routes} />
}

export default AccountantAppLayout
