import { FC } from "react"

interface props {
    checked: boolean
}

const Check: FC<props> = ({ checked }) => {
  return (
    <svg width="24" height="24" viewBox="4 4 16 16" className="w-3 h-3 bg-white rounded-sm" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill={checked ? "#5323FF" : "#D7DCFC"} />
      {checked && (<path d="M8.5 12L11 14.5L16 9.5" stroke="white" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />)}
    </svg>
  )
}

export default Check
