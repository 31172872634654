import { useCallback, useEffect, useState } from "react"

const useElementDimensions = (el?: HTMLElement | null) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const getSize = useCallback(() => {
    if (el) {
      const { width, height } = el.getBoundingClientRect()
      setDimensions({ width, height })
    }
  }, [el])

  useEffect(() => {
    getSize()
    if (el) {
      const ro = new ResizeObserver(getSize)
      ro.observe(el)

      return () => ro.disconnect()
    }
  }, [el, getSize])

  return dimensions
}

export default useElementDimensions
