import { ChevronIcon } from "assets/icons"
import { FC, PropsWithChildren, ReactNode, useState } from "react"

interface props {
    header: ReactNode
    initialExpanded?: boolean
}

const Section: FC<PropsWithChildren<props>> = ({ header, children, initialExpanded = false }) => {
  const [expanded, setExpanded] = useState(initialExpanded)
  const [height, setHeight] = useState(0)
  const expandable = !!children

  return (
    <div className="flex flex-col bg-primary-section rounded-2.5">
      <button
        onClick={() => setExpanded(!expanded)}
        className="flex gap-3 items-center justify-between p-3 text-base font-semibold text-primary-blue"
      >
        {header}
        {expandable && (
          <div
            className="rounded-full bg-primary-section flex items-center justify-center"
          >
            <ChevronIcon className="transform transition-transform" style={{ transform: !expanded ? "rotate(180deg)" : "rotate(0deg)" }} />
          </div>
        )}
      </button>
      {expandable && (
        <div
          ref={e => setHeight(e?.scrollHeight || 0)}
          className="overflow-hidden w-full transition-all"
          style={{ height: expanded && expandable ? height : 0, opacity: expanded && expandable ? 1 : 0 }}
        >
          {children}
        </div>
      )}
    </div>
  )
}

export default Section
