import { useCallback, useEffect, useMemo, useState } from "react"

const crossProduct = (a: { x: number, y: number }, b: { x: number, y: number }) => {
  return Math.sign(a.x * b.y - a.y * b.x)
}

function pathFinder(pieId: string, i: number) {
  let config: {
    animationInProgress?: boolean,
    pieBounds: { x: number, y: number, width: number, height: number },
    pieWidth: number,
    pieHeight: number,
    dashArray: number[],
    circleOrigin: { x: number, y: number },
    circleRadius: number,
    circleLength: number,
    rotationNumber: number,
    arcStartPoint: { x: number, y: number },
    arcEndPoint: { x: number, y: number },
    arcStartRelativeToOrigin: { x: number, y: number },
    arcEndRelativeToOrigin: { x: number, y: number },
    getPointAtArc: (percentage: number) => { x: number, y: number },
    parentOffsetBounds: (el: HTMLElement) => { x: number, y: number, width: number, height: number },
  }

  function initConfig() {
    const parentEl = document.querySelector(`[data-wrapper-for='${pieId}']`)
    const pieEl = document.getElementById(pieId)
    const relevantCircle = pieEl?.querySelector(`circle[data-piece='${i}']`) as SVGCircleElement
    if (!parentEl || !pieEl || !relevantCircle) return
    if (relevantCircle.getAnimations().filter(a => !a.finished).length > 0) {
      config = { ...config, animationInProgress: true }

      return
    }
    const parentOffsetBounds = (el: HTMLElement) => {
      const parentBounds = parentEl.getBoundingClientRect()
      const { x, y, width, height } = el.getBoundingClientRect()

      return { x: x - parentBounds.x, y: y - parentBounds.y, width, height }
    }
    const pieBounds = parentOffsetBounds(pieEl)
    const pieWidth = pieBounds.width
    const pieHeight = pieBounds.height
    const dashArray = relevantCircle.getAttribute("stroke-dasharray")?.split(" ").map(Number) || [0, 0]
    const circleOrigin = { x: pieBounds.x + pieWidth / 2, y: pieBounds.y + pieHeight / 2 }
    const circleRadius = relevantCircle.r.baseVal.value
    const circleLength = circleRadius * 2 * Math.PI
    const rotation = relevantCircle.style.transform?.split("(")[1]?.split("deg")[0] || "0"
    const rotationNumber = Number(rotation)

    const getPointAtArc = (percentage: number) => {
      const offset = dashArray[0] * (1-percentage)
      const rotationOffset = rotationNumber * circleLength / 360
      let length = rotationOffset + offset
      while (length < 0) length += circleLength
      while (length > circleLength) length -= circleLength
      try {
        const pointSvg = relevantCircle.getPointAtLength(length)
        const point = { x: pointSvg.x + pieBounds.x, y: pointSvg.y + pieBounds.y }

        return point
      } catch (e) {
        return { x: 0, y: 0 }
      }
    }

    const arcStartPoint = getPointAtArc(0)
    const arcEndPoint = getPointAtArc(1)

    const arcStartRelativeToOrigin = { x: arcStartPoint.x - circleOrigin.x, y: arcStartPoint.y - circleOrigin.y }
    const arcEndRelativeToOrigin = { x: arcEndPoint.x - circleOrigin.x, y: arcEndPoint.y - circleOrigin.y }

    config = {
      pieBounds,
      pieWidth,
      pieHeight,
      dashArray,
      circleOrigin,
      circleRadius,
      circleLength,
      rotationNumber,
      arcStartPoint,
      arcEndPoint,
      arcStartRelativeToOrigin,
      arcEndRelativeToOrigin,
      getPointAtArc,
      parentOffsetBounds,
    }
  }

  const isPointAtArc = (point: { x: number, y: number }) => {
    if (!config) return false
    const { circleOrigin, arcStartRelativeToOrigin, arcEndRelativeToOrigin } = config
    const pointRelativeToOrigin = { x: point.x - circleOrigin.x, y: point.y - circleOrigin.y }
    const startToPoint = crossProduct(arcStartRelativeToOrigin, pointRelativeToOrigin)
    const pointToEnd = crossProduct(pointRelativeToOrigin, arcEndRelativeToOrigin)

    const startToEnd = crossProduct(arcStartRelativeToOrigin, arcEndRelativeToOrigin)

    if (startToEnd === 0) return false
    if (startToEnd > 0) return (startToPoint >= 0 && pointToEnd >= 0) || (startToPoint <= 0 && pointToEnd <= 0)

    return startToEnd === startToPoint && startToEnd === pointToEnd
  }

  const findPointClosestToEl = (el: HTMLElement | null) => {
    if (!config) return { x: 0, y: 0 }
    if (!el) return { x: 0, y: 0 }
    if (config.animationInProgress) return { x: 0, y: 0 }
    const { circleOrigin, circleRadius, parentOffsetBounds } = config
    const { x, y, width, height } = parentOffsetBounds(el)
    const elCenter = { x: x + width / 2, y: y + height / 2 }
    const lineBetweenElAndCenter = { x: elCenter.x - circleOrigin.x, y: elCenter.y - circleOrigin.y }
    const orientation = 1
    const distanceToCenter = Math.sqrt(lineBetweenElAndCenter.x ** 2 + lineBetweenElAndCenter.y ** 2)
    const percentageAtRadius = circleRadius / distanceToCenter * orientation
    const pointAtRadius = {
      x: circleOrigin.x + lineBetweenElAndCenter.x * percentageAtRadius,
      y: circleOrigin.y + lineBetweenElAndCenter.y * percentageAtRadius,
    }

    const isAtArc = isPointAtArc(pointAtRadius)

    const pointRelativeToEl = (point:{x: number, y: number}) => {
      return {
        x: point.x - x,
        y: point.y - y,
      }
    }

    if (!isAtArc) return pointRelativeToEl(config.getPointAtArc(0.5))

    return pointRelativeToEl(pointAtRadius)
  }

  return {
    findPointClosestToEl,
    initConfig,
  }
}

const usePathFinder = (pieId: string, index: number, origin: HTMLElement | null) => {
  const [point, setPoint] = useState({ x: 0, y: 0 })
  const pathFinderPie = useMemo(() => pathFinder(pieId, index), [pieId, index])

  const calc = useCallback(() => {
    return pathFinderPie?.findPointClosestToEl(origin) || { x: 0, y: 0 }
  }, [pathFinderPie, origin])

  const cb = useCallback(() => {
    setPoint(calc())
  }, [calc])

  useEffect(() => {
    const parentEl = document.querySelector(`[data-wrapper-for='${pieId}']`)
    const recalculate = () => {
      pathFinderPie?.initConfig()
      cb()
    }
    recalculate()
    document.addEventListener("pieChanged", cb)
    const mo = new MutationObserver(recalculate)
    const ro = new ResizeObserver(recalculate)
    if (parentEl) {
      mo.observe(parentEl, { attributes: true, childList: true, subtree: true })
      ro.observe(parentEl)
    }


    return () => {
      mo.disconnect()
      ro.disconnect()
      document.removeEventListener("pieChanged", cb)
    }
  }, [cb, pieId, pathFinderPie])

  return point
}

export default usePathFinder
