import { FC, Fragment, SVGAttributes, useMemo } from "react"

type Piece = {
  name: string | number
  value: number
  colors: [string, string]
}

interface props extends Omit<SVGAttributes<SVGSVGElement>, "width" | "height" | "viewBox"> {
  pieces?: Piece[]
}

const emptyPieces = [
  { name: "a", value: 10, colors: ["#EDEBF0", "#DBDBDB"] as [string, string] },
  { name: "b", value: 20, colors: ["#EDEBF0", "#DBDBDB"] as [string, string] },
  { name: "c", value: 15, colors: ["#EDEBF0", "#DBDBDB"] as [string, string] },
  { name: "d", value: 30, colors: ["#EDEBF0", "#DBDBDB"] as [string, string] },
] as Piece[]

const Pie: FC<props> = ({ pieces = emptyPieces, className, children, ...svgProps }) => {
  const pieId = svgProps.id || "pie"
  const size = 200
  const outerWidth = 20
  const piecesWidth = 12
  const radius = (size - outerWidth) / 2
  const center = size / 2

  const gap = piecesWidth * 2

  const circleLength = 2 * Math.PI * radius
  const availableLength = circleLength - gap * pieces.length

  const piecesConfig = useMemo(() => {
    const piecesLength = pieces.length
    const half = Math.floor(piecesLength / 2)

    const totalMaybeZero = pieces.reduce((acc, curr) => acc + curr.value, 0)
    const total = totalMaybeZero || piecesLength

    const left = pieces.slice(0, half)
    const leftSize = left.reduce((acc, curr) => acc + curr.value, 0)
    const leftMidPoint = !totalMaybeZero ? piecesLength / 4 : leftSize / 2

    const offsetAngle = (-leftMidPoint / total) * 360
    let currentAngle = offsetAngle

    return pieces.map((piece, i) => {
      const id = `pie-${pieId}-${piece.name}`
      const percentage = totalMaybeZero ? piece.value / total : 1 / piecesLength
      const pieceLength = percentage * availableLength
      const initialRotation = currentAngle
      const angle = ((pieceLength + gap) / circleLength) * 360
      currentAngle += angle

      return { id, percentage: !totalMaybeZero ? 0 : percentage, initialRotation, pieceLength, colors: piece.colors, angle }
    })
  }, [pieces, pieId, availableLength, gap, circleLength])

  const contentSize = (2 * (radius - outerWidth / 2)) / Math.sqrt(2)
  const contentOffset = (size - contentSize) / 2

  return (
    <svg
      {...svgProps}
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      className={["!aspect-square shrink-0 transition-all", className].asClass}
    >
      <circle cx={center} cy={center} r={radius} stroke="rgba(248,248,255,1)" strokeWidth={outerWidth} fill="transparent" />
      {piecesConfig.map((piece, i) => {
        const rotation = piece.initialRotation
        const pieceLength = piece.pieceLength
        const [startColor, endColor] = piece.colors
        const gradient = `conic-gradient(${startColor} 90deg, ${endColor} ${80 + piece.angle}deg, 
          ${startColor} ${80 + piece.angle}deg, ${startColor} 450deg)`

        const dashOffset = 0

        const dashArray = [pieceLength, circleLength - pieceLength]

        const startAngle = ((pieceLength + piecesWidth / 2) / circleLength) * 360

        const maskId = `${piece.id}_mask`

        if (pieceLength < 36) return (
        // fuck gradients
          <circle
            key={piece.id}
            data-piece={i}
            cx={center}
            cy={center}
            r={radius}
            strokeWidth={piecesWidth}
            stroke={endColor}
            fill="transparent"
            className="transition-all rotate-[0deg]"
            strokeLinecap="round"
            strokeDashoffset={dashOffset}
            strokeDasharray={dashArray.join(" ")}
            style={{
              transform: `rotate(${rotation}deg)`,
              transformOrigin: "center",
              filter: piece.percentage === 0 ? "grayscale(1)" : undefined,
            }}
          />
        )

        return (
          <Fragment key={piece.id}>
            <defs>
              <mask id={maskId}>
                <rect x={0} y={0} width={size} height={size} fill="black" />
                <circle
                  key={i}
                  data-piece={i}
                  cx={center}
                  cy={center}
                  r={radius}
                  strokeWidth={piecesWidth}
                  stroke="white"
                  fill="black"
                  className="transition-all rotate-[0deg]"
                  strokeLinecap="round"
                  strokeDashoffset={dashOffset}
                  strokeDasharray={dashArray.join(" ")}
                  style={{
                    transform: `rotate(${rotation}deg)`,
                    transformOrigin: "center",
                  }}
                />
              </mask>
            </defs>
            <foreignObject mask={`url(#${maskId})`} x={0} y={0} className="overflow-visible" width={size} height={size}>
              <div
                style={{
                  background: gradient,
                  width: size,
                  height: size,
                  transform: `rotate(${rotation || startAngle + 90}deg)`,
                  transformOrigin: "center",
                  filter: piece.percentage === 0 ? "grayscale(1)" : undefined,
                }}
              />
            </foreignObject>
          </Fragment>
        )
      })}
      <foreignObject x={contentOffset} y={contentOffset} className="overflow-visible" width={contentSize} height={contentSize}>
        {children}
      </foreignObject>
    </svg>
  )
}

export default Pie
