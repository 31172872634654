import { ThankYouCheckIcon } from "assets/icons"
import TalentFinderProvider from "contexts/TalentFinder/TalentFinderProvider"
import useRecruiter from "contexts/User/useRecruiter"
import useAwaitableModal from "hooks/useAwaitableModal"
import RecruiterAppLayout from "layouts/App/Recruiter"
import RecruiterSignUp from "modals/RecruiterSignUp"
import { RecruiterProfile, TalentFinder, Candidates, Accountant } from "pages/recruiter"
import { FC, useEffect } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"
import { maybeNotSetupRef } from "."
import ChatsPage, { ChatPage } from "pages/Chat"
import EmployeesPage from "pages/recruiter/Employees"

const RecruiterRouter: FC = () => {
  const user = useRecruiter()
  const isVerified = user.verified
  const [showOnboarding, OnboardingModal] = useAwaitableModal(RecruiterSignUp, {} as any)

  useEffect(() => {
    if (user.justSignedUp) {
      maybeNotSetupRef.current?.setUser?.({ ...user, justSignedUp: false })
      showOnboarding().catch(() => {})
    }
  }, [])

  if (!isVerified) return (
    <div className="w-full h-full flex items-center justify-center bg-slate-100">
      <div className="p-8 gap-8 flex-cool bg-white rounded-3xl">
        <div className="text-center flex-cool items-center">
          <ThankYouCheckIcon />
          <span className="mt-8 text-2xl font-medium text-slate-900">Thank You</span>
          <span className="mt-0.5 text-sm text-slate-500">Thanks for signing up. We will get in touch with you soon!</span>
        </div>
      </div>
    </div>
  )

  return (
    <TalentFinderProvider>
      {OnboardingModal}
      <Routes>
        <Route path="*" element={user ? <RecruiterAppLayout /> : <Navigate to="/setup" replace />}>
          <Route path="profile" element={<RecruiterProfile />} />
          <Route path="employees" element={<EmployeesPage />} />
          <Route path="talent" element={<TalentFinder />} />
          <Route path="candidates" element={<Candidates />} />
          <Route path="accountant/:id" element={<Accountant />} />
          <Route path="chat" element={<ChatsPage />}>
            <Route
              index
              element={
                <div className="grow p-10 flex flex-col justify-start items-center">
                  <span className="text-lg text-primary-blue">No chat selected</span>
                </div>
              }
            />
            <Route path=":chatId" element={<ChatPage />} />
          </Route>
        </Route>
      </Routes>
    </TalentFinderProvider>
  )
}

export default RecruiterRouter
