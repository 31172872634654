import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { Course, IUser } from "./types"
import { availableReviews } from "constants/certifications"

class AccountantApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async signup(data: {
    bio: string,
    location: string,
    name: string,
    password: string,
    employmentType: string,
    softwareExperience: Record<string, boolean>,
    certifications: Record<string, string>,
    image?: string
  }) {
    return this.api.post("/user/accountant/setup", data).then(res => ({ ...res.data, justSignedUp: true }) as IUser)
  }

  async setVisibility(visible: boolean) {
    return this.api.patch("/user/accountant/visibility", { visible })
  }

  async updateProfile(data: {
    bio: string,
    state: string,
    city: string,
    name: string,
    zip: string,
    employmentType: string,
    softwareExperience: Record<string, boolean>,
    linkedIn?: string,
  }) {
    return this.api.patch("/user/accountant/profile", data)
  }

  async updateCertifications(data:
    {updatedOld: Record<string, string>, newCertifications: Record<string, string>}) {
    return this.api.patch("/user/accountant/profile/certifications", data).then(res => res.data as Record<string, string>)
  }

  async updateCertification(name: string, certification: string | null) {
    return this.api.patch("/user/accountant/profile/certification", { name, certification }).then(res => res.data as Record<string, string>)
  }

  async updateReviews(data:
    {reviews: Record<string, string>}) {
    const filtered = Object.keys(data.reviews).reduce((acc, key) => {
      if (availableReviews.includes(key)) {
        return { ...acc, [key]: data.reviews[key] }
      }

      return acc
    }, {} as Record<string, string>)

    return this.api.patch("/user/accountant/profile/reviews", { reviews: filtered }).then(res => res.data as Record<string, string>)
  }

  async getCandidateListCount() {
    return this.api.get("/candidates/count").then(res => res.data.count as number)
  }

  async deleteCandidate(id: string) {
    return this.api.delete(`/candidates/${id}`)
  }

  async openGym(magic: string) {
    return this.api.post("/user/accountant/gym", { magic }).then(res => res.data as {
      token: string
      courses: Record<string, Course>
    })
  }

  async shareProfile() {
    return this.api.post("/user/accountant/share").then(res => {
      const token = res.data.token as string
      const url = `${window.location.origin}/share/${token}/accountant`

      return url
    })
  }
}

const accountantApi = new AccountantApi()

export default accountantApi
