import { FC } from "react"
import CommonAppLayout from "./Common"
import { ProfileIcon, ProposalsIcon, TalentFinderIcon } from "assets/icons"
import useRecruiter from "contexts/User/useRecruiter"

const RecruiterAppLayout: FC = () => {
  const user = useRecruiter()

  const routes = [
    ...(user.isEnterprise ? [{
      name: "Manage Employees",
      icon: TalentFinderIcon,
      path: "/employees",
    }] : []), {
      name: "Talent Finder",
      icon: ProfileIcon,
      path: "/talent",
    }, {
      name: "Candidate Tracker",
      icon: ProposalsIcon,
      path: "/candidates",
    },
  ]

  return (
    <CommonAppLayout routes={routes} />
  )
}

export default RecruiterAppLayout
