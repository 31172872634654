import { IUser } from "api/types"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"

const useUser = () => {
  const maybeNotSetup = useMaybeNotSetupUser()

  if (!maybeNotSetup.user) {
    throw new Error("User is not setup")
  }

  return maybeNotSetup.user as IUser
}

export default useUser
