import useCraig from "contexts/Craig/useCraig"
import useCraigParentLocation from "hooks/craig/useCraigParentLocation"
import { FC, useCallback, useEffect, useRef, useState } from "react"
import UserBubble from "./components/UserBubble"
import CraigBubble from "./components/CraigBubble"

const CraigChatPage: FC = () => {
  const [text, setText] = useState("")
  const { messages, sendMessage, sending } = useCraig()
  const chatContainer = useRef<HTMLDivElement>(null)

  const scrollToBottom = () => {
    if (!chatContainer.current) return
    chatContainer.current.scrollTop = chatContainer.current.scrollHeight
  }

  const location = useCraigParentLocation()

  const handleSubmit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const url = location ? new URL(location) : null
    const locationWithoutQuery = url?.href.split("?")?.[0] || null
    window.parent.postMessage({ type: "craig:unread" }, "*")
    text && sendMessage(text, locationWithoutQuery, () => {
      setText("")
    })

    return false
  }, [sendMessage, text, location])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  return (
    <div className="bg-white w-full h-full flex flex-col">
      <div className="flex items-center justify-between w-full p-4 border-b border-slate-100">
        <span className="text-xl font-semibold text-slate-900 w-max">Chat with Craig</span>
        <div className="text-sm font-medium text-slate-950 flex items-center gap-2">
          <span>Active</span>
          <div className="bg-emerald-500 w-2 h-2 rounded-full" />
        </div>
      </div>
      <div ref={chatContainer} className="grow flex flex-col w-full gap-8 p-4 pb-8 overflow-y-scroll scroll-behavior-auto">
        {messages?.map(message => (
          message.user ? <UserBubble key={message.id} message={message} /> : <CraigBubble key={message.id} message={message} />
        ))}
      </div>
      <form onSubmit={handleSubmit} className="flex bg-slate-950 rounded-t-2xl" style={{ pointerEvents: sending ? "none" : "auto" }}>
        <input
          value={sending ? "..." : text}
          readOnly={sending}
          onChange={e => setText(e.currentTarget.value)}
          className="h-full p-8 grow bg-transparent outline-none text-white"
        />
        <button
          type="submit"
          disabled={sending}
          className="h-full p-8 bg-slate-900 rounded-r-2xl text-white"
        >
          Send
        </button>
      </form>
    </div>
  )
}

export default CraigChatPage
