import { PlusIcon, XIcon } from "assets/icons"
import Checkbox from "components/Checkbox"
import Selector from "components/Selector"
import { defaultSoftwareExperienceOptions } from "constants/softwareExperience"
import { Dispatch, FC, SetStateAction, useState } from "react"

interface props {
    experience: string[]
    setExperience: Dispatch<SetStateAction<string[]>>
    top?: boolean
}

const dedupe = (arr: string[]) => Array.from(new Set(arr))

const SoftwareExperience: FC<props> = ({ experience, setExperience, top }) => {
  const [available, setAvailable] = useState(dedupe([
    ...experience,
    ...defaultSoftwareExperienceOptions,
  ]))
  const [other, setOther] = useState("")

  const handleCheck = (item: string, next: boolean) => {
    if (next) {
      setExperience(old => old.filter(i => i !== item))
      setExperience(old => [...old, item])
    } else {
      if (!defaultSoftwareExperienceOptions.includes(item)) setAvailable(old => old.filter(i => i !== item))
      setExperience(old => old.filter(i => i !== item))
    }
  }

  const onAdd = () => {
    if (!other) return
    setAvailable(old => [...old.filter(i => i !== other), other])
    setExperience(old => [...old.filter(i => i !== other), other])
    setOther("")
  }

  return (
    <div className="relative w-full flex-col gap-4">
      <Selector value="Software Experience" top={top}>
        {available.map((item, index) => (
          <div
            key={item}
            onClick={e => {
              e.stopPropagation()
              handleCheck(item, !experience.includes(item))
            }}
            className="flex items-center px-5 py-4 gap-3 cursor-pointer select-none"
          >
            <Checkbox checked={experience.includes(item)} onChange={() => {}} />
            <span className="text-sm text-slate-900">
              {item}
            </span>
          </div>
        ))}
        <div
          onClick={e => {
            e.stopPropagation()
          }}
          className="flex items-center gap-3 cursor-pointer select-none pr-2"
        >
          <input
            type="text"
            value={other}
            placeholder="Other"
            onChange={e => setOther(e.currentTarget.value)}
            className="text-sm text-slate-900 px-5 py-4 !border-none grow"
          />
          <PlusIcon onClick={onAdd} className="w-6 h-6 hover:bg-slate-100 rounded-full p-1" />
        </div>
      </Selector>
      <div className="flex flex-wrap gap-4 mt-4">
        {experience.map(item => (
          <div key={item} className="bg-indigo-50 p-3 rounded-lg gap-3 flex items-center">
            <span className="text-sm text-slate-900">{item}</span>
            <XIcon className="rounded-full hover:bg-indigo-100 transition-colors cursor-pointer" onClick={() => handleCheck(item, false)} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default SoftwareExperience
