import { highActivityCutoff, lowActivityCutoff } from "constants/xp"

export const activityStatusByDate = (date?: string) => {
  if (!date) return "low"
  const today = new Date()
  const thatDate = new Date(date || today)

  const diffTime = Math.abs(thatDate.getTime() - today.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  if (diffDays > lowActivityCutoff) {
    return "low"
  }
  if (diffDays < highActivityCutoff) {
    return "high"
  }

  return "average"
}

export const daysSinceActive = (date?: string) => {
  const today = new Date()
  const thatDate = new Date(date || today)

  const diffTime = Math.abs(thatDate.getTime() - today.getTime())
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

  return diffDays
}

export const speedByFrequency = (freq?: "low" | "average" | "high") => {
  if (freq === "low") {
    return "Shining Star"
  }
  if (freq === "average") {
    return "Rising Star"
  }

  return "Rockstar"
}
