export const skillColors = {
  "Sales and Customer Management": "#00ac8e",
  "Accounting Workflows": "#56c1ff",
  "Balance Sheet Transactions": "#179181",
  "Bank Feeds and Reconciliation": "#2d8401",
  "Employees, Contractors and Payroll": "#7a7100",
  "Expense and Vendor Management": "#ff968d",
  "Inventory Management": "#ff95ca",
  "Reporting": "#feae00",
  "Special/Unique Transactions": "#ff42a1",
  "Troubleshooting": "#0076ba",
}

export const availableSkills = [
  "Sales and Customer Management",
  "Expense and Vendor Management",
  "Balance Sheet Transactions",
  "Inventory Management",
  "Special/Unique Transactions",
  "Bank Feeds and Reconciliation",
  "Employees, Contractors and Payroll",
  "Accounting Workflows",
  "Reporting",
  "Troubleshooting",
]

export const skillGroups = [
  {
    id: 3,
    name: "Sales and Customer Management",
  },
  {
    id: 4,
    name: "Expense and Vendor Management",
  },
  {
    id: 1,
    name: "Balance Sheet Transactions",
  },
  {
    id: 8,
    name: "Inventory Management",
  },
  {
    id: 6,
    name: "Special/Unique Transactions",
  },
  {
    id: 7,
    name: "Bank Feeds and Reconciliation",
  },
  {
    id: 9,
    name: "Employees, Contractors and Payroll",
  },
  {
    id: 5,
    name: "Accounting Workflows",
  },
  {
    id: 2,
    name: "Reporting",
  },
  {
    id: 10,
    name: "Troubleshooting",
  },
]
