import { FC, PropsWithChildren, useCallback, useEffect, useState } from "react"
import { ChatContext } from "./chatContext"
import { IChatThread } from "api/types"
import chatsApi from "api/chats"
import { collection, getDocs, onSnapshot, query, where } from "firebase/firestore"
import firebase from "config/firebase"

const ChatProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [threads, setThreads] = useState<IChatThread[]>([])
  const [usersLastActiveAt, setUsersLastActiveAt] = useState<{ [key: string]: string }>({})

  useEffect(() => {
    chatsApi.listChats().then(chats => {
      setThreads(chats.map(chat => ({
        ...chat,
        messages: chat.latestMessage ? [chat.latestMessage] : [],
      })))
    })
  }, [])

  useEffect(() => {
    const chatsCol = collection(firebase.db, "chats")
    const q = query(chatsCol, where("members", "array-contains", firebase.auth.currentUser?.uid))
    const listener = onSnapshot(q, snapshot => {
      snapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          chatsApi.listChats().then(chats => {
            setThreads(chats.map(chat => ({
              ...chat,
              messages: chat.latestMessage ? [chat.latestMessage] : [],
            })))
          })
        } else if (change.type === "modified") {
          setThreads(old => old.map(thread => {
            if (thread.id === change.doc.id) {
              const isNewUnread = change.doc.data().latestMessage?.author === firebase.auth.currentUser?.uid
                ? false : change.doc.data().latestMessageAt > thread.latestMessageAt

              return {
                ...thread,
                latestMessageAt: change.doc.data().latestMessageAt,
                latestMessage: change.doc.data().latestMessage,
                lastSeen: change.doc.data().lastSeen,
                unread: isNewUnread ? thread.unread + 1 : thread.unread,
              }
            }

            return thread
          }))
        }
      })
    }, console.error)

    return () => listener()
  }, [])

  useEffect(() => {
    const notMe = threads.flatMap(thread => thread.members).filter(uid => uid !== firebase.auth.currentUser?.uid)
    if (!notMe.length) return
    const userActivityCol = collection(firebase.db, "userActivity")
    const q = query(userActivityCol, where("user", "in", notMe))
    getDocs(q).then(snapshot => {

      const data: { [key: string]: string } = {}
      snapshot.forEach(doc => {
        data[doc.id] = doc.data().lastActiveAt
      })
      setUsersLastActiveAt(data)
    })
    const listener = onSnapshot(q, snapshot => {
      const data: { [key: string]: string } = {}
      snapshot.forEach(doc => {
        data[doc.id] = doc.data().lastActiveAt
      })
      setUsersLastActiveAt(data)
    }, console.error)

    return () => listener()
  }, [threads])

  const resetUnread = useCallback((chatId: string) => {
    setThreads(old => old.map(thread => {
      if (thread.id === chatId) {
        return {
          ...thread,
          unread: 0,
        }
      }

      return thread
    }))
  }, [])

  return (
    <ChatContext.Provider value={{ threads, usersLastActiveAt, resetUnread }}>
      {children}
    </ChatContext.Provider>
  )
}

export default ChatProvider
