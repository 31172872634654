import { XIcon } from "assets/icons"
import Check from "components/Check"
import Dropdown, { DropdownRef } from "components/Dropdown"
import Tooltip from "components/Tooltip"
import { availableCertifications } from "constants/certifications"
import { skillGroups } from "constants/skills"
import { FC, useRef, useState } from "react"
import { useSearchParams } from "react-router-dom"

const availableEmployment = [
  "Full Time",
  "Part Time",
  "Contract",
  "Flexible",
]

interface props {
  noEmployment?: boolean
}

const ManualFilters: FC<props> = ({ noEmployment }) => {
  const dropdownRef = useRef<DropdownRef>(null)
  const [sp, setSp] = useSearchParams()
  const [employment, setEmployment] = useState<string[]>(sp.get("employment")?.split(":") || [])
  const [certifications, setCertifications] = useState<string[]>(sp.get("certifications")?.split(":") || [])
  const [zip, setZip] = useState(sp.get("zip"))
  const [radius, setRadius] = useState(sp.get("radius")?.split(":").at(-1))
  const [skills, setSkills] = useState(sp.get("skills")?.split(":") || [])

  const apply = () => {
    setSp(old => {
      if (employment.length) {
        old.set("employment", employment.join(":"))
      } else {
        old.delete("employment")
      }
      if (certifications.length) {
        old.set("certifications", certifications.join(":"))
      } else {
        old.delete("certifications")
      }
      if (skills.length) {
        old.set("skills", skills.join(":"))
      } else {
        old.delete("skills")
      }
      if (zip) {
        old.set("zip", zip)
        if (radius) {
          old.set("radius", radius)
        } else {
          old.delete("radius")
        }
      } else {
        old.delete("zip")
      }

      return old
    })
  }

  const toggleEmployment = (value: string) => {
    setEmployment(old => {
      if (old.includes(value)) {
        return old.filter(e => e !== value)
      } else {
        return [...old, value]
      }
    })
  }

  const toggleCertification = (value: string) => {
    setCertifications(old => {
      if (old.includes(value)) {
        return old.filter(e => e !== value)
      } else {
        return [...old, value]
      }
    })
  }

  const toggleSkillGroup = (id: number) => {
    const value = id.toString()
    setSkills(old => {
      if (old.includes(value)) {
        return old.filter(e => e !== value)
      } else {
        return [...old, value]
      }
    })
  }

  const clearAndApply = () => {
    setEmployment([])
    setCertifications([])
    setZip("")
    setRadius("")
    setSp(old => {
      old.delete("employment")
      old.delete("certifications")
      old.delete("zip")
      old.delete("radius")

      return old
    })
  }

  return (
    <div className="flex items-center gap-1">
      <Dropdown ref={dropdownRef} value="Manual Filters" dontCloseOnSelect>
        <div className="flex flex-col bg-white rounded-2.5 p-2 z-[15] gap-1 max-h-[50vh] overflow-y-auto mt-2">
          {!noEmployment && (
            <>
              <div className="flex flex-col bg-primary-section rounded-2.5 p-3 gap-4">
                <span className="text-xs text-text-secondary">
                  Employment Type
                </span>
                <div className="flex flex-col gap-1">
                  {availableEmployment.map(et => (
                    <button
                      key={et}
                      onClick={() => toggleEmployment(et)}
                      className="flex items-center py-2.5 px-3 gap-1.5 rounded-2.5 text-xs font-light
             text-text-secondary bg-white hover:drop-shadow-md transition-all"
                    >
                      <Check checked={employment.includes(et)} />
                      <span>
                        {et}
                      </span>
                    </button>
                  ))}

                </div>
              </div>
              <div className="w-full h-[0.3px] shrink-0 bg-[#D3E0EB]" />
            </>
          )}
          <div className="flex flex-col bg-primary-section rounded-2.5 p-3 gap-4">
            <span className="text-xs text-text-secondary">
              Location
            </span>
            <div className="flex flex-col gap-0.5">
              <div className="flex flex-col gap-1 pb-1">
                <span className="font-light text-text-secondary text-xs">
                  Enter ZIP Code
                </span>
                <input
                  value={zip || ""}
                  onChange={e => {
                    setZip(e.currentTarget.value)
                  }}
                  placeholder="Type"
                  className="bg-white text-text-secondary font-light
                 text-xs placeholder:opacity-50 placeholder:italic p-3 rounded-2.5 outline-none"
                />
              </div>
              <div className="flex flex-col gap-1 pb-1">
                <span className="font-light text-text-secondary text-xs">
                  Add Miles Radius
                </span>
                <input
                  value={radius || ""}
                  onChange={e => {
                    setRadius(e.currentTarget.value)
                  }}
                  placeholder="Type"
                  className="bg-white text-text-secondary font-light
                 text-xs placeholder:opacity-50 placeholder:italic p-3 rounded-2.5 outline-none"
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[0.3px] shrink-0 bg-[#D3E0EB]" />
          <div className="flex flex-col bg-primary-section rounded-2.5 p-3 gap-4">
            <span className="text-xs text-text-secondary">
              Certifications
            </span>
            <div className="flex flex-col gap-1">
              {availableCertifications.map(et => (
                <button
                  key={et}
                  onClick={() => toggleCertification(et)}
                  className="flex items-center py-2.5 px-3 gap-1.5 rounded-2.5 text-xs font-light
             text-text-secondary bg-white hover:drop-shadow-md transition-all"
                >
                  <Check checked={certifications.includes(et)} />
                  <span>
                    {et}
                  </span>
                </button>
              ))}

            </div>
          </div>
          <div className="flex flex-col bg-primary-section rounded-2.5 p-3 gap-4">
            <span className="text-xs text-text-secondary">
              Skill Groups
            </span>
            <div className="flex flex-col gap-1">
              {skillGroups.map(({ id, name }) => (
                <button
                  key={id}
                  onClick={() => toggleSkillGroup(id)}
                  className="flex items-center py-2.5 px-3 gap-1.5 rounded-2.5 text-xs font-light
             text-text-secondary bg-white hover:drop-shadow-md transition-all"
                >
                  <Check checked={skills.includes(id.toString())} />
                  <span>
                    {name}
                  </span>
                </button>
              ))}

            </div>
          </div>
          <div className="w-full h-[0.3px] shrink-0 bg-[#D3E0EB]" />
          <div className="flex gap-1 justify-end">
            <button onClick={() => dropdownRef.current?.setOpen(false)} className="btn-secondary text-text-secondary !py-2 !px-4">
              Cancel
            </button>
            <button
              onClick={() => {
                apply()
                dropdownRef.current?.setOpen(false)
              }}
              className="btn-primary !py-2 !px-4"
            >
              Apply
            </button>
          </div>
        </div>
      </Dropdown>
      {employment.length > 0 || certifications.length > 0 || zip || radius ? (
        <div className="flex gap-1">
          <Tooltip text="Clear Filters" position="top">
            <button onClick={clearAndApply} className="btn-secondary text-text-secondary !p-2">
              <XIcon />
            </button>
          </Tooltip>
        </div>
      ) : null}
    </div>
  )
}

export default ManualFilters
