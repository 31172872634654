import { IAccountant } from "api/types"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"

const useAccountant = () => {
  const maybeNotSetup = useMaybeNotSetupUser()

  if (!maybeNotSetup.user) {
    throw new Error("User is not setup")
  }

  if (maybeNotSetup.user.type !== "accountant") {
    throw new Error("User is not an accountant")
  }

  return maybeNotSetup.user as unknown as IAccountant
}

export default useAccountant
