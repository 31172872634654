import { IRecruiter } from "api/types"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"

const useRecruiter = () => {
  const maybeNotSetup = useMaybeNotSetupUser()

  if (!maybeNotSetup.user) {
    throw new Error("User is not setup")
  }

  if (maybeNotSetup.user.type !== "recruiter") {
    throw new Error("User is not a recruiter")
  }

  const user = maybeNotSetup.user as unknown as IRecruiter

  const updatedSubscription = {
    ...user,
    subscription: {
      ...user.subscription,
      plan: "ultimate",
    },
  }

  return updatedSubscription as IRecruiter
}

export default useRecruiter
