import { Course } from "api/types"
import { ArrowRightIcon } from "assets/icons"
import Modal from "components/Modal"
import useAccountant from "contexts/User/useAccountant"
import { AwaitableModal } from "hooks/useAwaitableModal"

interface props {
    courses: Record<string, Course>
    token: string
}

const CoursePicker: AwaitableModal<props, props> = ({ initialData, open, onCancel, resolve }) => {
  const courses = initialData?.courses || {}
  const token = initialData?.token || ""
  const magic = useAccountant().magic
  const coursesArray = Object.entries(courses).reduce((acc, [name, course]) => {
    return [...acc, { ...course, name }]
  }, [] as (Course & {name: string})[]).sort((a, b) => {
    return b.id - a.id
  }).filter(course => course.is_assigned && !course.draft)

  const openCourse = (course: Course & {name: string}) => {
    if (!token) return
    const uri = `https://fasteasyqbo.xperiencify.io/${course.name}/?magic=${magic}&booksmatch_token=${token}`
    window.open(uri, "_blank")
  }

  return (
    <Modal open={open} close={onCancel}>
      <div
        className="bg-white rounded-2.5 divide-y divide-y-slate-200
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <header className="flex items-center justify-start gap-2.5 p-4">
          <span className="font-semibold text-2xl text-slate-900">Your Courses</span>
        </header>
        <main className="flex-cool overflow-y-auto">
          <div className="flex-cool divide-y divide-y-slate-400">
            {coursesArray?.map(course => (
              <div
                key={course.id}
                className="flex gap-3 p-3 cursor-pointer group hover:bg-slate-100 transition-colors items-center"
                onClick={() => openCourse(course)}
              >
                <img src={course.poster} className="w-12 h-12 rounded-full" />
                <div className="flex flex-col gap-px grow">
                  <span className="text-lg font-medium text-slate-950">{course.title}</span>
                  <span className="text-xs font-normal text-text-secondary">Progress: {course.progress || 0}%</span>
                </div>
                <ArrowRightIcon className="w-6 h-6 stroke-slate-400 group-hover:translate-x-0.5 transition-all" />
              </div>
            ))}
          </div>
        </main>
        <footer className="p-4 flex items-center justify-center gap-2.5">
          <button className="btn-secondary" onClick={onCancel}>
            Close
          </button>
        </footer>
      </div>
    </Modal>
  )
}

export default CoursePicker
