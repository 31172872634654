import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { IChat, IChatMessage, IChatThread } from "./types"

class ChatsApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async listChats() {
    return this.api.get("/chats").then(res => res.data as IChat[])
  }

  async getChat(chat: string) {
    return this.api.get(`/chats/${chat}`).then(res => res.data as IChatThread)
  }

  async getMessages(chat: string, cursor?: ({after: string} | {before: string}) & {cursor?: string}) {
    const sp = new URLSearchParams()
    if (cursor) {
      if ("after" in cursor) {
        sp.append("after", cursor.after)
      } else if ("before" in cursor) {
        sp.append("before", cursor.before)
      }
      if (cursor.cursor) {
        sp.append("cursor", cursor.cursor)
      }
    }

    return this.api.get(`/chats/${chat}/messages?${sp.toString()}`).then(res => res.data as {
          messages: IChatMessage[]
          lastRead?: string
          after?: string
          before?: string
        })
  }

  async sendMessage(
    chat: string,
    message: {
      content: string
      file?: {
        ref: string
        name: string
      }
    },
  ) {
    return this.api.post(`/chats/${chat}/messages`, message).then(res => res.data as {
          created: IChatMessage
        })
  }

  async searchMessages(chat: string, query: string) {
    return this.api.get(`/chats/${chat}/messages/query?q=${query}`).then(res => res.data.results as IChatMessage[])
  }
}

const chatsApi = new ChatsApi()

export default chatsApi
