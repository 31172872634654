import { Logo } from "assets"
import { ImageIcon } from "assets/icons"
import { DefaultProfileSrc } from "assets/images"
import Selector from "components/Selector"
import useAuthContext from "contexts/Auth/useAuthContext"
import useTempFile from "hooks/useTempFile"
import { ChangeEvent, FC, useCallback, useState } from "react"
import SoftwareExperience from "./components/SoftwareExperience"
import Certification from "./components/Certification"
import { handleError } from "helpers/errors"
import accountantApi from "api/accountant"
import { maybeNotSetupRef } from "routes/private"
import { availableCertifications } from "constants/certifications"

const SetupPage: FC = () => {
  const auth = useAuthContext()
  const [employmentType, setEmploymentType] = useState<string>("")
  const [softwareExperience, setSoftwareExperience] = useState<string[]>([])
  const [certifications, setCertifications] = useState<{ id: string; file: File }[]>([])
  const [certificationStates, setCertificationStates] = useState<{ id: string; ready?: boolean, name?: string }[]>([])

  const { url: profileImage, setFile, progress } = useTempFile(`/users/${auth?.uid}/profile`)
  const [name, setName] = useState(auth?.displayName || "")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [location, setLocation] = useState("")
  const [bio, setBio] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const strokeDasharray = Math.PI * 23 * 2
  const strokeDashoffset = strokeDasharray * (1 - progress)

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setFile(file)
    }
  }

  const onCertificationNameChange = useCallback((id: string, name: string) => {
    setCertificationStates(old => old.map(certification => {
      if (certification.id === id) {
        return { ...certification, name }
      }

      return certification
    }))
  }, [])

  const onCertificationReadyStateChange = useCallback((id: string, ready: boolean) => {
    setCertificationStates(old => old.map(certification => {
      if (certification.id === id) {
        return { ...certification, ready }
      }

      return certification
    }))
  }, [])

  const removeCertification = useCallback((id: string) => {
    setCertifications(old => old.filter(certification => certification.id !== id))
    setCertificationStates(old => old.filter(certification => certification.id !== id))
  }, [])

  const addCertification = useCallback((name: string, certification: File) => {
    setCertifications(old => [
      ...old.filter(cert => cert.id !== name),
      {
        id: name,
        file: certification,
      },
    ])
    setCertificationStates(old => [
      ...old.filter(cert => cert.id !== name),
      {
        id: name,
      },
    ])
  }, [])

  const onSignup = () => {
    if (submitting) return
    if (!name) return handleError("Please enter your name")
    if (!password) return handleError("Please enter your password")
    if (!confirmPassword) return handleError("Please confirm your password")
    if (password !== confirmPassword) return handleError("Passwords do not match")

    /*if (!location) return handleError("Please enter your location")
    if (!employmentType) return handleError("Please select your employment type")
    if (certificationStates.some(cert => !cert.ready)) {
      return handleError("Please wait for all certifications to upload")
    }*/
    if (progress && progress !== 1) {
      return handleError("Please wait for your profile image to upload")
    }

    setSubmitting(true)

    const certificationsTransformed = certifications.reduce((acc, { id }) => ({
      ...acc,
      [certificationStates.find(cert => cert.id === id)?.name || id]: `/users/${auth?.uid}/certifications/${id}.pdf`,
    }), {})

    const softwareExperienceTransformed = softwareExperience.reduce((acc, item) => ({
      ...acc,
      [item]: true,
    }), {})

    accountantApi.signup({
      name,
      password,
      bio,
      location: "",
      employmentType: "",
      certifications: {},
      softwareExperience: {},
    }).then(user => maybeNotSetupRef.current?.setUser?.(user)).finally(() => setSubmitting(false))
  }

  return (
    <div className="w-full bg-slate-100 h-full overflow-y-auto flex justify-center">
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <Logo className="w-8 h-auto" />
          <span className="font-semibold text-2xl text-slate-900">Create an Account</span>
        </header>
        <main className="flex-cool p-8 gap-8">
          {/*<div className="flex gap-4 items-center">
            <div className="relative">
              <svg
                className="absolute inset-0"
                viewBox="0 0 48 48"
                style={{ rotate: "-90deg" }}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="24" cy="24" r="23" className="stroke-white" strokeWidth={1} />
                <circle
                  cx="24"
                  cy="24"
                  r="23"
                  className="transition-all stroke-indigo-600"
                  strokeWidth={1}
                  strokeLinecap="round"
                  strokeDasharray={`${strokeDasharray} ${strokeDasharray}`}
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <img src={profileImage || DefaultProfileSrc} className="w-24 h-24 rounded-full p-1.5 object-cover" />
            </div>
            <label htmlFor="profile">
              <input type="file" accept="image/*" className="hidden" id="profile" onChange={onFileUpload} />
              <ImageIcon className="w-12 h-12 p-3 rounded-full bg-indigo-600 cursor-pointer hover:scale-[1.05] transition-transform" />
            </label>
  </div>*/}
          <div className="flex-cool gap-4">
            <h3 className="font-medium text-base text-slate-900">Account Details</h3>
            <div className="flex flex-col gap-4 relative z-[100000]">
              <input type="text" placeholder="Name" value={name} onChange={e => setName(e.currentTarget.value)} />
              <input type="text" placeholder="Email" disabled value={auth?.email || ""} />
              <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.currentTarget.value)} />
              <input
                type="password"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.currentTarget.value)}
              />
              {/*<div className="col-span-3 gap-4 grid grid-cols-2">
                <input type="text" placeholder="Location" value={location} onChange={e => setLocation(e.currentTarget.value)} />
                <Selector value={employmentType || "Employment Type"}>
                  <div
                    onClick={e => {
                      setEmploymentType("Full Time")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Full Time
                  </div>
                  <div
                    onClick={e => {
                      setEmploymentType("Part Time")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Part Time
                  </div>
                  <div
                    onClick={e => {
                      setEmploymentType("Contract")
                    }}
                    className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
                  >
                    Contract
                  </div>
                </Selector>
              </div>
              <input type="text" placeholder="Biography" className="col-span-3" value={bio} onChange={e => setBio(e.currentTarget.value)} />
              <div className="col-span-3">
                <SoftwareExperience experience={softwareExperience} setExperience={setSoftwareExperience} />
                  </div>*/}
            </div>
          </div>
          {/*<div className="gap-4 flex-cool">
            <div className="flex items-center gap-4">
              <h3 className="font-medium text-base text-slate-900">Certifications</h3>
              <span className="text-sm text-slate-500 grow text-right">Learn what type of certifications we support</span>
            </div>
            <div className="grid grid-cols-3 gap-8 divide-x divide-x-slate-200">
              {availableCertifications.map(name => {
                const certificationFile = certifications.find(cert => cert.id === name)?.file

                return (
                  <div className="flex flex-col gap-4 [&:not(:first-child)]:pl-8">
                    <span className="font-medium text-sm text-slate-900 grow">{name}</span>
                    {!certificationFile
                      ? (
                        <label
                          htmlFor={"certification_" + name}
                          className="border border-slate-300 cursor-pointer border-dashed rounded-lg p-2 text-sm text-slate-900"
                        >
                          <input
                            type="file"
                            accept=".pdf"
                            className="hidden"
                            multiple
                            id={"certification_" + name}
                            onInput={e => Array.from(e.currentTarget.files || []).forEach(f => addCertification(name, f))}
                          />
                          Upload
                        </label>
                      )
                      : (
                        <Certification
                          key={name}
                          certification={certificationFile}
                          onDelete={() => removeCertification(name)}
                          handleReady={ready => onCertificationReadyStateChange(name, ready)}
                          id={name}
                        />
                      )}
                  </div>
                )
              })}
            </div>
            </div>*/}
        </main>
        <footer className="p-8 flex items-center justify-between gap-4">
          <span className="text-sm text-slate-500 min-w-max">
            By signing up, you agree to
            <br />
            our Terms of Service and Privacy Policy
          </span>
          <button disabled={submitting} onClick={onSignup} className="btn-primary">Create an Account</button>
        </footer>
      </div>
    </div>
  )
}

export default SetupPage
