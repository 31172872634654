import { IAccountant } from "api/types"
import { LinkedInIcon } from "assets/icons"
import Tooltip from "components/Tooltip"
import { FC, useEffect, useState } from "react"

interface props {
    disabled?: boolean
    user: IAccountant
    change?: (url: string) => void
}

const LinkedIn: FC<props> = ({ user, disabled, change }) => {
  const canHaveLinkedIn = !user.enterprise
  const editable = !!change
  const [updating, setUpdating] = useState(false)
  const [editing, setEditing] = useState(false)
  const [url, setUrl] = useState<string | undefined>(user.linkedIn)

  const onEdit = () => {
    if (updating) return
    if (!url) return
    setUpdating(true)
    setEditing(false)
    // remove spaces and trailing slashes
    change?.(url.replace(/\s/g, "").replace(/\/$/, ""))
    setUrl(url.replace(/\s/g, "").replace(/\/$/, ""))
  }

  useEffect(() => {
    if (!disabled) {
      setUpdating(false)
    }
  }, [disabled])

  if (!canHaveLinkedIn) return null

  if (!user.linkedIn && !editable) return null

  if (!editable) {
    return (
      <div
        className={[
          "flex cursor-pointer transition-colors",
          "bg-primary-section hover:bg-[#5D5FEF08]",
          "rounded-2.5 p-4 items-center justify-center gap-2.5",
        ].asClass}
      >
        <LinkedInIcon className="w-4 h-4 shrink-0" />
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={[
            "text-xs font-normal text-[#0176B2] w-max truncate grow",
          ].asClass}
        >
          LinkedIn Profile
        </a>
      </div>
    )
  }

  return (
    <Tooltip text="Enter a valid LinkedIn URL starting with https://..." position="bottom">
      <div
        className={[
          "flex transition-colors bg-primary-section",
          "rounded-2.5 p-3 items-center justify-center gap-2.5",
          (disabled || updating) && "pointer-events-none",
          (updating) && "animate-pulse",
        ].asClass}
      >
        <LinkedInIcon className="w-4 h-4 shrink-0" />
        {editing ? (
          <input
            type="text"
            value={url}
            onChange={e => setUrl(e.target.value)}
            placeholder="LinkedIn Profile"
            className="text-xs font-normal !text-primary-blue w-max truncate grow
           border-none bg-transparent focus:outline-none !p-0 !rounded-none"
          />
        ) : (
          <a
            href={url}
            target="_blank"
            rel="noreferrer"
            className={[
              "text-base font-normal w-max truncate grow",
              "text-[#0176B2]",
            ].asClass}
          >
            LinkedIn Profile
          </a>
        )}
        {editing ? (
          <button
            onClick={onEdit}
            className="text-primary-purple text-base rounded-full hover:bg-[#5D5FEF08]
           transition-colors p-1.5 -mr-1.5 w-6 h-6 flex items-center justify-center"
          >
            ✓
          </button>
        ) : (
          <button
            onClick={() => setEditing(true)}
            disabled={updating}
            className="text-primary-purple text-base rounded-full hover:bg-[#5D5FEF08]
           transition-colors p-1.5 -mr-1.5 w-6 h-6 flex items-center justify-center"
          >
            +
          </button>
        )}
      </div>
    </Tooltip>
  )
}

export default LinkedIn
