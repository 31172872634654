import { useCallback, useEffect, useRef, useState } from "react"
import useChats from "./useChats"
import { IChatMessage, IChatThread } from "api/types"
import { IChatConcept } from "./types"
import chatsApi from "api/chats"
import firebase from "config/firebase"
import { ref, uploadBytes } from "firebase/storage"
import useAuthContext from "contexts/Auth/useAuthContext"

const useChat = (chatId: string) => {
  const user = useAuthContext()
  const { threads, resetUnread } = useChats()
  const [loading, setLoading] = useState<boolean>(false)
  const [chat, setChat] = useState<IChatThread | undefined>(threads.find(thread => thread.id === chatId))
  const [messages, setMessages] = useState<IChatMessage[]>()
  const [concept, setConcept] = useState<IChatConcept>({ content: "" })
  const [sending, setSending] = useState<boolean>(false)
  const cursor = useRef<{ after: string | null, before: string | null, moreAfter: boolean }>({
    after: null,
    before: null,
    moreAfter: false,
  })
  const [loadingNextBefore, setLoadingNextBefore] = useState<boolean>(false)
  const [loadingNextAfter, setLoadingNextAfter] = useState<boolean>(false)

  useEffect(() => {
    setChat(threads.find(thread => thread.id === chatId))
  }, [chatId, threads])

  const setSearchBeginning = useCallback((message: IChatMessage) => {
    setMessages(prev => {
      if (!prev) return
      cursor.current.before = message.createdAt
      cursor.current.after = message.createdAt
      cursor.current.moreAfter = true

      return [message]
    })
  }, [])

  useEffect(() => {
    setLoading(old => {
      if (old) return old
      chatsApi.getChat(chatId).then(chatRes => {
        cursor.current.moreAfter = true
        cursor.current.after = chatRes.messages[0]?.createdAt
        cursor.current.before = chatRes.messages.at(-1)?.createdAt || null
        setLoadingNextAfter(false)
        setLoadingNextBefore(false)
        resetUnread(chatId)
        setChat(chatRes)
        setMessages(chatRes.messages)
      }).finally(() => setLoading(false))

      return true
    })
  }, [chatId, resetUnread])

  const loadNext = useCallback((direction: "before" | "after", hasMoreAfter = false) => {
    if (hasMoreAfter) cursor.current.moreAfter = true
    if (direction === "after" && !cursor.current.moreAfter) return
    if (!cursor.current[direction]) return
    if (direction === "before" && loadingNextBefore) return
    if (direction === "after" && loadingNextAfter) return
    const currentDirValue = cursor.current[direction]?.toString()
    cursor.current[direction] = null
    if (direction === "before") {
      setLoadingNextBefore(true)
    } else {
      setLoadingNextAfter(true)
    }
    chatsApi.getMessages(chatId, { [direction]: currentDirValue } as any)
      .then(({ messages, after, before }) => {
        resetUnread(chatId)
        setMessages(prev => {
          if (!prev) return messages
          const presentIds = prev.map(message => message.id)
          const newMessages = messages.filter(message => !presentIds.includes(message.id))

          return direction === "before" ? [...newMessages, ...prev] : [...prev, ...newMessages]
        })
        if (direction === "before") {
          cursor.current.before = before || null
        } else {
          cursor.current.moreAfter = !!after
          cursor.current.after = after || null
        }
      })
      .catch(() => {
        cursor.current[direction] = currentDirValue || null
      })
      .finally(() => {
        if (direction === "before") {
          setLoadingNextBefore(false)
        } else {
          setLoadingNextAfter(false)
        }
      })

  }, [chatId, resetUnread, loadingNextBefore, loadingNextAfter])

  const sendConcept = useCallback(async(chatId: string) => {
    if (!concept.content && !concept.file) {
      return
    }
    if (sending) return
    setSending(true)

    const promise = async() => {
      if (concept.file) {
        const conceptFile = concept.file
        const dest = ref(firebase.storage, `temp/users/${user?.uid}/${new Date().getTime()}`)

        return uploadBytes(dest, concept.file).then(snapshot => {
          const file = {
            name: conceptFile.name,
            ref: snapshot.ref.fullPath,
          }

          return chatsApi.sendMessage(chatId, { content: concept.content, file })
        })
      } else {
        return chatsApi.sendMessage(chatId, { content: concept.content })
      }
    }

    return promise()
      .then(({ created }) => {
        setConcept(prev => ({ content: "" }))
        setMessages(prev => prev && [...prev, created])
      })
      .finally(() => setSending(false))
  }, [concept, user?.uid, sending])

  return {
    chat,
    messages,
    concept,
    setConcept,
    loading,
    sending,
    sendConcept,
    loadNext,
    loadingNextBefore,
    loadingNextAfter,
    setSearchBeginning,
  }
}

export default useChat
