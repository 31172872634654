import { Logo } from "assets"
import { ImageIcon } from "assets/icons"
import { DefaultProfileSrc } from "assets/images"
import Selector from "components/Selector"
import { ChangeEvent, FC, useState } from "react"
import { handleError } from "helpers/errors"
import { maybeNotSetupRef } from "routes/private"
import api from "api"
import { useNavigate } from "react-router"

const SignupPage: FC = () => {
  const [email, setEmail] = useState<string>("")
  const [type, setType] = useState<string>("")
  const [file, setFile] = useState<string>()
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const strokeDasharray = Math.PI * 23 * 2
  const strokeDashoffset = 0
  const navigate = useNavigate()

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const _file = e.target.files?.[0]
    if (_file) {
      if (_file.size > 1024 * 1024 * 1) return handleError("File size must be less than 1MB")
      const fr = new FileReader()
      fr.onload = () => {
        setFile(fr.result as string)
      }

      fr.readAsDataURL(_file)
    }
  }

  const emailValid = email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const onSignup = () => {
    if (submitting) return
    if (!email) return handleError("Please enter your email")
    if (!emailValid) return handleError("Please enter a valid email")
    if (!name) return handleError("Please enter your name")
    if (!password) return handleError("Please enter your password")
    if (!confirmPassword) return handleError("Please confirm your password")
    if (password !== confirmPassword) return handleError("Passwords do not match")
    if (!type) return handleError("Please select your recruitment type")

    setSubmitting(true)

    api.signup({
      name,
      password,
      type,
      image: file,
      email,
    }).then(user => {
      maybeNotSetupRef.current?.setUser?.({ ...user, justSignedUp: true })
    }).finally(() => setSubmitting(false))
  }

  return (
    <div className="w-full bg-slate-100 h-full overflow-y-auto flex justify-center">
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] w-full">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <Logo className="w-8 h-auto" />
          <span className="font-semibold text-2xl text-slate-900 grow">Create an Account</span>
          <span className="text-xs text-indigo-600 hover:underline font-medium cursor-pointer" onClick={() => navigate("/auth")}>
            Sign In
          </span>
        </header>
        <main className="flex-cool p-8 gap-8">
          <div className="flex gap-4 items-center">
            <div className="relative">
              <svg
                className="absolute inset-0"
                viewBox="0 0 48 48"
                style={{ rotate: "-90deg" }}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="24" cy="24" r="23" className="stroke-white" strokeWidth={1} />
                <circle
                  cx="24"
                  cy="24"
                  r="23"
                  className="transition-all stroke-indigo-600"
                  strokeWidth={1}
                  strokeLinecap="round"
                  strokeDasharray={`${strokeDasharray} ${strokeDasharray}`}
                  strokeDashoffset={strokeDashoffset}
                />
              </svg>
              <img src={file || DefaultProfileSrc} className="w-24 h-24 rounded-full p-1.5 object-cover" />
            </div>
            <label htmlFor="profile">
              <input type="file" accept="image/*" className="hidden" id="profile" onChange={onFileUpload} />
              <ImageIcon className="w-12 h-12 p-3 rounded-full bg-indigo-600 cursor-pointer hover:scale-[1.05] transition-transform" />
            </label>
          </div>
          <div className="flex-cool gap-4">
            <input type="text" placeholder="Name" value={name} onChange={e => setName(e.currentTarget.value)} />
            <Selector value={type || "Type"}>
              <div
                onClick={e => {
                  setType("Recruiter")
                }}
                className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
              >
                Recruiter
              </div>
              {/*<div
                onClick={e => {
                  setType("Potato")
                }}
                className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
              >
                Potato
              </div>*/}
            </Selector>
            <input type="text" placeholder="Email Address" value={email} onChange={e => setEmail(e.currentTarget.value)} />
            <input type="password" placeholder="Password" value={password} onChange={e => setPassword(e.currentTarget.value)} />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={e => setConfirmPassword(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-8 flex items-center justify-between">
          <span className="text-sm text-slate-500">
            By signing up, you agree to
            <br />
            our Terms of Service and Privacy Policy
          </span>
          <button disabled={submitting} onClick={onSignup} className="btn-primary">Create an Account</button>
        </footer>
      </div>
    </div>
  )
}

export default SignupPage
