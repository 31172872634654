import Modal from "components/Modal"
import { AwaitableModal } from "hooks/useAwaitableModal"

const RecruiterSignUp: AwaitableModal<{}, {}> = ({ open, resolve }) => {

  return (
    <Modal open={open} close={resolve}>
      <div
        className="bg-white rounded-3xl divide-y divide-y-slate-200 overflow-hidden
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <main className="flex-cool p-8 items-center justify-center">
          <span className="text-2xl font-medium text-slate-900">BooksMatch</span>
          <span className="text-sm text-slate-900 mt-2">Matching High-Quality Bookkeepers With Companies That Need Them</span>
          <div className="aspect-[16/9] w-[512px] bg-green-500 mt-6" />
        </main>
        <footer className="p-8 flex items-center justify-center">
          <button onClick={resolve} className="btn-primary">See It In Action</button>
        </footer>
      </div>
    </Modal>
  )
}

export default RecruiterSignUp
