import { FC } from "react"
import { ICraigRoute } from "./craigContext"
import useCraig from "./useCraig"

interface props {
    route?: ICraigRoute;
    element: JSX.Element;
}

const CraigRoute: FC<props> = ({ route, element }) => {
  const { route: currentRoute } = useCraig()

  if (!route && !currentRoute) return element

  if (route && currentRoute && route === currentRoute) return element

  return null
}

export default CraigRoute
