import api from "api"
import Modal from "components/Modal"
import Selector from "components/Selector"
import firebase from "config/firebase"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"

interface props {
  email: string
}

const EditEmail: AwaitableModal<props, props> = ({ initialData, open, onCancel, resolve }) => {
  const [email, setEmail] = useState(initialData.email)
  const [submitting, setSubmitting] = useState(false)

  const emailValid = email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const onEdit = () => {
    if (submitting) return
    if (!email) return handleError("Please enter your email")
    if (!emailValid) return handleError("Please enter a valid email")
    setSubmitting(true)
    api.updateEmail(email).then(async() => {
      await firebase.auth.currentUser?.reload()
      resolve({ email })
    }).finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <span className="font-semibold text-2xl text-slate-900">Edit Profile</span>
        </header>
        <main className="flex-cool p-8 gap-8">
          <div className="flex-cool gap-4">
            <input
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-8 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={onCancel}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onEdit} className="btn-primary">Save Changes</button>
        </footer>
      </div>
    </Modal>
  )
}

export default EditEmail
