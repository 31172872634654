import { IUser } from "api/types"
import { Dispatch, SetStateAction, createContext } from "react"

export interface IMaybeNotSetupUserContext {
    user?: IUser | null
    setUser?: Dispatch<SetStateAction<IUser | null | undefined>>
}

const MaybeNotSetupUserContext = createContext<IMaybeNotSetupUserContext>({})

export default MaybeNotSetupUserContext
