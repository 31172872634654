import { FC } from "react"
import { IAdmin, IRecruiter } from "api/types"
import AdminRouter from "./admin"
import RecruiterRouter from "./recruiter"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"

const NotAccountantRouter: FC = () => {
  const user = useMaybeNotSetupUser().user as IAdmin | IRecruiter | undefined

  if (!user) {
    return null
  }

  if (user.type === "admin") {
    return <AdminRouter />
  }

  if (user.type === "recruiter") {
    return <RecruiterRouter />
  }

  return null
}

export default NotAccountantRouter
