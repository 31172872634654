import { FC } from "react"

interface props {
    checked: boolean
    onChange: (next: boolean) => void
}

const Checkbox: FC<props> = ({ onChange, checked }) => {
  return (
    <svg onClick={() => onChange(!checked)} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" fill="white" />
      {checked && (
        <g clipPath="url(#clip0_138_2855)">
          <path d="M8.5 12L11 14.5L16 9.5" stroke="#4F46E5" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round" />
        </g>
      )}
      <rect x="0.5" y="0.5" width="23" height="23" rx="5.5" stroke="#E2E8F0" />
      <defs>
        <clipPath id="clip0_138_2855">
          <rect width="12" height="12" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Checkbox
