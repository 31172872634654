import { FC } from "react"
import CommonAppLayout from "./Common"
import { BuildingWhiteIcon, ProfileIcon, SearchIcon } from "assets/icons"

const AdminAppLayout: FC = () => {

  const routes = [{
    name: "Booksmatch Stats",
    icon: SearchIcon,
    path: "/stats",
  }, {
    name: "Accountant Finder",
    icon: ProfileIcon,
    path: "/talent",
  }, {
    name: "Enterprise Management",
    icon: BuildingWhiteIcon,
    path: "/enterprise",
  }]

  return (
    <CommonAppLayout routes={routes} />
  )
}

export default AdminAppLayout
