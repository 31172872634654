import TableSpanner from "components/TableSpanner"
import { FC } from "react"
import Tooltip from "components/Tooltip"
import Ordering from "./Ordering"
import ManualFilters from "./ManualFilters"
import { SearchIcon } from "assets/icons"

const TalentFinderLocker: FC = () => {
  const onContactSales = () => {
    const email = "anthonybirch@fastandeasyqbo.com"
    const subject = "Interested in Talent Finder"
    const body = "Hi Anthony, I'm interested in Talent Finder. Can you please provide more information?"
    window.open(`mailto:${email}?subject=${subject}&body=${body}`)
  }

  return (
    <div className="w-full h-full overflow-hidden pt-5 px-5 flex flex-col">
      <div className="flex items-center pb-5 gap-4">
        <span className="font-semibold text-2xl text-primary-blue">Talent Finder</span>
      </div>
      <div className="flex-cool border-t-[0.3px] border-t-[#D3E0EB] pt-6 relative overflow-hidden grow">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4 p-2.5 mb-5">
          <div className="relative">
            <SearchIcon className="absolute top-1/2 left-2.5 transform -translate-y-1/2 z-10" />
            <input
              className="rounded-2.5 bg-white drop-shadow-md py-3.5 pr-5
               pl-10 text-xs text-text-secondary placeholder:opacity-50 outline-none"
              placeholder="Search talents"
            />
          </div>
          <div className="flex items-center gap-5 grow">
            <div
              className="flex items-center gap-1 p-1 bg-white rounded-2.5 drop-shadow-xl"
              style={{
                boxShadow: "0px 4px 4px 0px #003F531A inset",
              }}
            >
              <button
                className={[
                  "btn-primary !py-2 !px-4 hover:rounded-full",
                ].asClass}
              >
                All Talents
              </button>
              <Tooltip text="Displays Top Talent Only" position="top">
                <button
                  className={[
                    "btn-primary !py-2 !px-4 bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white",
                  ].asClass}
                >
                  Top Talents
                </button>
              </Tooltip>
              <Tooltip text="See Candidates Upskilling The Fastest" position="top">
                <button
                  className={[
                    "btn-primary !py-2 !px-4 bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white",
                  ].asClass}
                >
                  Rising Talents
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="z-20 flex gap-5">
            <Ordering />
            <ManualFilters />
          </div>
        </div>
        <div
          className="flex-cool min-h-min
          grow
        bg-white rounded-2.5 overflow-auto"
        >
          <table className="talent-finder-table">
            <thead className="sticky top-0 z-10">
              <th>
                Talent
              </th>
              <th>
                <Tooltip
                  text="Indicates whether the candidate is looking for full time, part time, or contract work.
                  Flexible indicates they are available for any of the above."
                  position="top"
                  className="z-[15]"
                >
                  Employment
                </Tooltip>
              </th>
              <th>
                Location
              </th>
              <th className="!text-center">
                QBO Certifications
              </th>
              <th className="!text-center">
                <Tooltip
                  text="Our bookkeepers are ranked against each other based on their verified skills and
                         level of activity in our training environment,
                         so you can see who knows what they're doing"
                  position="top-end"
                  className="z-[15]"
                >
                  Knowledge
                </Tooltip>
              </th>
              <th className="!text-center">
                <Tooltip
                  text={
                    "High - Candidate upskilled within the last two weeks.\n\nLow - Candidate has not upskilled in the last 3 months."
                  }
                  position="top"
                  className="whitespace-pre-line z-[15]"
                >
                  Consistency
                </Tooltip>
              </th>
              <th className="!text-center">
                <Tooltip
                  text={`A bookkeeper's speed shows how quickly they are advancing through the training environment.
                        \nRockstar - These bookeepers are upskilling super fast!
                        Rising Star - These bookkeepers are consistently upskilling.
                        Shining Star - These bookkeepers either are upskilling slowly or have completed all of the training.
                      `}
                  position="top"
                  className="whitespace-pre-line z-[15]"
                >
                  Speed
                </Tooltip>
              </th>
              <th className="!text-center">
                Ranking
              </th>
            </thead>
            <tbody>
              <TableSpanner
                rows={5}
                cols={8}
                children={(
                  <div className="flex flex-col w-full h-full items-center justify-center gap-4">
                    <main className="flex-cool px-8 items-center justify-center">
                      <span className="text-2xl font-medium text-slate-900">Upgrade Plan</span>
                      <span className="text-sm text-slate-900 mt-2">
                        You don't have access to this feature.
                      </span>
                    </main>
                    <footer className="px-8 flex items-center justify-center">
                      <button onClick={onContactSales} className="btn-primary">Contact Sales</button>
                    </footer>
                  </div>
                )}
              />
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default TalentFinderLocker
