import { IBadgeTranslation, ITalentPreview } from "api/types"
import { Dispatch, MutableRefObject, SetStateAction, createContext } from "react"

export interface ITalentFinderContext {
    loading: boolean,
    talents?: ITalentPreview[],
    page: number,
    pages: number,
    setPage: Dispatch<SetStateAction<number>>,
    translations?: Record<string, IBadgeTranslation>
    scroll: MutableRefObject<number>
    total?: number
}

const TalentFinderContext = createContext<ITalentFinderContext>({} as ITalentFinderContext)

export default TalentFinderContext
