import { useEffect } from "react"

const useHideSupportChat = (dontHide?: boolean) => {
  useEffect(() => {
    if (dontHide) return
    const chatId = "fd-chat-client-v2"
    const chat = document.getElementById(chatId)
    if (!chat) return
    chat.style.display = "none"

    const observer = new MutationObserver(() => {
      const chat = document.getElementById(chatId)
      if (!chat) return
      chat.style.display = "none"
    })

    observer.observe(chat, { attributes: true, attributeFilter: ["style"] })

    return () => {
      chat.style.display = "block"
      observer.disconnect()
    }
  }, [dontHide])
}

export default useHideSupportChat
