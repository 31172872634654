import { useState, FC, PropsWithChildren } from "react"
import CraigContext, { ICraigRoute } from "./craigContext"
import { ICraigMessage } from "api/types"
import craigApi from "api/craig"

const CraigProvider: FC<PropsWithChildren> = ({ children }) => {
  const [messages, setMessages] = useState<ICraigMessage[]>([])
  const [route, setRoute] = useState<ICraigRoute>()
  const [sending, setSending] = useState(false)

  const sendMessage = async(message: string, url: string | null, onSuccess?: () => void) => {
    if (sending) return
    if (!messages) return
    setSending(true)
    const old = messages
    setMessages([...old, { message, user: true, id: Math.random().toString(), createdAt: new Date().toISOString() }])
    craigApi.sendMessage(message, url).then(res => {
      setMessages(old => [...(old || []), res.craigMessage])
      setSending(false)
      if (onSuccess) onSuccess()
    }).catch(() => {
      setMessages(old)
      setSending(false)
    })
  }

  /*useEffect(() => {
    craigApi.getLatestMessages().then(res => {
      setMessages(res.messages || [])
    })
  }, [])*/

  return (
    <CraigContext.Provider value={{ messages, sending, sendMessage, route, setRoute }}>
      {children}
    </CraigContext.Provider>
  )
}

export default CraigProvider
