import { User } from "firebase/auth"
import { useState, useEffect, FC, PropsWithChildren } from "react"
import firebase from "../../config/firebase"
import AuthContext from "./authContext"
import { decodeToken } from "react-jwt"

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const [user, setUser] = useState<User & {xpId?: string} | null>()

  useEffect(() => {
    const unsubscribe = firebase.auth.onAuthStateChanged(user => {
      if (user) {
        // @ts-ignore
        const decodedToken = decodeToken(user.accessToken) as {xpId?: string}
        setUser({ ...user, xpId: decodedToken?.xpId })
        try {
          localStorage.setItem("beenInApp", "true")
          const redirect = localStorage.getItem("redirect")
          if (redirect) {
            window.location.pathname = redirect
            localStorage.removeItem("redirect")
          }
        } catch (e) {
          // localStorage is disabled
        }
      } else {
        setUser(old => {
          if (old) {
            window.location.href = "/auth"
          }

          return null
        })
      }
    })

    return unsubscribe
  }, [])

  useEffect(() => {
    const sp = new URLSearchParams(window.location.search)
    if (sp.has("redirect")) {
      localStorage.setItem("redirect", sp.get("redirect") as string)
      sp.delete("redirect")
      window.history.replaceState({}, "", `${window.location.pathname}${sp.toString()}`)
    }
  }, [])

  return (
    <AuthContext.Provider value={user}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
