import Dropdown from "components/Dropdown"
import { FC, useEffect, useState } from "react"
import { useSearchParams } from "react-router-dom"

const Ordering: FC = () => {
  const [sp, setSp] = useSearchParams()
  const [ordering, setOrdering] = useState(sp.get("ordering") || "")

  useEffect(() => {
    setSp(old => {
      if (ordering) {
        old.set("ordering", ordering)
      } else {
        old.delete("ordering")
      }

      return old
    })
  }, [ordering])

  return (
    <Dropdown value="Sort by">
      <div className="flex flex-col bg-white rounded-2.5 p-2 z-[15] gap-1">
        <button
          onClick={() => setOrdering("")}
          className="px-3 py-2.5 rounded-2.5 bg-white font-light
                  group drop-shadow-sm hover:drop-shadow-md transition-all
                   text-text-secondary text-xs flex items-center gap-1"
        >
          <div className="w-3 h-3 rounded-full p-0.5 bg-[#D7DCFC]">
            <div
              className={[
                "rounded-full transition-all w-2 h-2 group-hover:bg-[#5D5FEF55]", !ordering && "bg-primary-purple",
              ].asClass}
            />
          </div>
          <span>Top Candidate First</span>
        </button>
        <button
          onClick={() => setOrdering("knowledge")}
          className="px-3 py-2.5 rounded-2.5 bg-white font-light
                  group drop-shadow-sm hover:drop-shadow-md transition-all
                   text-text-secondary text-xs flex items-center gap-1"
        >
          <div className="w-3 h-3 rounded-full p-0.5 bg-[#D7DCFC]">
            <div
              className={[
                "rounded-full transition-all w-2 h-2 group-hover:bg-[#5D5FEF55]", ordering === "knowledge" && "bg-primary-purple",
              ].asClass}
            />
          </div>
          <span>Knowledge High First</span>
        </button>
        <button
          onClick={() => setOrdering("consistency")}
          className="px-3 py-2.5 rounded-2.5 bg-white font-light
                  group drop-shadow-sm hover:drop-shadow-md transition-all
                   text-text-secondary text-xs flex items-center gap-1"
        >
          <div className="w-3 h-3 rounded-full p-0.5 bg-[#D7DCFC]">
            <div
              className={[
                "rounded-full transition-all w-2 h-2 group-hover:bg-[#5D5FEF55]", ordering === "consistency" && "bg-primary-purple",
              ].asClass}
            />
          </div>
          <span>Consistency High First</span>
        </button>
        <button
          onClick={() => setOrdering("speed")}
          className="px-3 py-2.5 rounded-2.5 bg-white font-light
                  group drop-shadow-sm hover:drop-shadow-md transition-all
                   text-text-secondary text-xs flex items-center gap-1"
        >
          <div className="w-3 h-3 rounded-full p-0.5 bg-[#D7DCFC]">
            <div
              className={[
                "rounded-full transition-all w-2 h-2 group-hover:bg-[#5D5FEF55]", ordering === "speed" && "bg-primary-purple",
              ].asClass}
            />
          </div>
          <span>Speed High First</span>
        </button>
      </div>
    </Dropdown>
  )
}

export default Ordering
