import { FC } from "react"

interface props {
    p: number
    color: string
}

const ProgressBar: FC<props> = ({ p, color }) => {
  const width = `${(p * 100)}%`

  return (
    <div className="bg-border-section h-[0.188rem] w-full rounded-full">
      <div className="h-full rounded-full relative transition-all" style={{ backgroundColor: color, width, maxWidth: width }}>
        <div
          className="absolute border-[0.188rem] top-1/2 -translate-y-1/2 translate-x-1/2
           border-white w-4 h-4 rounded-full right-0 drop-shadow-md"
          style={{ backgroundColor: color }}
        />
      </div>
    </div>
  )
}

export default ProgressBar

