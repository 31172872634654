import { Logo } from "assets"
import firebase from "config/firebase"
import { FirebaseError } from "firebase/app"
import { confirmPasswordReset } from "firebase/auth"
import { handleError } from "helpers/errors"
import { FC, useState } from "react"
import { Navigate, useNavigate } from "react-router"
import { useSearchParams } from "react-router-dom"

const ResetPasswordPage: FC = () => {
  const [sp] = useSearchParams()
  const [password, setPassword] = useState("")
  const [confirmPassword, setConfirmPassword] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [changed, setChanged] = useState(false)
  const navigate = useNavigate()
  const code = sp.get("code")

  const onSubmit = () => {
    if (submitting) return
    if (!password) return handleError("Please enter a password")
    if (password !== confirmPassword) return handleError("Passwords do not match")
    setSubmitting(true)
    confirmPasswordReset(firebase.auth, code!, password)
      .then(() => setChanged(true))
      .catch((err: FirebaseError) => {
        switch (err.code) {
        case "auth/expired-action-code":
          handleError("This link has expired")
          break
        case "auth/invalid-action-code":
          handleError("This link is invalid")
          break
        case "auth/weak-password":
          handleError("Password is too weak")
          break
        default:
          handleError("An unknown error occurred")
        }
        setSubmitting(false)
      })
  }

  if (!code) return <Navigate to="/auth" replace />

  return (
    <div className="w-full bg-slate-100 h-full overflow-y-auto flex justify-center items-center">
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <Logo className="w-8 h-auto" />
          <span className="font-semibold text-2xl text-slate-900">{changed ? "Password Changed" : "Reset Password"}</span>
        </header>
        <main className="flex-cool p-8 gap-8">
          {changed
            ? (
              <span
                className="text-center w-full text-slate-900 text-base font-medium"
              >
                Your password has been reset successfully. You can now sign in with your new password.
              </span>
            )
            : (
              <div className="flex-cool gap-4">
                <input
                  type="password"
                  placeholder="New Password"
                  value={password}
                  onChange={e => setPassword(e.currentTarget.value)}
                />
                <input
                  type="password"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={e => setConfirmPassword(e.currentTarget.value)}
                />
              </div>
            )}
        </main>
        {
          changed
            ? (
              <footer className="p-8 flex items-center justify-center gap-2.5">
                <span className="text-sm text-indigo-600 hover:underline font-medium cursor-pointer" onClick={() => navigate("/auth")}>
                  Sign In
                </span>
              </footer>
            )
            : (
              <footer className="p-8 flex items-center justify-between gap-2.5">
                <span className="text-sm text-indigo-600 hover:underline font-medium cursor-pointer" onClick={() => navigate("/auth")}>
                  Sign In
                </span>
                <button disabled={submitting} onClick={onSubmit} className="btn-primary">Reset</button>
              </footer>
            )

        }
      </div>
    </div>
  )
}

export default ResetPasswordPage
