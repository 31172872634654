/* eslint-disable max-len */
export const availableBadges = [
  {
    id: 1401,
    title: "<div> 🏃‍♂️ Feb 2023 🏋️</div>",
    color: "#5ce0aa",
    font_color: "#000000",
    user: 217839,
    students_count: 131,
  },
  {
    id: 1402,
    title: "<div> 🏆 Monthly Mover </div>",
    color: "#fce205",
    font_color: "#000000",
    user: 217839,
    students_count: 91,
  },
  {
    id: 1444,
    title: "<div>Admin</div>",
    color: "#5ce0aa",
    font_color: "#000000",
    user: 217839,
    students_count: 1,
  },
  {
    id: 1560,
    title: "<div>🏃‍♂️ Mar 2023 🏋️‍♀️</div>",
    color: "#fc8181",
    font_color: "#000000",
    user: 217839,
    students_count: 105,
  },
  {
    id: 1678,
    title: "<div>🏃‍♂️ Apr 2023 🏋️</div>",
    color: "#d6bcfa",
    font_color: "#000000",
    user: 217839,
    students_count: 86,
  },
  {
    id: 1825,
    title: "<div>🏃‍♂️ May 2023 🏋️</div>",
    color: "#73c2fb",
    font_color: "#000000",
    user: 217839,
    students_count: 51,
  },
  {
    id: 1925,
    title: "<div>🏋️‍♀️ June 2023🏃‍♂️</div>",
    color: "#38a169",
    font_color: "#000000",
    user: 217839,
    students_count: 34,
  },
  {
    id: 2088,
    title: "<div>🏃‍♂️ July 2023 🏋️‍♀️</div>",
    knowledge: [
      "Invoice Reminders",
      "Journal Entries",
      "Recurring Bills",
      "Sub-Customers",
      "Invoice from Estimate",
      "Loan Payments",
      "Credit Card Payments",
      "Bounced Checks",
      "Client Locations",
      "Recording Interest Income",
      "Location and Class Reports",
      "Classes",
    ],
    color: "#ecc94b",
    font_color: "#000000",
    user: 217839,
    students_count: 15,
  },
  {
    id: 2135,
    title: "<div>🏃‍♂️ August 2023 🏋️‍♀️</div>",
    color: "#feb2b2",
    font_color: "#000000",
    user: 217839,
    students_count: 22,
  },
  {
    id: 2207,
    title: "<div>🏃 September 2023 🏋️</div>",
    color: "#ef7215",
    font_color: "#000000",
    user: 217839,
    students_count: 15,
  },
]

export const BOOKSMATCH_ID_KEY = 1583

export const lowActivityCutoff = 30 // 30 days

export const highActivityCutoff = 14 // 14 days

export const activityColors = {
  low: "red-500",
  average: "amber-500",
  high: "lime-500",
}

export const speedText = {
  low: "Slow",
  average: "Average",
  high: "Fast",
}
