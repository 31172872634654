import { AkadianIcon } from "assets/icons"
import { DefaultProfileSrc } from "assets/images"
import { FC } from "react"
import Tooltip from "./Tooltip"

interface props {
    size: number
    src?: string
    isPowerTeam?: boolean
    className?: string
}

const ProfileImage: FC<props> = ({ size, src, isPowerTeam, className }) => {
  return (
    <div
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
      className={["rounded-full relative", className].asClass}
    >
      <img alt="Profile" className="rounded-full object-cover w-full h-full" src={src || DefaultProfileSrc} />
      {isPowerTeam && (
        <div
          className="absolute right-0 bottom-0 w-2/5 h-2/5
         translate-x-2/5 translate-y-2/5 bg-gradient-to-br p-[10%] from-[#FFFEFA] to-[#FFFFFF] rounded-full z-10"
        >
          <Tooltip
            text={"Member of the\nAkadian Course Excellence team."}
            position="top"
            className="whitespace-pre-line text-center text-xs !p-2"
          >
            <AkadianIcon className="w-full h-full text-white" />
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default ProfileImage
