/* eslint-disable react/jsx-no-useless-fragment */
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"
import useAwaitableModal from "hooks/useAwaitableModal"
import AccountantAppLayout from "layouts/App/Accountant"
import { AccountantProfile, AccountantProposals, Setup } from "pages/accountant"
import RecruiterProfilePage from "pages/accountant/Recruiter"
import { FC, useEffect } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"
import AccountantSignUp from "modals/AccountantSignUp"
import { IAccountant } from "api/types"
import CraigRouter from "./craig"
import { maybeNotSetupRef } from ".."
import ChatsPage, { ChatPage } from "pages/Chat"

const AccountantRouter: FC = () => {
  const user = useMaybeNotSetupUser().user as IAccountant | undefined
  const [showOnboarding, OnboardingModal] = useAwaitableModal(AccountantSignUp, {} as any)

  useEffect(() => {
    if (user?.justSignedUp) {
      maybeNotSetupRef.current?.setUser?.({ ...user, justSignedUp: false })
      showOnboarding().catch(() => {})
    }
  }, [])

  return (
    <>
      {OnboardingModal}
      <Routes>
        <Route path="setup" element={user ? <Navigate to="/" replace /> : <Setup />} />
        <Route path="craig" element={user ? <CraigRouter /> : <></>} />
        <Route path="*" element={user ? <AccountantAppLayout /> : <Navigate to="/setup" replace />}>
          <Route path="profile" element={<AccountantProfile />} />
          <Route path="proposals" element={<AccountantProposals />} />
          <Route path="recruiter/:id" element={<RecruiterProfilePage />} />
          <Route path="chat" element={<ChatsPage />}>
            <Route
              index
              element={
                <div className="grow p-10 flex flex-col justify-start items-center">
                  <span className="text-lg text-primary-blue">No chat selected</span>
                </div>
              }
            />
            <Route path=":chatId" element={<ChatPage />} />
          </Route>
        </Route>
      </Routes>
    </>
  )
}

export default AccountantRouter
