import { IAccountant } from "api/types"
import Dropdown from "components/Dropdown"
import Tooltip from "components/Tooltip"
import { FC, useMemo, useState } from "react"

interface props {
    user: IAccountant
}
const skillGroupsOrder = [3, 4, 1, 8, 6, 7, 9, 5, 2, 10]
const skillGroupColors = [
  ["#BCDCFF", "#2E90FA"],
  ["#F5B9B9", "#FF3636"],
  ["#FFD7A2", "#FF9100"],
  ["#FFBAEB", "#EE46BC"],
  ["#C3ECFF", "#0BA5EC"],
  ["#B9C3FF", "#4E5BA6"],
  ["#FFD8C2", "#FB6514"],
  ["#A4FFD4", "#12B76A"],
  ["#FFB3C5", "#F63D68"],
  ["#E0D1FF", "#9E77ED"],
] as [string, string][]

const GroupComparison: FC<props> = ({ user }) => {
  const hasEnterpriseBreakdown = !!user.enterprise
  const [withinEnterprise, setWithinEnterprise] = useState(hasEnterpriseBreakdown)
  const graphConfig = useMemo(() => {
    const userSkillsByGroup = user.activityOverview.skillsEarned.reduce((acc, curr) => {
      if (!acc[curr.skillGroupId]) {
        acc[curr.skillGroupId] = 0
      }
      acc[curr.skillGroupId]++

      return acc
    }, {} as Record<string, number>)

    const columns = skillGroupsOrder.map(group => {
      const userCount = userSkillsByGroup[group] || 0
      const groupData = user.activityOverview.groupsData.find(g => g.id === group)!
      const average = withinEnterprise ? groupData.enterpriseAverage as number : groupData.average
      const name = groupData.name

      return {
        name,
        user: userCount,
        average,
        max: groupData.total,
      }
    })

    return { columns }
  }, [user, withinEnterprise])

  const steps = 5

  return (
    <div className="bg-white col-span-2 2xl:col-span-4 flex flex-col rounded-2.5 p-4 min-h-full drop-shadow-md">
      <div className="flex items-center border-b-[0.3px] border-b-[rgba(211, 224, 235, 1)] pb-4 gap-4">
        <span className="text-base text-primary-blue font-semibold">Group Comparison</span>
        {hasEnterpriseBreakdown && (
          <Dropdown value={withinEnterprise ? "My Firm" : "All Talent"}>
            <div className="flex flex-col bg-white rounded-2.5 p-2 gap-1">
              <button
                onClick={() => setWithinEnterprise(true)}
                className="px-3 py-2.5 text-xs text-text-secondary rounded-2.5 shadow-xs transition-all
                     border border-transparent hover:border-border-dropdown bg-white w-full text-left hover:shadow-sm"
              >
                My Firm
              </button>
              <button
                onClick={() => setWithinEnterprise(false)}
                className="px-3 py-2.5 text-xs text-text-secondary rounded-2.5 shadow-xs transition-all
                     border border-transparent hover:border-border-dropdown bg-white w-full text-left hover:shadow-sm"
              >
                All Talent
              </button>
            </div>
          </Dropdown>
        )}
        <div className="flex items-center gap-2 grow justify-end">
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-sm bg-[#383BF8]" />
            <span className="text-text-secondary text-xs font-light">
              1st Column : <span className="font-normal">{user.name}</span>
            </span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-sm bg-[#383BF850]" />
            <span className="text-text-secondary text-xs font-light">
              2nd Column : <span className="font-normal">{withinEnterprise ? "Enterprise" : "Group"} Average</span>
            </span>
          </div>
        </div>
      </div>
      <div className="flex flex-col xl:flex-row w-full pt-4 gap-4 pr-4">
        <div className="h-full w-1/2 xl:w-max flex flex-row-reverse xl:flex-col justify-between xl:h-[16rem]">
          {Array.from({ length: steps + 1 }).map((_, i) => (
            <span key={i} className="text-xs text-text-secondary font-light">
              {Math.round(100 * (steps-i) / steps)}%
            </span>
          ))}
        </div>
        <div className="grid grid-rows-10 xl:grid-cols-10 grow gap-4">
          {graphConfig.columns.map((column, i) => {
            const color = skillGroupColors[i]

            return (
              <div key={i} className="flex xl:flex-col items-center gap-2 h-[4rem] xl:h-full w-full">
                <div className="flex flex-col xl:flex-row gap-2 h-full w-1/2 xl:h-[16rem] xl:w-full">
                  <div className="w-full h-full rounded-md bg-[#F4F4F4] grow flex xl:flex-col xl:justify-end">
                    <Tooltip text={`${column.user} / ${column.max} available`} position="top" withoutPointer>
                      <div
                        className="min-w-unset xl:min-w-full min-h-full xl:min-h-0 rounded-md transition-all"
                        style={{
                          height: `${100 * column.user / column.max}%`,
                          width: `${100 * column.user / column.max}%`,
                          background: `linear-gradient(316.04deg, ${color[1]} 3.82%, ${color[0]} 89.78%)`,
                        }}
                      />
                    </Tooltip>
                  </div>
                  <div className="w-full h-full rounded-md bg-[#F4F4F4] grow flex xl:flex-col xl:justify-end">
                    <Tooltip text={`${Math.round(column.average)} / ${column.max} available`} position="top" withoutPointer>
                      <div
                        className="min-w-unset xl:min-w-full min-h-full xl:min-h-0 rounded-md transition-all"
                        style={{
                          height: `${100 * column.average / column.max}%`,
                          width: `${100 * column.average / column.max}%`,
                          background: `linear-gradient(316.04deg, ${color[1]}70 3.82%, ${color[0]}70 89.78%)`,
                        }}
                      />
                    </Tooltip>
                  </div>
                </div>
                <span className="text-xs text-text-secondary text-center grow">{column.name}</span>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default GroupComparison
