import { FC, useMemo, useState } from "react"
import { maybeNotSetupRef } from "routes/private"
import useAccountant from "contexts/User/useAccountant"
import ProfileCard from "./components/ProfileCard"
import OverviewCard from "./components/OverviewCard"
import { activityStatusByDate } from "utils/activityStatusByDate"
import MetricCard from "./components/MetricCard"
import { highActivityCutoff, lowActivityCutoff } from "constants/xp"
import SkillGroupsBreakdown from "./components/SkillGroupsBreakdown"
import GroupComparison from "./components/GroupComparison"
import LifetimeSkillChart from "./components/LifetimeSkillChart/LifetimeSkillChart"
import RecentTrainingActivity from "./components/RecentTrainingActivity"
import { EnvelopeIcon } from "assets/icons"
import accountantApi from "api/accountant"
import { handleError } from "helpers/errors"

const AccountantProfilePage: FC = () => {
  const user = useAccountant()
  const [sharing, setSharing] = useState(false)

  const setAccountant = maybeNotSetupRef.current?.setUser as any

  const knowledge = user.skillScore > 80 ? "high" : user.skillScore > 20 ? "average" : "low"
  const activity = activityStatusByDate(user.xpData.lastActivity || user.xpData.dateJoined)
  const speed = user.speed

  const activityDescription = useMemo(() => {
    if (activity === "low") {
      return "Over " + lowActivityCutoff + " days ago"
    }
    if (activity === "average") {
      return "Within " + lowActivityCutoff + " days"
    }

    return "Within " + highActivityCutoff + " days"
  }, [activity])

  const addedPastWeek = user.activityOverview.skillsEarned.filter(skill => {
    const date = new Date(skill.createdAt)
    const today = new Date()
    const diffTime = Math.abs(today.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    return diffDays <= 7
  }).length

  const totalSkills = user.activityOverview.skillsEarned.length

  const userTags = Object.values(user?.xpData?.userTags || {})
  const isSubscribedToGym = Boolean(user.xpData.courses[422860]) && !userTags.includes("Gym Suspended")

  const onSubscribe = () => {
    const sp = new URLSearchParams()
    sp.append("email", user.xpData.email)
    sp.append("first_name", user.xpData.firstName)
    sp.append("last_name", user.xpData.lastName)
    window.open("https://fastandeasyqbo.samcart.com/products/qbo-gym/?"+sp.toString(), "_blank")
  }

  const onShare = () => {
    if (sharing) return
    setSharing(true)
    accountantApi.shareProfile().then(url => {
      navigator.clipboard.writeText(url)
      handleError("Link copied to clipboard")
    })
      .finally(() => setSharing(false))
  }

  return (
    <div className="w-full h-full overflow-hidden p-5 flex flex-col">
      <div className="flex items-center pb-5 gap-4">
        <span className="font-semibold text-2xl text-primary-blue">Accountant Profile</span>
        {!user.enterprise && (
          <button
            onClick={onShare}
            disabled={sharing}
            className="flex items-center gap-2 p-2 disabled:pointer-events-none
           disabled:bg-slate-200 hover:bg-white transition-all hover:drop-shadow-sm rounded-full text-primary-blue"
          >
            <EnvelopeIcon className="w-6 h-6 text-primary-blue" />
            <span className="text-sm">Share</span>
          </button>
        )}
      </div>
      <div className="grow w-full overflow-y-auto border-t-[0.3px] border-t-[#D3E0EB] pt-4 relative">
        <div
          className="flex grow w-full gap-4 overflow-x-hidden"
        >
          <ProfileCard user={user} setUser={setAccountant} />
          <div className="grid grid-cols-2 2xl:grid-cols-4 gap-4 grow h-max pb-10 relative">
            {
              !isSubscribedToGym && (
                <div className="absolute inset-0 backdrop-grayscale backdrop-blur-md flex items-start justify-center z-50 rounded-2.5 p-10">
                  <div
                    className="bg-white rounded-2.5 divide-y divide-y-slate-200 overflow-hidden
      flex-cool h-max drop-shadow-xl border border-slate-200"
                  >
                    <main className="flex-cool p-4 items-center justify-center">
                      <span className="text-2xl font-medium text-slate-900">Subscription to Gym Required</span>
                      <span className="text-sm text-slate-900 mt-2">
                        These are <b>QBO Gym</b> statistics. You need to be an active member to view this.
                      </span>
                    </main>
                    <footer className="p-4 flex items-center justify-center">
                      <button onClick={onSubscribe} className="btn-primary">Click Here To Purchase</button>
                    </footer>
                  </div>
                </div>
              )
            }
            <OverviewCard activity={activity} speed={speed} knowledge={knowledge} />
            <MetricCard
              metric="knowledge"
              value={knowledge}
              numberValue={totalSkills}
              description={`${totalSkills} skill${totalSkills !== 1 ? "s" : ""} verified`}
            />
            <MetricCard
              metric="consistency"
              value={activity}
              description={activityDescription}
            />
            <MetricCard
              metric="speed"
              value={speed}
              numberValue={addedPastWeek}
              description={`${addedPastWeek} skill${addedPastWeek !== 1 ? "s" : ""} within a week`}
            />
            <SkillGroupsBreakdown user={user} />
            <GroupComparison user={user} />
            <LifetimeSkillChart user={user} />
            <RecentTrainingActivity user={user} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountantProfilePage
