import { ISummarizedRecruiter } from "api/types"
import Modal from "components/Modal"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"

interface props {
  profiles: ISummarizedRecruiter[]
  title: string
}

const UsersByCreatedDate: AwaitableModal<undefined, props> = ({ open, onCancel, resolve, initialData }) => {
  const sortedProfiles = initialData?.profiles.slice(0).sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
  const onClose = () => {
    resolve()
  }

  const onCopyEmail = (email: string) => {
    navigator.clipboard.writeText(email)
    handleError("Email copied to clipboard")
  }

  return (
    <Modal open={open} close={onClose}>
      <div
        className="bg-white rounded-2xl divide-y divide-y-slate-200 flex-cool h-max
       max-h-[calc(100vh-10rem)] my-12 mx-4 max-w-[768px] min-w-[512px]"
      >
        <header className="flex items-center justify-between gap-2.5 p-5">
          <span className="font-semibold text-2xl text-slate-900">{initialData?.title}</span>
          <span>Total count: <b>{sortedProfiles?.length}</b></span>
        </header>
        <main className="flex-cool p-5 gap-8 overflow-y-auto">
          <div className="flex-cool gap-4">
            <table className="w-full">
              <thead>
                <tr>
                  <th className="text-left">Name</th>
                  <th className="text-left">Email</th>
                  <th className="text-left">Created At</th>
                </tr>
              </thead>
              <tbody className="[&_td]:border [&_td]:border-border-section">
                {sortedProfiles?.map(profile => (
                  <tr key={profile.id} className="[&>td]:py-1 [&>td]:px-2">
                    <td>{profile.name}</td>
                    <td
                      className="max-w-[20rem] truncate hover:underline cursor-pointer"
                      onClick={() => onCopyEmail(profile.email)}
                    >
                      {profile.email}
                    </td>
                    <td>{new Date(profile.createdAt).toLocaleDateString()}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
        <footer className="p-5 flex items-center justify-center gap-2.5">
          <button onClick={onClose} className="btn-primary">Done</button>
        </footer>
      </div>
    </Modal>
  )
}

export default UsersByCreatedDate
