import { Logo } from "assets"
import firebase from "config/firebase"
import { FirebaseError } from "firebase/app"
import { sendPasswordResetEmail } from "firebase/auth"
import { handleError } from "helpers/errors"
import { FC, useState } from "react"
import { useNavigate } from "react-router"

const ForgotPasswordPage: FC = () => {
  const [email, setEmail] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [sent, setSent] = useState(false)
  const navigate = useNavigate()

  const emailValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const onSubmit = () => {
    if (!emailValid) return handleError("Please enter a valid email address")
    setSubmitting(true)
    const url = window.location.origin + "/auth"
    sendPasswordResetEmail(firebase.auth, email, { url }).then(() => setSent(true)).catch((err: FirebaseError) => {
      switch (err.code) {
      case "auth/invalid-email":
        handleError("Please enter a valid email address")
        break
      case "auth/user-disabled":
        handleError("This account has been disabled")
        break
      case "auth/user-not-found":
        handleError("This account does not exist")
        break
      case "auth/wrong-password":
        handleError("Incorrect password")
        break
      default:
        handleError("An unknown error occurred")
      }
      setSubmitting(false)
    })
  }

  return (
    <div className="w-full bg-slate-100 h-full overflow-y-auto flex justify-center items-center">
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <Logo className="w-8 h-auto" />
          <span className="font-semibold text-2xl text-slate-900">{sent ? "Email Sent" : "Forgot Password"}</span>
        </header>
        <main className="flex-cool p-8 gap-8">
          {sent
            ? (
              <span
                className="text-left w-full text-slate-900 text-base font-medium"
              >
                Check Your Inbox
              </span>
            )
            : (
              <div className="flex-cool gap-4">
                <input
                  type="text"
                  placeholder="Email"
                  value={email}
                  onChange={e => setEmail(e.currentTarget.value)}
                />
              </div>
            )}
        </main>
        <footer className="p-8 flex items-center justify-between gap-2.5">
          <span className="text-sm text-indigo-600 hover:underline font-medium cursor-pointer" onClick={() => navigate("/auth")}>
            Sign In
          </span>
          <button disabled={submitting} onClick={onSubmit} className="btn-primary">Reset</button>
        </footer>
      </div>
    </div>
  )
}

export default ForgotPasswordPage
