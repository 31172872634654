import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { DraggerIcon, TrashIcon } from "assets/icons"
import ProfileImage from "components/ProfileImage"
import { ICandidate } from "api/types"
import { useState } from "react"
import { useNavigate } from "react-router"
import useAwaitableModal from "hooks/useAwaitableModal"
import Feedback from "modals/Feedback"
import Diff from "../modals/Diff"
import recruiterApi from "api/recruiter"
import Tooltip from "components/Tooltip"

interface Props {
  proposal: ICandidate;
  deleteTask: (id: string) => void;
  changeProposalNote: (id: string, name: string) => void;
  disabled?: any
  resetChanges: () => void
}

const isAtLeast30DaysAgo = (date: string) => {
  const now = new Date()
  now.setDate(now.getDate() - 0)

  return new Date(date).getTime() < now.getTime()
}

function ProposalCard({ proposal, deleteTask, disabled, changeProposalNote, resetChanges }: Props) {
  const {
    setNodeRef,
    attributes,
    listeners,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: proposal.id,
    data: {
      type: "Proposal",
      proposal,
    },
    disabled: !!disabled,
  })
  const [feedback, setFeedback] = useState(proposal.feedback)
  const navigate = useNavigate()
  const [leaveFeedback, FeedbackModal] = useAwaitableModal(Feedback, {} as any)
  const [requesting, setRequesting] = useState(false)
  const [showDiff, DiffModal] = useAwaitableModal(Diff, {} as any)

  const [note, setNote] = useState(proposal.note || "")

  const canLeaveFeedback = proposal.state === "done" && proposal.hiredAt && !feedback && isAtLeast30DaysAgo(proposal.hiredAt) && false

  const onLeaveFeedback = (e: any) => {
    e.stopPropagation()
    if (!canLeaveFeedback) return
    leaveFeedback({ id: proposal.id, name: proposal.name, image: proposal.image }).then(() => setFeedback(true))
  }

  const requestDiff = (e: any) => {
    e.stopPropagation()
    if (requesting) return
    setRequesting(true)
    recruiterApi.getWatchedDiff(proposal.id)
      .then(d => {
        showDiff({ ...d, name: proposal.name })
        resetChanges()
      })
      .finally(() => setRequesting(false))
  }

  const onDelete = () => {
    if (requesting) return
    setRequesting(true)
    recruiterApi.deleteCandidate(proposal.id).then(() => deleteTask(proposal.id)).finally(() => setRequesting(false))
  }


  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={["gap-4 items-center flex rounded-lg", isDragging ? "bg-slate-100" : "bg-transparent"].asClass}
    >
      {DiffModal}
      {FeedbackModal}
      <DraggerIcon className={["peer cursor-grab h-full outline-none", isDragging && "opacity-0"].asClass} {...attributes} {...listeners} />
      <div
        onClick={e => {
          e.stopPropagation()
          navigate(`/accountant/${proposal.id}`)
        }}
        className={[
          "flex-cool transition-transform border border-slate-200 bg-white rounded-lg p-4",
          "cursor-pointer gap-4 peer-hover:scale-[0.98] grow select-none",
          isDragging && "scale-[0.98] opacity-0",
        ].asClass}
      >
        <div className="flex items-center gap-3 select-none">
          <div className="flex items-center gap-3 grow group">
            <ProfileImage size={48} src={proposal.image} isPowerTeam={proposal.isPowerTeam} className="group-hover:p-1.5 transition-all" />
            <div className="flex-cool grow">
              <span className="text-sm text-slate-900 font-medium select-none group-hover:underline">{proposal.name}</span>
              {canLeaveFeedback && (
                <span className="text-indigo-600 text-xs font-medium hover:underline cursor-pointer" onClick={onLeaveFeedback}>
                  Feedback
                </span>
              )}
            </div>
            <Tooltip position="left" text="Remove From Candidates">
              <button
                onClick={e => {
                  e.stopPropagation()
                  onDelete()
                }}
                className="w-[44px] h-[44px] hover:bg-slate-100 rounded-full p-2.5 cursor-pointer"
              >
                <TrashIcon />
              </button>
            </Tooltip>
            {!!proposal.changes ? (
              <Tooltip position="left" text="View Progress">
                <span
                  className={[
                    "w-8 h-8 flex items-center justify-center rounded-full bg-indigo-50 text-indigo-500 font-semibold text-[0.6125rem]",
                    requesting && "animate-pulse",
                  ].asClass}
                  onClick={requestDiff}
                >
                  {proposal.changes}
                </span>
              </Tooltip>
            ) : null}
          </div>
        </div>
        {(proposal.state === "done" || true) && (
          <div className="border-t border-t-slate-200 pt-4 relative">
            <span className="absolute top-4 left-0 text-xs pointer-events-none">Note</span>
            <input
              value={note}
              onClick={e => e.stopPropagation()}
              className="text-sm pt-[1.125rem] outline-none w-full"
              placeholder="Add a note..."
              onChange={e => setNote(e.currentTarget.value)}
              onBlur={e => changeProposalNote(proposal.id, e.currentTarget.value)}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProposalCard
