import Modal from "components/Modal"
import { AwaitableModal } from "hooks/useAwaitableModal"

const AccountantSignUp: AwaitableModal<{}, {seenBefore?: boolean}> = ({ open, resolve, initialData }) => {
  const seenBefore = initialData?.seenBefore

  return (
    <Modal open={open} close={resolve}>
      <div
        className="bg-white rounded-3xl divide-y divide-y-slate-200 overflow-hidden
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <main className="flex-cool p-8 items-center justify-center">
          {seenBefore
            ? <span className="text-2xl font-medium text-slate-900">Understanding <span className="font-bold">BooksMatch</span></span>
            : <span className="text-2xl font-medium text-slate-900">Welcome to <span className="font-bold">BooksMatch</span></span>}
          <div className="aspect-[16/9] w-[512px] bg-green-500 mt-6">
            <iframe
              src={
                // eslint-disable-next-line max-len
                "https://videos.sproutvideo.com/embed/ea90d0b31f14e2ca63/ef8ea142ac1837a6?playerColor=2f3437&amp;playerTheme=dark&amp;type=hd"
              }
              width="100%"
              height="100%"
              className="border-none w-full h-full"
              allowFullScreen={false}
              allow="autoplay"
              referrerPolicy="no-referrer-when-downgrade"
              title="Video Player"
            />
          </div>
        </main>
        <footer className="p-8 flex items-center justify-center">
          <button onClick={resolve} className="btn-primary">{seenBefore ? "Close" :"See It In Action"}</button>
        </footer>
      </div>
    </Modal>
  )
}

export default AccountantSignUp
