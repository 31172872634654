import adminApi from "api/admin"
import { IEnterpriseEntry } from "api/types"
import { ThankYouCheckIcon } from "assets/icons"
import Modal from "components/Modal"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"

const NewEnterpriseModal: AwaitableModal<IEnterpriseEntry | undefined> = ({ open, onCancel, resolve }) => {
  const [email, setEmail] = useState<string>("")
  const [name, setName] = useState<string>("")
  const [link, setLink] = useState<string>()
  const [created, setCreated] = useState<IEnterpriseEntry>()
  const [submitting, setSubmitting] = useState(false)

  const emailValid = email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const onCreate = () => {
    if (submitting) return
    if (!email) return handleError("Please enter the email")
    if (!emailValid) return handleError("Please enter a valid email")
    if (!name) return handleError("Please enter the name")
    setSubmitting(true)
    adminApi.createEnterprise({ email, name }).then(async({ user, passwordResetLink }) => {
      setCreated(user)
      const sp = new URLSearchParams(passwordResetLink)
      const code = sp.get("oobCode")
      if (!code) return handleError("An error occurred")
      const url = `/auth/reset-password?code=${code}`
      setLink(window.location.origin + url)
      if (user && !passwordResetLink) {
        resolve(user)
      }
    }).finally(() => setSubmitting(false))
  }

  const onResolve = () => {
    resolve(created)
  }

  if (link) return (
    <Modal open={open} close={!submitting ? onResolve : () => {}}>
      <div className="bg-white rounded-2xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-5">
          <span className="font-semibold text-2xl text-slate-900">Enterprise Created</span>
        </header>
        <main className="flex-cool p-5 gap-8 items-center">
          <div className="flex-cool gap-4">
            <ThankYouCheckIcon className="h-32" />
          </div>
          <span>
            The enterprise has been created. Send the password reset link below to the user.
          </span>
          <input type="text" value={link} readOnly className="border-2 rounded-md p-2 w-full" />
        </main>
        <footer className="p-5 flex items-center justify-center gap-2.5">
          <button onClick={onResolve} className="btn-primary">Done</button>
        </footer>
      </div>
    </Modal>
  )

  return (
    <Modal open={open} close={!submitting ? onResolve : () => {}}>
      <div className="bg-white rounded-2xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-5">
          <span className="font-semibold text-2xl text-slate-900">Create an Enterprise</span>
        </header>
        <main className="flex-cool p-5 gap-8">
          <div className="flex-cool gap-4">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
            <input
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-5 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={!submitting ? onResolve : () => {}}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onCreate} className="btn-primary">Create</button>
        </footer>
      </div>
    </Modal>
  )
}

export default NewEnterpriseModal
