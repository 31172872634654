import adminApi from "api/admin"
import { IEnterpriseEntry } from "api/types"
import ProfileImage from "components/ProfileImage"
import Toggle from "components/Toggle"
import { handleError } from "helpers/errors"
import { FC, useState } from "react"
import { useNavigate } from "react-router"

interface props {
    enterprise: IEnterpriseEntry,
    onUpdateTalentFinder: (id: string, data: {hasTalentFinder: boolean, maxEmployees: number}) => void
}

const Enterprise: FC<props> = ({ enterprise, onUpdateTalentFinder }) => {
  const navigate = useNavigate()
  const [updating, setUpdating] = useState(false)

  const goTo = undefined // () => navigate(`/enterprise/${enterprise.id}`, { state: { showBack: true } })

  const [loadingLink, setLoadingLink] = useState(false)

  const getLink = () => {
    if (loadingLink) return
    setLoadingLink(true)
    adminApi.getEnterprisePasswordResetLink(enterprise.id).then(link => {
      const sp = new URLSearchParams(link)
      const code = sp.get("oobCode")
      if (!code) return handleError("An error occurred")
      const url = `/auth/reset-password?code=${code}`
      navigator.clipboard.writeText(window.location.origin + url)
      handleError("Link copied to clipboard")
    })
      .finally(() => setLoadingLink(false))
  }


  return (
    <tr
      style={{ height: 0 }}
      className="cursor-pointer hover:opacity-75 transition-all"
    >
      <td
        onClick={goTo}
      >
        <div className="flex items-center gap-2 relative group">
          <ProfileImage size={32} src={enterprise.image} />
          <ProfileImage
            src={enterprise.image}
            size={100}
            className="border-2 p-px pointer-events-none opacity-0 group-hover:opacity-100 transition-all
                 absolute left-0 top-1/2 -translate-y-1/2"
          />
          <span>{enterprise.name}</span>
          <span className="text-xs text-slate-500">({enterprise.email})</span>
          <button
            onClick={getLink}
            className="text-xs text-slate-500"
          >
            {loadingLink ? "Loading..." : "Copy Link"}
          </button>
        </div>
      </td>
      <td
        onClick={goTo}
        className="capitalize"
      >
        {new Date(enterprise.createdAt).toLocaleDateString()}
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        {enterprise.employees} / <input
          type="number"
          className="w-12 text-center"
          defaultValue={enterprise.maxEmployees}
          onBlur={e => {
            const value = Number(e.target.value)
            if (isNaN(value)) return e.target.value = enterprise.maxEmployees.toString()
            if (value !== enterprise.maxEmployees) {
              onUpdateTalentFinder(enterprise.id, {
                hasTalentFinder: enterprise.hasTalentFinder,
                maxEmployees: value,
              })
            }
          }}
        />
      </td>
      <td
        onClick={() => onUpdateTalentFinder(enterprise.id, {
          hasTalentFinder: !enterprise.hasTalentFinder,
          maxEmployees: enterprise.maxEmployees,
        })}
      >
        <Toggle checked={enterprise.hasTalentFinder} />
      </td>
    </tr>
  )
}

export default Enterprise
