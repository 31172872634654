import TableSpanner from "components/TableSpanner"
import FullLoader from "components/loaders/FullLoader"
import { FC, useEffect, useState } from "react"
import { IEnterpriseEntry } from "api/types"
import Enterprise from "./Enterprise"
import adminApi from "api/admin"
import { SearchIcon } from "assets/icons"
import useAwaitableModal from "hooks/useAwaitableModal"
import NewEnterpriseModal from "./modals/NewEnterprise"

const EnterpriseSettingsPage: FC = () => {
  const [enterprises, setEnterprises] = useState<IEnterpriseEntry[] | undefined>(undefined)
  const [addEnterprise, AddEnterpriseComponent] = useAwaitableModal(NewEnterpriseModal, {})
  const [updating, setUpdating] = useState(false)

  useEffect(() => {
    adminApi.getEnterprises().then(setEnterprises)
  }, [])

  const onUpdateTalentFinder = async(id: string, data: {hasTalentFinder: boolean, maxEmployees: number}) => {
    if (updating) return
    setUpdating(true)
    try {
      await adminApi.updateEnterpriseTalentFinderAccess(id, data)
      setEnterprises(old => old && old.map(e => e.id === id ? { ...e, ...data } : e))
    } catch (e) {
      console.error(e)
    }
    setUpdating(false)
  }

  const onCreateEnterprise = async() => {
    addEnterprise().then(async enterprise => {
      if (enterprise) {
        setEnterprises(old => old && [enterprise, ...old])
      }
    })
  }

  const [search, setSearch] = useState("")

  const filteredEnterprises = enterprises?.filter(e => e.name.toLowerCase().includes(search.toLowerCase()))

  return (
    <div className="w-full h-full overflow-hidden pt-5 px-5 flex flex-col">
      {AddEnterpriseComponent}
      <div className="flex items-center pb-5 gap-4">
        <span className="font-semibold text-2xl text-primary-blue grow">Enterprise Management</span>
        <button className="btn-primary" onClick={onCreateEnterprise}>
          Add New Enterprise
        </button>
      </div>
      <div className="flex-cool border-t-[0.3px] border-t-[#D3E0EB] pt-6 relative overflow-hidden grow">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4 p-2.5 mb-5">
          <div className="relative">
            <SearchIcon className="absolute top-1/2 left-2.5 transform -translate-y-1/2 z-10" />
            <input
              value={search}
              onChange={e => setSearch(e.target.value)}
              className="rounded-2.5 bg-white drop-shadow-md py-3.5 pr-5
               pl-10 text-xs text-text-secondary placeholder:opacity-50 outline-none"
              placeholder="Search employees"
            />
          </div>
        </div>
        <div
          className="flex-cool min-h-min
          grow
        bg-white rounded-2.5 overflow-auto"
        >
          <table className="talent-finder-table">
            <thead className="sticky top-0 z-10">
              <th>
                Enterprise
              </th>
              <th>
                Added At
              </th>
              <th>
                Employee Count / Max
              </th>
              <th>
                Access to Talent Finder
              </th>
            </thead>
            <tbody>
              {
                filteredEnterprises === undefined
                  ? <TableSpanner rows={5} cols={4} children={<FullLoader />} />
                  : filteredEnterprises.length === 0
                    ? (
                      <TableSpanner
                        rows={5}
                        cols={4}
                        children={(
                          <div className="text-text-secondary flex flex-col items-center justify-center">
                            No enterprises found
                          </div>
                        )}
                      />
                    )
                    : filteredEnterprises.map(enterprise => (
                      <Enterprise key={enterprise.id} enterprise={enterprise} onUpdateTalentFinder={onUpdateTalentFinder} />
                    ))
              }
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center mt-5 pt-5 border-t-[0.5px] border-t-[#D3E0EB] pb-5">
          {!!enterprises ? (
            <span className="text-sm text-text-secondary text-left grow">
              Total Enterprises Found: <span className="text-[#151D48] font-medium">{filteredEnterprises?.length}</span>
            </span>
          ) : (
            <span className="text-sm text-text-secondary text-left grow">
              Total Enterprises Found: <span className="text-[#151D48] font-medium">...</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default EnterpriseSettingsPage
