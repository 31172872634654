import { SortableContext, useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { FC, PropsWithChildren, useMemo } from "react"
import ProposalCard from "./ProposalCard"
import FullLoader from "components/loaders/FullLoader"
import { ICandidate } from "api/types"

interface Props {
  column: {id: string};
  deleteTask: (id: string) => void;
  changeProposalNote: (id: string, name: string) => void;
  proposals: ICandidate[];
  disabled?: any;
  loading?: boolean;
}

const ColumnContainer: FC<PropsWithChildren<Props>> = ({
  column,
  proposals,
  deleteTask,
  changeProposalNote,
  disabled,
  children,
  loading,
}) => {
  const proposalIds = useMemo(() => {
    return proposals.map(prop => prop.id)
  }, [proposals])

  const {
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id: column.id,
    data: {
      type: "Column",
      column,
    },
    disabled: !!disabled,
  })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform),
  }

  return (
    <div
      ref={setNodeRef}
      style={style}
      className="flex-cool grow divide-y divide-y-slate-200 flex-1"
    >
      {children}
      <div className="flex-cool grow  overflow-y-auto overflow-x-visible gap-4 p-8">
        {!loading
          ?(
            <SortableContext items={proposalIds}>
              {proposals.map(task => (
                <ProposalCard
                  key={task.id}
                  proposal={task}
                  deleteTask={deleteTask}
                  changeProposalNote={changeProposalNote}
                />
              ))}
            </SortableContext>
          )
          : <FullLoader /> }
      </div>
    </div>
  )
}

export default ColumnContainer
