import { IBadgeTranslation } from "api/types"
import { BadgeIcon } from "assets/icons"
import Modal from "components/Modal"
import { skillColors } from "constants/skills"
import { AwaitableModal } from "hooks/useAwaitableModal"

const Diff: AwaitableModal<{}, {skills: Record<string, IBadgeTranslation>, name?: string}> = ({ initialData, open, resolve }) => {
  const data = initialData || {}
  const noChanges = !Object.keys(data.skills || {}).length

  const badges = Object.entries(data.skills || {})

  return (
    <Modal open={open} close={resolve}>
      <div
        className="bg-white rounded-xl gap-8 text-slate-950 pt-4 items-center
      flex-cool h-max my-12 mx-4 max-w-[535px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <div className="flex-cool text-center items-center gap-2">
          <span className="text-2xl font-semibold">
            New Skills Earned
          </span>
          <span className="text-xs">
            {initialData?.name || "Accountant"} has verified these skills since your last visit
          </span>
        </div>
        {noChanges && (
          <div>
            <span className="text-xl text-slate-950 pb-4">No Changes</span>
          </div>
        )}
        {!!badges?.length && (
          <div className="gap-3 flex-cool items-center overflow-y-auto px-4 pb-4">
            <div className="flex flex-wrap justify-center items-start gap-5 pb-8">
              {badges.map(([tag, { name, group }], i) => {
                const color = skillColors[group as keyof typeof skillColors]

                if (!color || !name) return null

                const backColor = color + "1a"

                return (
                  <div
                    key={`${tag}${name}${group}`}
                    className="flex gap-2 items-center rounded-full px-3 py-2 text-sm"
                    style={{ backgroundColor: backColor }}
                  >
                    <BadgeIcon fill={color} className="w-4 h-4" />
                    <span style={{ color: color }}>
                      {name}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default Diff
