import api from "api"
import { handleError } from "helpers/errors"
import { ChangeEvent, FC, useState } from "react"
import { maybeNotSetupRef } from "routes/private"
import { DefaultProfileSrc } from "assets/images"
import Loader from "components/loaders/Loader"

interface props {
    src?: string,
}

const ChangeableProfilePicture: FC<props> = ({ src: _src }) => {
  const [src, setSrc] = useState<string | undefined>(_src)
  const [changing, setChanging] = useState(false)

  const onFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (changing) return
    const _file = e.target.files?.[0]
    if (_file) {
      setChanging(true)
      try {
        if (_file.size > 1024 * 1024 * 1) {
          setChanging(false)

          return handleError("File size must be less than 1MB")
        }
        const fr = new FileReader()
        fr.onload = () => {
          api.updateProfilePic(fr.result as string)
            .then(updatedSrc => {
              maybeNotSetupRef.current?.setUser?.(old => old && ({ ...old, image: updatedSrc }))
              setSrc(updatedSrc)
            })
            .finally(() => setChanging(false))
        fr.result as string
        }

        fr.readAsDataURL(_file)
      } catch (error) {
        console.log(error)
        setChanging(false)
      }
    }
  }

  return (
    <label className={["relative", !changing && "cursor-pointer hover:scale-[0.97]"].asClass} htmlFor="profile-pic">
      <img
        src={src || DefaultProfileSrc}
        alt="profile"
        className="w-[3.25rem] h-[3.25rem] object-cover rounded-full drop-shadow-md"
      />
      {changing && (
        <div className="absolute inset-0 flex items-center justify-center">
          <Loader />
        </div>
      )}
      <input type="file" accept="image/*" className="hidden" id="profile-pic" onChange={onFileUpload} />
    </label>
  )
}

export default ChangeableProfilePicture
