import enterpriseApi from "api/enterprise"
import { IAccountant } from "api/types"
import { ThankYouCheckIcon } from "assets/icons"
import Modal from "components/Modal"
import Loader from "components/loaders/Loader"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useMemo, useState } from "react"

const NewEmployeeModal: AwaitableModal<IAccountant | undefined> = ({ open, onCancel, resolve }) => {
  const [email, setEmail] = useState<string>("")
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [created, setCreated] = useState<IAccountant>()
  const [submitting, setSubmitting] = useState(false)

  const signInLink = useMemo(() => `https://fastandeasyqbo.booksmatch.us/auth/code/${created?.magic}`, [created])

  const emailValid = email?.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const onCreate = () => {
    if (submitting) return
    if (!email) return handleError("Please enter the email")
    if (!emailValid) return handleError("Please enter a valid email")
    if (!firstName) return handleError("Please enter the first name")
    if (!lastName) return handleError("Please enter the last name")
    setSubmitting(true)
    enterpriseApi.createEmployee({ email, firstName, lastName }).then(async user => {
      setCreated(user)
    }).finally(() => setSubmitting(false))
  }

  const onResolve = () => {
    resolve(created)
  }

  if (created) return (
    <Modal open={open} close={!submitting ? onResolve : () => {}}>
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-5">
          <span className="font-semibold text-2xl text-slate-900">Employee Added</span>
        </header>
        <main className="flex-cool p-5 gap-8 items-center">
          <div className="flex-cool gap-4">
            <ThankYouCheckIcon className="h-32" />
          </div>
          <span>
            Your employee has been added to the gym. Send the setup link below to the employee.
          </span>
          <input type="text" value={signInLink} readOnly className="border-2 rounded-md p-2 w-full" />
        </main>
        <footer className="p-5 flex items-center justify-center gap-2.5">
          <button onClick={onResolve} className="btn-primary">Done</button>
        </footer>
      </div>
    </Modal>
  )

  return (
    <Modal open={open} close={!submitting ? onResolve : () => {}}>
      <div className="bg-white rounded-3xl divide-y relative divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        {submitting && (
          <div className="absolute flex flex-col inset-px rounded-3xl backdrop-blur-md items-center justify-center gap-4 p-5 z-[10]">
            <Loader />
            <span className="text-center font-medium text-slate-950 text-lg flex flex-col gap-2">
              Please wait while we create the account.
              <span className="text-center font-medium text-slate-500 text-sm">
                Once it is done you will receive a link which you can send to your employee to log in
              </span>
            </span>
          </div>
        )}
        <header className="flex items-center justify-start gap-2.5 p-4 !border-t-0">
          <span className="font-semibold text-2xl text-slate-900">Add an Employee</span>
        </header>
        <main className="flex-cool p-5 gap-4">
          <div className="flex-cool gap-4">
            <input
              type="text"
              placeholder="Email Address"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
            <div className="flex gap-4 w-full">
              <input
                type="text"
                className="grow"
                placeholder="First Name"
                value={firstName}
                onChange={e => setFirstName(e.currentTarget.value)}
              /><input
                type="text"
                className="grow"
                placeholder="Last Name"
                value={lastName}
                onChange={e => setLastName(e.currentTarget.value)}
              />
            </div>
          </div>
        </main>
        <footer className="p-5 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={!submitting ? onResolve : () => {}}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onCreate} className="btn-primary">Create</button>
        </footer>
      </div>
    </Modal>
  )
}

export default NewEmployeeModal
