import useAuthContext from "contexts/Auth/useAuthContext"
import MaybeNotSetupUserProvider from "contexts/MaybeNotSetupUser/MaybeNotSetupUserProvider"
import { FC, createRef } from "react"
import { Navigate, Route, Routes } from "react-router"
import { IMaybeNotSetupUserContext } from "contexts/MaybeNotSetupUser/maybeNotSetupUserContext"
import NotAccountantRouter from "./notAccountant"
import AccountantRouter from "./accountant"
import ShareRouter from "routes/share"

export const maybeNotSetupRef = createRef<IMaybeNotSetupUserContext>()

const PrivateRouter: FC = () => {
  const auth = useAuthContext()

  if (!auth) {
    return <Navigate to="/auth" />
  }

  return (
    <MaybeNotSetupUserProvider ref={maybeNotSetupRef}>
      <Routes>
        <Route path="share/*" element={<ShareRouter />} />
        <Route path="*" element={auth.xpId ? <AccountantRouter /> : <NotAccountantRouter />} />
      </Routes>
    </MaybeNotSetupUserProvider>
  )
}

export default PrivateRouter
