import { useEffect, useState } from "react"

const useCraigParentLocation = () => {
  const [href, setHref] = useState<string | null>(null)

  useEffect(() => {
    window.parent.postMessage({ type: "craig:available" }, "*")
  }, [])

  useEffect(() => {
    const handleEvent = (event: MessageEvent) => {
      if (event.data?.type === "craig:locationchange") {
        setHref(event.data.data.current)
      }
    }

    window.addEventListener("message", handleEvent)

    return () => {
      window.removeEventListener("message", handleEvent)
    }
  }, [])

  return href
}

export default useCraigParentLocation
