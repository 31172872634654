import api from "api"
import FullLoader from "components/loaders/FullLoader"
import firebase from "config/firebase"
import useAuthContext from "contexts/Auth/useAuthContext"
import { signInWithCustomToken } from "firebase/auth"
import { FC, useEffect } from "react"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import AuthRouter from "routes/auth"
import PrivateRouter from "routes/private"
import PublicRouter from "routes/public"

const Root: FC = () => {
  const auth = useAuthContext()

  useEffect(() => {
    if (auth === null) {
      if (!window.location.pathname.startsWith("/auth")) {
        const tokenFromUrl = new URLSearchParams(window.location.search).get("token")
        api.authCookie(tokenFromUrl ? { cookie: tokenFromUrl } : undefined).then(token => {
          if (token) {
            return signInWithCustomToken(firebase.auth, token).catch(() => {})
          }
        }).catch(() => {
          tokenFromUrl && signInWithCustomToken(firebase.auth, tokenFromUrl).catch(() => {})
        })
      }
    }
  }, [auth])

  if (auth === undefined) {
    return <FullLoader />
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={auth ? <PrivateRouter /> : <PublicRouter />} />
        <Route path="auth/*" element={<AuthRouter />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Root
