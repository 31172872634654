import { Gradients } from "constants/colors"
import { FC, PropsWithChildren } from "react"

interface props {
    level: "low" | "average" | "high"
    className?: string
}

const Badge: FC<PropsWithChildren<props>> = ({ level, children, className }) => {
  return (
    <div
      className={[
        "rounded-2.5 text-xs px-5 py-1 text-white text-center capitalize !min-w-[6rem]",
        className,
      ].asClass}
      style={{
        background: Gradients[level],
      }}
    >
      {children}
    </div>
  )
}

export default Badge
