import { BuildingIcon, EnvelopeIcon, LockIcon, SubscriptionIcon } from "assets/icons"
import useRecruiter from "contexts/User/useRecruiter"
import useAwaitableModal from "hooks/useAwaitableModal"
import { FC } from "react"
import EditProfile from "./modals/EditProfile"
import EditEmail from "./modals/EditEmail"
import EditPassword from "./modals/EditPassword"
import { maybeNotSetupRef } from "routes/private"
import EditAddress from "./modals/EditAddress"
import ChangeableProfilePicture from "components/ChangeableProfilePicture"

const formatAddress: (address?: any) => string = address => {
  if (!address) return "No Address Given"

  return [address.street1, address.street2, address.city, address.state, address.country].filter(Boolean).join(", ")
}

const ProfilePage: FC = () => {
  const user = useRecruiter()
  const [editProfile, EditProfileModal] = useAwaitableModal(EditProfile, {} as any)
  const [editEmail, EditEmailModal] = useAwaitableModal(EditEmail, {} as any)
  const [editPassword, EditPasswordModal] = useAwaitableModal(EditPassword, {} as any)
  const [editAddress, EditAddressModal] = useAwaitableModal(EditAddress, {} as any)

  const onEdit = () => {
    editProfile({
      name: user.name,
      type: user.recruiterType,
    })
      .then(edited => {
        if (edited) {
          maybeNotSetupRef.current?.setUser?.({ ...user, name: edited.name, recruiterType: edited.type })
        }
      })
      .catch(() => {})
  }

  const onEditEmail = () => {
    editEmail({
      email: user.email,
    })
      .then(edited => {
        if (edited) {
          maybeNotSetupRef.current?.setUser?.({ ...user, ...edited })
        }
      })
      .catch(() => {})
  }

  const onEditPassword = () => {
    editPassword({})
      .then(() => {})
      .catch(() => {})
  }

  const onEditAddress = () => {
    editAddress({ ...user.address })
      .then(address => {
        if (address) {
          maybeNotSetupRef.current?.setUser?.({ ...user, address })
        }
      })
      .catch(() => {})
  }

  return (
    <div className="w-full h-full">
      {EditEmailModal}
      {EditProfileModal}
      {EditPasswordModal}
      {EditAddressModal}
      <div className="pt-8 px-8 gap-8 flex-cool h-full">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4">
          <span className="font-semibold text-3xl text-slate-900">User Profile</span>
        </div>
        <div
          className="flex-cool min-h-min
          grow
        bg-white rounded-3xl divide-y
        divide-y-slate-200 overflow-hidden overflow-y-auto"
        >
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <ChangeableProfilePicture size={96} src={user.image} />
            <div className="flex-cool gap-1 grow">
              <span className="text-2xl font-medium text-slate-900">{user.name}</span>
              <span className="text-slate-500 text-base">{user.recruiterType}</span>
            </div>
            <button className="btn-secondary w-[162px]" onClick={onEdit}>Edit Profile</button>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <EnvelopeIcon />
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Email Address</span>
              <span className="text-slate-900 text-base grow">{user.email}</span>
            </div>
            <button className="btn-secondary w-[162px]" onClick={onEditEmail}>Edit Email</button>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <LockIcon />
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Password</span>
              <span className="text-slate-900 text-base grow">••••••••••••••••</span>
            </div>
            <button className="btn-secondary w-[162px]" onClick={onEditPassword}>Edit Password</button>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <BuildingIcon />
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Company Address</span>
              <span className="text-slate-900 text-base grow">{formatAddress(user.address)}</span>
            </div>
            <button className="btn-secondary w-[162px]" onClick={onEditAddress}>Edit Address</button>
          </div>
          {/*<div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <SubscriptionIcon className="stroke-slate-500" />
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Credit Card</span>
              <span className="text-slate-900 text-base grow">••••  ••••  ••••  1234</span>
            </div>
            <button className="btn-secondary w-[162px]">Edit Card</button>
  </div>*/}
        </div>
      </div>
    </div>
  )
}

export default ProfilePage
