import { ITalentFinderEntry } from "api/types"
import ProfileImage from "components/ProfileImage"
import { availableCertifications } from "constants/certifications"
import Badge from "./Badge"
import { FC } from "react"
import { useNavigate } from "react-router"
import { GoodTalentIcon, PoorTalentIcon, TopTalentIcon } from "assets/images"

interface props {
    talent: ITalentFinderEntry,
}

const Talent: FC<props> = ({ talent }) => {
  const navigate = useNavigate()

  const goTo = talent.preview ? undefined : () => {
    navigate(`/accountant/${talent.id}`, { state: { showBack: true } })
  }

  const [_city, _state] = talent.location?.split(", ") || []
  const city = _city?.toLowerCase() || ""
  const state = _state?.toUpperCase() || ""
  const location = [city, state].filter(Boolean).join(", ")

  const knowledge = talent.skillScore > 80 ? "high" : talent.skillScore > 20 ? "average" : "low"
  const activity = talent.activity
  const speed = talent.speed

  const icon = talent.top === "high" ? <TopTalentIcon /> : talent.top === "average" ? <GoodTalentIcon /> : <PoorTalentIcon />

  return (
    <tr
      style={{ height: 0 }}
      className={[!talent.preview && "cursor-pointer hover:opacity-75 transition-all"].asClass}
    >
      {
        talent.preview ? (
          <td className="blur-md">
            <div className="flex items-center gap-2 relative group">
              <ProfileImage size={34} isPowerTeam={talent.isPowerTeam} className="border-2 p-px drop-shadow-md" />
              <ProfileImage
                size={100}
                className="border-2 p-px pointer-events-none opacity-0 group-hover:opacity-100 transition-all
                 absolute left-0 top-1/2 -translate-y-1/2"
              />
              <span>Johnny Doeey</span>
            </div>
          </td>
        ) : (
          <td
            onClick={goTo}
          >
            <div className="flex items-center gap-2 relative group">
              <ProfileImage size={34} src={talent.image} isPowerTeam={talent.isPowerTeam} className="drop-shadow-md" />
              <ProfileImage
                src={talent.image}
                size={100}
                className="border-2 p-px pointer-events-none opacity-0 group-hover:opacity-100 transition-all
                 !absolute left-0 top-1/2 -translate-y-1/2"
              />
              <span>{talent.name}</span>
            </div>
          </td>)
      }
      <td
        onClick={goTo}
      >
        {talent.employment}
      </td>
      <td
        onClick={goTo}
        className="capitalize"
      >
        {location}
      </td>
      <td
        className="!text-center"
        onClick={goTo}
      >
        {talent.certifications.length} / {availableCertifications.length}
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        <div className="flex justify-center">
          <Badge level={knowledge}>{knowledge}</Badge>
        </div>
      </td>
      <td
        onClick={goTo}
        className="!text-center"
      >
        <div className="flex justify-center">
          <Badge level={activity}>{activity}</Badge>
        </div>
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        <div className="flex justify-center">
          <Badge level={speed}>{speed}</Badge>
        </div>
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        <div className="flex justify-center">
          <Badge level={talent.top} className="!w-[14rem] shrink-0">
            <div className="flex items-center justify-center gap-2">
              {icon}
              {talent.rank}
            </div>
          </Badge>
        </div>
      </td>
    </tr>
  )
}

export default Talent
