import { IAccountant } from "api/types"
import { RatingStarIcon } from "assets/icons"
import { availableReviews } from "constants/certifications"
import { FC, useEffect, useState } from "react"

interface props {
    name: typeof availableReviews[number]
    disabled?: boolean
    user: IAccountant
    change?: (name: string, url: string) => void
}

const Review: FC<props> = ({ name, user, disabled, change }) => {
  const editable = !!change
  const [updating, setUpdating] = useState(false)
  const [editing, setEditing] = useState(false)
  const [url, setUrl] = useState<string | undefined>(user.reviews?.[name])

  useEffect(() => {
    if (!disabled) {
      setUpdating(false)
    }
  }, [disabled])

  const onEdit = () => {
    if (updating) return
    if (!url) return
    setUpdating(true)
    setEditing(false)
    change?.(name, url)
  }

  const present = !!user.reviews?.[name]

  if (!present && !editable) return null

  if (!editable) {
    return (
      <div
        className={[
          "flex cursor-pointer transition-colors",
          "bg-primary-section-dark hover:bg-[#5D5FEF08]",
          "rounded-2.5 py-2 px-3 items-center justify-center gap-2.5 border border-[#5D5FEF26]",
        ].asClass}
      >
        <RatingStarIcon className="w-4 h-4 shrink-0 !fill-primary-purple" />
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={[
            "text-xs font-normal text-primary-purple w-max truncate grow",
            "text-primary-purple",
          ].asClass}
        >
          {name}
        </a>
      </div>
    )
  }

  return (
    <div
      className={[
        "flex transition-colors w-full bg-[#5D5FEF21] border border-[#5D5FEF26]",
        "rounded-2.5 py-2 px-3 items-center justify-center gap-2.5",
        (disabled || updating) && "pointer-events-none",
        (updating) && "animate-pulse",
      ].asClass}
    >
      <RatingStarIcon className="w-4 h-4 shrink-0 !fill-primary-purple" />
      {editing ? (
        <input
          type="text"
          value={url}
          onChange={e => setUrl(e.target.value)}
          placeholder={name}
          className="text-xs font-normal !text-primary-blue truncate grow shrink !min-w-0
           border-none bg-transparent focus:outline-none !p-0 !rounded-none"
        />
      ) : (
        <a
          href={url}
          target="_blank"
          rel="noreferrer"
          className={[
            "text-xs font-normal text-primary-purple w-max truncate grow",
          ].asClass}
        >
          {name}
        </a>
      )}
      {editing ? (
        <button
          onClick={onEdit}
          className="text-primary-purple text-base rounded-full hover:bg-[#5D5FEF26]
           transition-colors p-1.5 -mr-1.5 w-6 h-6 flex items-center justify-center"
        >
          ✓
        </button>
      ) : (
        <button
          onClick={() => setEditing(true)}
          disabled={updating}
          className="text-primary-purple text-base rounded-full hover:bg-[#5D5FEF26]
           transition-colors p-1.5 -mr-1.5 w-6 h-6 flex items-center justify-center"
        >
          +
        </button>
      )}
    </div>
  )
}

export default Review
