import { FC, useEffect, useRef, useState } from "react"

interface props {
    value: string
    children: React.ReactNode
    top?: boolean
}

const Selector: FC<props> = ({ value, children, top }) => {
  const [show, setShow] = useState<boolean>(false)
  const listener = useRef<() => void>()

  useEffect(() => {
    const handler = () => {
      setShow(false)
    }

    document.addEventListener("hideSelector", handler)

    listener.current?.()
    listener.current = () => document.removeEventListener("hideSelector", handler)
  }, [show])

  useEffect(() => {
    const listener = () => setShow(false)

    window.addEventListener("click", listener)

    return () => window.removeEventListener("click", listener)
  }, [show])

  return (
    <div className="relative w-full">
      <button
        className={[
          "select w-full bg-white relative transition-all",
          show && (top ? "rounded-t-none" : "rounded-b-none"),
        ].asClass}
        children={value}
        onClick={e => {
          e.stopPropagation()
          setShow(old => {
            if (old) return false
            listener.current?.()

            const event = new CustomEvent("hideSelector")
            document.dispatchEvent(event)

            return true
          })
        }}
      />
      <div
        className={[
          "absolute inset-y-0 z-[110] right-2 flex items-center px-2 pointer-events-none transition-transform",
          show ? (top ? "rotate-90" : "-rotate-90") : (top ? "-rotate-90" : "rotate-90"),
        ].asClass}
      >
        &gt;
      </div>
      <div
        className={[
          "absolute w-full overflow-y-hidden max-h-[200px] z-[1000]",
          "min-h-full",
          top ? "bottom-full" : "top-full",
          show ? "pointer-events-auto" : "pointer-events-none",
        ].asClass}
      >
        <div
          className={[
            "divide-y bg-white divide-y-slate-200 max-h-[200px] overflow-y-auto",
            "transition-all duration-300 ease-in-out border-x border-slate-200",
            (top ? [
              "border-t rounded-t-lg w-full",
              !show ? "translate-y-full rounded-lg max-h-full opacity-0 pointer-events-none" : "translate-y-0 opacity-1",
            ] : [
              "border-b rounded-b-lg",
              !show ? "-translate-y-full rounded-lg max-h-full opacity-0 pointer-events-none" : "translate-y-0 opacity-1",
            ]).asClass,
          ].asClass}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Selector
