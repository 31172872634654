import { Logo } from "assets"
import firebase from "config/firebase"
import { FirebaseError } from "firebase/app"
import { signInWithEmailAndPassword } from "firebase/auth"
import { handleError } from "helpers/errors"
import { FC, useMemo, useState } from "react"
import { useNavigate } from "react-router"

const AuthPage: FC = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const navigate = useNavigate()

  const emailValid = email.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g)

  const onSignin = () => {
    if (!emailValid) return handleError("Please enter a valid email address")
    if (!password) return handleError("Please enter a password")
    setSubmitting(true)
    signInWithEmailAndPassword(firebase.auth, email, password).catch((err: FirebaseError) => {
      switch (err.code) {
      case "auth/invalid-email":
        handleError("Please enter a valid email address")
        break
      case "auth/user-disabled":
        handleError("This account has been disabled")
        break
      case "auth/user-not-found":
        handleError("This account does not exist")
        break
      case "auth/wrong-password":
        handleError("Incorrect password")
        break
      default:
        handleError("An unknown error occurred")
      }
      setSubmitting(false)
    })
  }

  const beenInApp = useMemo(() => {
    try {
      return localStorage.getItem("beenInApp") === "true"
    } catch {
      // localStorage is disabled
      return true
    }
  }, [])

  return (
    <div className="w-full bg-slate-100 h-full overflow-y-auto flex justify-center items-center">
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <Logo className="w-8 h-auto" />
          <span className="font-semibold text-2xl text-slate-900 grow">Sign In</span>
          <span
            className={[
              "text-xs text-indigo-600 hover:underline font-medium cursor-pointer",
              beenInApp && "hidden",
            ].asClass}
            onClick={() => navigate("/auth/signup")}
          >
            Create an Account
          </span>
        </header>
        <main className="flex-cool p-8 gap-8">
          <div className="flex-cool gap-4">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={e => setEmail(e.currentTarget.value)}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-8 flex items-center justify-between gap-2.5">
          <span className="text-sm text-indigo-600 hover:underline font-medium cursor-pointer" onClick={() => navigate("/auth/forgot")}>
            Forgot Password
          </span>
          <button disabled={submitting} onClick={onSignin} className="btn-primary">Continue</button>
        </footer>
      </div>
    </div>
  )
}

export default AuthPage
