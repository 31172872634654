import Paginator from "components/Paginator"
import TableSpanner from "components/TableSpanner"
import FullLoader from "components/loaders/FullLoader"
import { ChangeEvent, FC, useEffect, useRef, useState } from "react"
import Talent from "./Talent"
import { useSearchParams } from "react-router-dom"
import useAwaitableModal from "hooks/useAwaitableModal"
import Tooltip from "components/Tooltip"
import useRecruiter from "contexts/User/useRecruiter"
import Subscribe from "modals/Subscribe"
import useTalentFinderContext from "contexts/TalentFinder/useTalentFinder"
import Ordering from "./Ordering"
import ManualFilters from "./ManualFilters"
import { SearchIcon } from "assets/icons"
import TalentFinderLocker from "./Locked"

const TalentFinderPage: FC = () => {
  let { page, pages, setPage, loading, talents, scroll, total } = useTalentFinderContext()

  const [sp, setSp] = useSearchParams()
  const [search, setSearch] = useState(sp.get("search") || "")
  const type = sp.get("type")
  const [requireSub, SubscribeModal] = useAwaitableModal(Subscribe, {} as any)
  const scrollRef = useRef<HTMLDivElement>(null)
  const searchChangeTimeout = useRef<NodeJS.Timeout>()

  const user = useRecruiter()

  const canUseTop = ["ultimate"].includes(user.subscription?.plan as string)
  const canUseRising = ["premium", "ultimate"].includes(user.subscription?.plan as string)

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    if (searchChangeTimeout.current) {
      clearTimeout(searchChangeTimeout.current)
    }
    setSearch(e.target.value)
    searchChangeTimeout.current = setTimeout(() => {
      setSp(old => {
        old.set("search", e.target.value)

        return old
      })
    }, 500)
  }

  const setManual = () => {
    setSp(old => {
      old.delete("type")

      return old
    })
  }

  const setTop = () => {
    setSp(old => {
      old.set("type", "top")

      return old
    })
  }

  const setRising = () => {
    setSp(old => {
      old.set("type", "rising")

      return old
    })
  }

  useEffect(() => {
    const el = scrollRef.current
    if (!el) return
    const onScroll = () => {
      scroll.current = el.scrollTop
    }

    el.addEventListener("scroll", onScroll)

    return () => {
      el.removeEventListener("scroll", onScroll)
    }
  }, [])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scroll({ top: scroll.current, behavior: "auto" })
    }
  }, [scrollRef.current])

  // @ts-ignore
  if (user.isEnterprise && !user.hasTalentFinder) return <TalentFinderLocker />

  return (
    <div className="w-full h-full overflow-hidden pt-5 px-5 flex flex-col">
      <div className="flex items-center pb-5 gap-4">
        <span className="font-semibold text-2xl text-primary-blue">Talent Finder</span>
      </div>
      {SubscribeModal}
      <div className="grid border-t-[0.3px] border-t-[#D3E0EB] pt-6 relative overflow-hidden grow">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4 p-2.5 mb-5">
          <div className="relative">
            <SearchIcon className="absolute top-1/2 left-2.5 transform -translate-y-1/2 z-10" />
            <input
              value={search}
              onChange={onChangeSearch}
              className="rounded-2.5 bg-white drop-shadow-md py-3.5 pr-5
               pl-10 text-xs text-text-secondary placeholder:opacity-50 outline-none"
              placeholder="Search talents"
            />
          </div>
          <div className="flex items-center gap-5 grow">
            <div
              className="flex items-center gap-1 p-1 bg-white rounded-2.5 drop-shadow-xl"
              style={{
                boxShadow: "0px 4px 4px 0px #003F531A inset",
              }}
            >
              <button
                className={[
                  "btn-primary !py-2 !px-4",
                  !type ? "hover:rounded-full" : "bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white",
                ].asClass}
                onClick={setManual}
              >
                All Talents
              </button>
              <Tooltip text={canUseTop ? "Displays Top Talent Only" : "Upgrade Your Plan"} position="top">
                <button
                  className={[
                    "btn-primary !py-2 !px-4",
                    type === "top" ? "hover:rounded-full" : "bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white",
                    !canUseTop && "grayscale hover:rounded-full",
                  ].asClass}
                  onClick={canUseTop ? setTop : requireSub}
                >
                  Top Talents
                </button>
              </Tooltip>
              <Tooltip text={canUseRising ? "See Candidates Upskilling The Fastest" : "Upgrade Your Plan"} position="top">
                <button
                  className={[
                    "btn-primary !py-2 !px-4",
                    type === "rising" ? "hover:rounded-full" : "bg-white text-indigo-600 hover:bg-indigo-600 hover:text-white",
                    !canUseRising && "grayscale hover:rounded-full",
                  ].asClass}
                  onClick={canUseRising ? setRising : requireSub}
                >
                  Rising Talents
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="z-20 flex gap-5">
            <Ordering />
            <ManualFilters />
          </div>
        </div>
        <div
          ref={scrollRef}
          className="w-full h-full relative
          grow
        bg-white rounded-2.5 overflow-auto"
        >
          <table className="talent-finder-table w-full">
            <thead className="sticky top-0 z-10">
              <th>
                Talent
              </th>
              <th>
                <Tooltip
                  text="Indicates whether the candidate is looking for full time, part time, or contract work.
                  Flexible indicates they are available for any of the above."
                  position="top"
                  className="z-[15]"
                >
                  Employment
                </Tooltip>
              </th>
              <th>
                Location
              </th>
              <th className="!text-center">
                QBO Certifications
              </th>
              <th className="!text-center">
                <Tooltip
                  text="Our bookkeepers are ranked against each other based on their verified skills and
                         level of activity in our training environment,
                         so you can see who knows what they're doing"
                  position="top-end"
                  className="z-[15]"
                >
                  Knowledge
                </Tooltip>
              </th>
              <th className="!text-center">
                <Tooltip
                  text={
                    "High - Candidate upskilled within the last two weeks.\n\nLow - Candidate has not upskilled in the last 3 months."
                  }
                  position="top"
                  className="whitespace-pre-line z-[15]"
                >
                  Consistency
                </Tooltip>
              </th>
              <th className="!text-center">
                <Tooltip
                  text={"A bookkeeper's speed shows how quickly they are advancing through the training environment."}
                  position="top"
                  className="whitespace-pre-line z-[20]"
                >
                  Speed
                </Tooltip>
              </th>
              <th className="!text-center">
                Ranking
              </th>
            </thead>
            <tbody>
              {
                talents === undefined
                  ? <TableSpanner rows={5} cols={8} children={<FullLoader />} />
                  : talents.length === 0
                    ? (
                      <TableSpanner
                        rows={5}
                        cols={8}
                        children={(
                          <div className="text-text-secondary flex flex-col items-center justify-center">
                            No talents found
                          </div>
                        )}
                      />
                    )
                    : talents.map(talent => (
                      <Talent key={talent.id} talent={talent} />
                    ))
              }
            </tbody>
          </table>
        </div>
        <div className="flex justify-center items-center mt-5 pt-5 border-t-[0.5px] border-t-[#D3E0EB]">
          {!loading ? (
            <span className="text-sm text-text-secondary text-left grow">
              Total Talents Found: <span className="text-[#151D48] font-medium">{total}</span>
            </span>
          ) : (
            <span className="text-sm text-text-secondary text-left grow">
              Total Talents Found: <span className="text-[#151D48] font-medium">...</span>
            </span>
          )}
          <Paginator page={page} setPage={setPage} loading={loading} total={pages} />
          {!loading ? (
            <span className="text-sm text-text-secondary text-left grow invisible">
              Total Talents Found: <span className="text-[#151D48] font-medium">{total}</span>
            </span>
          ) : (
            <span className="text-sm text-text-secondary text-left grow invisible">
              Total Talents Found: <span className="text-[#151D48] font-medium">...</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default TalentFinderPage
