import recruiterApi from "api/recruiter"
import Modal from "components/Modal"
import Selector from "components/Selector"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"

interface props {
  street1: string
  street2?: string
  city: string
  state: string
  country: string
}

const EditAddress: AwaitableModal<props, props> = ({ initialData, open, onCancel, resolve }) => {
  const [street1, setStreet1] = useState(initialData.street1)
  const [street2, setStreet2] = useState(initialData.street2)
  const [city, setCity] = useState(initialData.city)
  const [state, setState] = useState(initialData.state)
  const [country, setCountry] = useState(initialData.country)
  const [submitting, setSubmitting] = useState(false)

  const onEdit = () => {
    if (submitting) return
    if (!street1) return handleError("Please enter the street address")
    if (!city) return handleError("Please enter the city")
    if (!state) return handleError("Please enter the state")
    if (!country) return handleError("Please enter the country")
    setSubmitting(true)
    recruiterApi.updateAddress({ street1, street2, city, country, state }).then(() => {
      resolve({ street1, street2, city, country, state })
    }).finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <span className="font-semibold text-2xl text-slate-900">Edit Company Address</span>
        </header>
        <main className="flex-cool p-8 gap-8">
          <div className="flex-cool gap-4">
            <input
              type="text"
              placeholder="Address 1"
              value={street1}
              onChange={e => setStreet1(e.currentTarget.value)}
            />
            <input
              type="text"
              placeholder="Address 2"
              value={street2}
              onChange={e => setStreet2(e.currentTarget.value)}
            />
            <input
              type="text"
              placeholder="City"
              value={city}
              onChange={e => setCity(e.currentTarget.value)}
            />
            <input
              type="text"
              placeholder="State"
              value={state}
              onChange={e => setState(e.currentTarget.value)}
            />
            <input
              type="text"
              placeholder="Country"
              value={country}
              onChange={e => setCountry(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-8 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={onCancel}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onEdit} className="btn-primary">Save Changes</button>
        </footer>
      </div>
    </Modal>
  )
}

export default EditAddress
