import { FC } from "react"

const Toggle: FC<{checked: boolean}> = ({ checked }) => {
  return (
    <div
      className={["p-px relative w-[1.40rem] h-[0.84rem] border border-[#D9E4EF]",
        "transition-all rounded-full",
        checked ? "bg-[#F6F9FC]" : "bg-slate-200"].asClass}
    >
      <div
        className={["w-2.5 h-2.5 shrink-0 rounded-full transition-all absolute left-1/2 top-px",
          checked ? "translate-x-0 bg-primary-purple" : "-translate-x-full bg-white"].asClass}
      />
    </div>
  )
}

export default Toggle
