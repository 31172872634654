import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { IBadgeTranslation, ITalentPreview } from "./types"

class RecruiterApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async setVisibility(visible: boolean) {
    return this.api.patch("/user/accountant/visibility", { visible })
  }

  async updateProfile(data: {
    name: string,
    type?: string
  }) {
    return this.api.patch("/user/recruiter/profile", data)
  }

  async updateAddress(data:{
    street1: string
    street2?: string
    city: string
    state: string
    country: string
  }) {
    return this.api.patch("/user/recruiter/address", data)
  }

  async getTalents(page: number = 1) {
    const sp = new URLSearchParams()
    sp.set("page", page.toString())

    const search = new URLSearchParams(window.location.search)

    if (search.has("employment")) sp.append("employment", search.get("employment")!)
    if (search.has("search")) sp.append("search", search.get("search")!)
    if (search.has("type")) sp.append("type", search.get("type")!)
    if (search.has("radius")) sp.append("radius", search.get("radius")!)
    if (search.has("zip")) sp.append("zip", search.get("zip")!)
    if (search.has("certifications")) sp.append("certifications", search.get("certifications")!)
    if (search.has("skills")) sp.append("skills", search.get("skills")!)
    if (window.location.pathname.startsWith("/employees")) sp.append("enterprise", "true")
    if (search.has("ordering")) sp.append("orderBy", search.get("ordering")!)

    return this.api.get(`/talent?${sp.toString()}`).then(res => res.data as {
      pages: number,
      total: number,
      talents: ITalentPreview[]
    })
  }

  async addCandidate(id: string) {
    return this.api.get(`/talent/${id}`)
  }

  async deleteCandidate(id: string) {
    return this.api.delete(`/candidates/${id}`)
  }

  async getWatchedDiff(id: string) {
    return this.api.get(`/candidates/watchlist/${id}`).then(res => res.data as {skills: Record<string, IBadgeTranslation> })
  }
}

const recruiterApi = new RecruiterApi()

export default recruiterApi
