import { ChevronIcon } from "assets/icons"
import { Dispatch, PropsWithChildren, SetStateAction, forwardRef, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react"

interface props {
    value: string
    dontCloseOnSelect?: boolean
}

export interface DropdownRef {
  setOpen: Dispatch<SetStateAction<boolean>>
}

const Dropdown = forwardRef<DropdownRef, PropsWithChildren<props>>(({ value, children, dontCloseOnSelect }, ref) => {
  const [height, setHeight] = useState(0)
  const contentWrapper = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!contentWrapper.current) return
    const handler = () => {
      setHeight(contentWrapper.current?.scrollHeight || 0)
    }

    handler()
    const observer = new ResizeObserver(handler)
    observer.observe(contentWrapper.current)

    return () => {
      observer.disconnect()
    }
  }, [contentWrapper])

  const dropdownStyle = useMemo(() => ({
    height: open ? height : 0,
    opacity: open ? 1 : 0,
    pointerEvents: (open ? "all" : "none") as any,
  }), [height, open])

  useImperativeHandle(ref, () => ({
    setOpen,
  }), [])

  useEffect(() => {
    if (!open) return
    const handler = (e: MouseEvent) => {
      setOpen(false)
    }

    document.body.addEventListener("click", handler)

    return () => {
      document.body.removeEventListener("click", handler)
    }
  }, [open])

  return (
    <div onClick={e => e.stopPropagation()} className="flex flex-col relative z-[10]">
      <button
        onClick={e => {
          setOpen(old => !old)
        }}
        className="flex gap-3 items-center justify-between border text-xs
         border-border-dropdown rounded-2.5 p-3 pr-1.5 font-light text-text-secondary z-10"
      >
        <span>
          {value}
        </span>
        <div
          className="rounded-full bg-white flex items-center justify-center"
        >
          <ChevronIcon className="transform transition-transform" style={{ transform: !open ? "rotate(180deg)" : "rotate(0deg)" }} />
        </div>
      </button>
      <div
        onClick={e => e.stopPropagation()}
        onClickCapture={e => {
          !dontCloseOnSelect && setOpen(false)
        }}
        ref={contentWrapper}
        className="overflow-hidden w-max transition-all mt-1 drop-shadow-xl absolute top-full right-0 z-10"
        style={dropdownStyle}
      >
        {children}
      </div>
    </div>
  )
})

export default Dropdown
