import { FC, useEffect, useState } from "react"
import adminApi from "api/admin"
import { IBooksmatchStats } from "api/types"
import FullpageLoader from "components/loaders/FullpageLoader"
import useAwaitableModal from "hooks/useAwaitableModal"
import UsersByCreatedDate from "./modals/UsersByCreatedDate"

const BooksmatchStatsPage: FC = () => {
  const [stats, setStats] = useState<IBooksmatchStats>()

  const [openRecuiters, RecruitersModal] = useAwaitableModal(
    UsersByCreatedDate,
    {
      title: "Recruiters",
      profiles: stats?.recruitersSummary || [],
    },
  )

  useEffect(() => {
    adminApi.getStats().then(setStats)
  }, [])

  const onRecruiters = () => {
    openRecuiters({
      title: "Recruiters",
      profiles: stats?.recruitersSummary || [],
    }).then(() => {})
  }

  if (!stats) return <FullpageLoader />

  return (
    <div className="w-full h-full">
      {RecruitersModal}
      <div className="pt-8 px-8 gap-8 flex-cool h-full">
        <div className="gap-8 flex items-center mobile:flex-col mobile:gap-4">
          <span className="font-semibold text-3xl text-slate-900">Booksmatch Stats</span>
          <button
            onClick={
              () => window.open(
                // eslint-disable-next-line max-len
                "https://console.firebase.google.com/project/booksmatch-f6ac0/analytics/app/web:N2IyMGMyNDUtOWE1NC00MGNjLWE3NmMtYmYzMGI5MzY0NjUx/overview"
                , "_blank",
              )
            }
            className="btn-primary"
          >
            Firebase Analytics
          </button>
        </div>
        <div
          className="flex-cool min-h-min
          grow
        bg-white rounded-3xl divide-y
        divide-y-slate-200 overflow-hidden overflow-y-auto"
        >
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Total Users</span>
              <span className="text-slate-900 text-base grow">{stats?.nUsers}</span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Accountants</span>
              <span className="text-slate-900 text-base grow">{stats?.nAccountants}</span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Gym Members</span>
              <span className="text-slate-900 text-base grow">{stats?.talents}</span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Talents Open To Work</span>
              <span className="text-slate-900 text-base grow">{stats?.visibleAccountants}</span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Recruiters</span>
              <span
                className="text-primary-purple text-base grow cursor-pointer hover:underline"
                onClick={onRecruiters}
              >
                {stats?.nRecruiters}
              </span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Watched</span>
              <span className="text-slate-900 text-base grow">{stats?.watched}</span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Conversations started</span>
              <span className="text-slate-900 text-base grow">{stats?.nChats}</span>
            </div>
          </div>
          <div className="flex h-max p-8 gap-6 items-center w-full mobile:flex-col mobile:text-center">
            <div className="grid grid-cols-2 grow gap-6 mobile:grid-cols-1">
              <span className="text-slate-500 text-base grow">Messages Sent</span>
              <span className="text-slate-900 text-base grow">{stats?.messages}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BooksmatchStatsPage
