import { default as USCitiesByZipCode } from "./stateCityByZip.json"

export const USZipCodesByState = Object.entries(USCitiesByZipCode).reduce((acc, [zip, city]) => {
  acc[city.state] = acc[city.state] || []
  acc[city.state].push(zip)

  return acc
}, {} as { [key: string]: string[] })

export {
  USCitiesByZipCode,
}

export const USStates = Object.keys(USZipCodesByState)
