import { IAccountant } from "api/types"
import { FC, useState, useMemo } from "react"
import Pie from "./Pie"
import PathTo from "./PathTo"
import { CopyIcon } from "assets/icons"
import Dropdown from "components/Dropdown"

interface props {
    user: IAccountant
}

const skillGroupsOrder = [3, 8, 6, 1, 7, 4, 10, 2, 5, 9]
const skillGroupsOrderNormal = [3, 4, 1, 8, 6, 7, 9, 5, 2, 10]
const skillGroupColors = [
  ["#BCDCFF", "#2E90FA"],
  ["#FFBAEB", "#EE46BC"],
  ["#C3ECFF", "#0BA5EC"],
  ["#FFD7A2", "#FF9100"],
  ["#B9C3FF", "#4E5BA6"],
  ["#FFD8C2", "#FB6514"],
  ["#A4FFD4", "#12B76A"],
  ["#FFB3C5", "#F63D68"],
  ["#E0D1FF", "#9E77ED"],
  ["#F5B9B9", "#FF3636"],
] as [string, string][]

const reverseAfterHalf = <T extends any[] = unknown[]>(arr: T) => {
  const half = Math.floor(arr.length / 2)
  const firstHalf = arr.slice(0, half)
  const secondHalf = arr.slice(half).reverse()

  return [half, [...firstHalf, ...secondHalf]] as const
}

const SkillGroupsBreakdown: FC<props> = ({ user }) => {
  const [breakdown, setBreakdown] = useState<string>()

  const [half, orderReversedAfter5] = useMemo(() => reverseAfterHalf(skillGroupsOrder), [])

  const pieces = useMemo(() => orderReversedAfter5.map(groupId => {
    const groupSum = user.activityOverview.skillsEarned.filter(sk => sk.skillGroupId === groupId).length

    return [groupId, groupSum]
  }, {} as Record<string, number>).map(([group, count], index) => {
    const groupName = user.activityOverview.groupsData.find(g => g.id === group)?.name || ""

    return {
      name: group,
      groupName,
      value: count,
      colors: skillGroupColors[index],
    }
  }).reverse(), [orderReversedAfter5, user.activityOverview.groupsData, user.activityOverview.skillsEarned])

  const breakdownCombined = useMemo(() => {
    if (!breakdown) return undefined
    const [, rA5groupColors] = reverseAfterHalf(skillGroupColors)
    const groupColors = rA5groupColors.find((_, i) => i === skillGroupsOrder.indexOf(+breakdown))
    const skillsWithinGroup = user.activityOverview.skillsEarned.filter(sk => sk.skillGroupId === +breakdown)
    if (!groupColors) return undefined
    const totalsByName = skillsWithinGroup.reduce((acc, curr) => {
      if (!acc[curr.skillName]) {
        acc[curr.skillName] = 0
      }
      acc[curr.skillName]++

      return acc
    }, {} as Record<string, number>)

    const mapped = Object.entries(totalsByName).map(([skillName, value], i) => ({
      name: skillName.replaceAll(" ", "-") + breakdown.toString(),
      group: breakdown,
      skillName,
      value: value,
      colors: groupColors,
    }))

    return reverseAfterHalf(mapped)
  }, [breakdown, user.activityOverview.skillsEarned])

  const [breakdownHalf, breakdownPieces] = breakdownCombined || [0, undefined]

  const breakdownGroup = breakdown ? user.activityOverview.groupsData.find(g => g.id === +breakdown) : undefined

  return (
    <>
      <div className="bg-white col-span-2 flex flex-col rounded-2.5 p-4 drop-shadow-md">
        <div className="flex items-center border-b-[0.3px] border-b-[rgba(211, 224, 235, 1)] pb-4">
          <span className="text-base text-primary-blue font-semibold">Skills By Group</span>
        </div>
        <div data-wrapper-for="skillsByGroupPie" className="flex pt-4 min-h-max h-full items-center">
          <div className="flex flex-col justify-between h-full w-1/2">
            {
              pieces.slice(half).reverse().map(({ name, value, groupName, colors }, i) => {
                const selected = name.toString() === breakdown?.toString()

                return (
                  <div
                    key={name}
                    onClick={() => setBreakdown(name)}
                    className="relative max-w-fit border-b group cursor-pointer"
                    style={{
                      borderBottomColor: selected ? colors[1] : "#D3E0EB",
                    }}
                  >
                    <span
                      className={[
                        "text-text-secondary text-xs leading-[100%] pb-1",
                        "group-hover:text-primary-blue group-hover:underline transition-all",
                      ].asClass}
                      style={{ color: selected ? colors[1] : "#66628D" }}
                    >
                      {groupName} ({value})
                    </span>
                    <PathTo
                      pieId="skillsByGroupPie"
                      position="left"
                      index={pieces.length - i - 1}
                      color={selected ? colors[1] : "#D3E0EB"}
                    />
                  </div>
                )
              })
            }
          </div>
          <Pie id="skillsByGroupPie" pieces={pieces?.length ? pieces : undefined}>
            <div className="w-full h-full flex flex-col overflow-visible items-center justify-center text-center">
              <span className="text-3xl font-semibold text-primary-blue">
                {user.activityOverview.skillsEarned.length}
              </span>
              <span className="text-text-secondary text-base">
                Skills Verified
              </span>
            </div>
          </Pie>
          <div className="flex flex-col justify-between items-end h-full w-1/2">
            {
              pieces.slice(0, half).map(({ name, value, groupName, colors }, i) => {
                const selected = name.toString() === breakdown?.toString()

                return (
                  <div
                    key={name}
                    onClick={() => setBreakdown(name)}
                    className="relative max-w-fit border-b group text-right"
                    style={{
                      borderBottomColor: selected ? colors[1] : "#D3E0EB",
                    }}
                  >
                    <span
                      className={[
                        "text-text-secondary text-xs leading-[100%] pb-1 cursor-pointer",
                        "group-hover:text-primary-blue group-hover:underline transition-all",
                      ].asClass}
                      style={{ color: selected ? colors[1] : "#66628D" }}
                    >
                      {groupName} ({value})
                    </span>
                    <PathTo pieId="skillsByGroupPie" position="right" index={i} color={selected ? colors[1] : "#D3E0EB"} />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
      <div className="bg-white col-span-2 flex flex-col rounded-2.5 p-4 min-h-full drop-shadow-md z-[10]">
        <div className="flex items-center border-b-[0.3px] border-b-[rgba(211, 224, 235, 1)] pb-4">
          <span className="text-base text-primary-blue font-semibold grow">Skills Group Insights</span>
          <Dropdown value={breakdownGroup?.name || "Select Group"}>
            <div className="flex flex-col bg-white rounded-2.5 p-2 gap-1">
              {
                skillGroupsOrderNormal.map(group => {
                  const groupData = user.activityOverview.groupsData.find(g => g.id === group)!
                  const name = groupData.name

                  return (
                    <button
                      key={group}
                      onClick={() => setBreakdown(group.toString())}
                      className="px-3 py-2.5 text-xs text-text-secondary rounded-2.5 shadow-xs transition-all
                       border border-transparent hover:border-border-dropdown bg-white w-full text-left hover:shadow-sm"
                    >
                      {name}
                    </button>
                  )
                })
              }
            </div>
          </Dropdown>
        </div>
        <div data-wrapper-for="skillsBreakdown" className="flex pt-4 items-center min-h-max h-full w-full">
          <div className="flex flex-col justify-between w-1/2 h-full gap-2">
            {
              breakdownPieces?.slice(breakdownHalf).reverse().map(({ group, value, skillName }, i) => {

                return (
                  <div key={group + skillName} className="relative max-w-fit border-b border-b-[#D3E0EB]">
                    <span
                      className={[
                        "text-text-secondary text-xs leading-[100%] pb-1",
                      ].asClass}
                    >
                      {skillName} ({value})
                    </span>
                    <PathTo pieId="skillsBreakdown" position="left" index={breakdownPieces.length - 1 - i} color="#D3E0EB" />
                  </div>
                )
              })
            }
          </div>
          <Pie id="skillsBreakdown" pieces={breakdownPieces?.length ? breakdownPieces as any[] : undefined} >
            <div className="w-full h-full flex flex-col overflow-visible items-center justify-center text-center">
              <span className="text-base font-semibold text-primary-blue">
                {breakdownGroup?.name ? breakdownGroup.name : <CopyIcon />}
              </span>
              <span className="text-text-secondary text-base">
                {breakdown
                  ? `${breakdownPieces?.reduce((acc, curr) => acc + curr.value, 0) || 0}/${breakdownGroup?.total} Skills Verified`
                  : "Select Group"}
              </span>
            </div>
          </Pie>
          <div className="flex flex-col justify-between items-end h-full w-1/2 gap-2">
            {
              breakdownPieces?.slice(0, breakdownHalf).map(({ group, value, skillName }, i) => {

                return (
                  <div key={group + skillName} className="relative max-w-fit border-b text-right border-b-[#D3E0EB]">
                    <span
                      className="text-text-secondary text-xs leading-[100%] pb-1"
                    >
                      {skillName} ({value})
                    </span>
                    <PathTo pieId="skillsBreakdown" position="right" index={i} color="#D3E0EB" />
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default SkillGroupsBreakdown
