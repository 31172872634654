import { Dispatch, FC, SetStateAction } from "react"
import { ChevronIcon } from "assets/icons"

interface props {
  page: number
  total: number
  setPage: Dispatch<SetStateAction<number>>
  loading: boolean
}

const Paginator: FC<props> = ({ setPage, page, total, loading }) => {
  const pagesLeft = total - page

  const middlePages = Array.from({ length: Math.min(2, total - 2) }, (_, i) => {
    if (total === 3) return 2
    if (page === total - 1) return page - 1 + i
    if (page + 3 > total) return page + i
    if (page === 0) return page + i + 2

    return page + i + 1
  })

  const buttonClass = `bg-white w-12 h-12 rounded-2.5 [&:not(:disabled):hover]:bg-[#F6F9FC]
   text-base text-[#151D488F] flex-cool items-center justify-center transition-all`
  const activeButtonClass = "bg-[#F6F9FC] w-12 h-12 rounded-2.5 text-base text-primary-purple"

  return (
    <div className="flex gap-4 items-center select-none">
      {!total ? (<span className="text-base-900">...</span>) : (
        <>
          <button
            className={page === 0 ? activeButtonClass : buttonClass}
            disabled={loading}
            onClick={() => setPage(0)}
          >
            1
          </button>
          {page > 1 && total > 4 && (
            <button onClick={() => setPage(old => old - 1)} className={buttonClass}>
              <ChevronIcon
                width={12}
                height={12}
                className="-rotate-90 [&_path]:fill-black"
              />
            </button>
          )}
          {middlePages.map(n => (
            <button
              key={n}
              className={page === n - 1 ? activeButtonClass : buttonClass}
              disabled={loading}
              onClick={() => setPage(n - 1)}
            >
              {n}
            </button>
          ))}
          {
            total > 4 && pagesLeft > 3 && (
              <button onClick={() => setPage(old => old + 1)} className={buttonClass}>
                <ChevronIcon
                  width={12}
                  height={12}
                  className="rotate-90 [&_path]:fill-black"
                />
              </button>
            )
          }
          {total > 1 && (
            <button
              className={page === total - 1 ? activeButtonClass : buttonClass}
              disabled={loading}
              onClick={() => setPage(total-1)}
            >
              {total}
            </button>
          )}
        </>
      )}
    </div>
  )
}

export default Paginator
