import { ICraigMessage } from "api/types"
import { createContext } from "react"

export type ICraigRoute = undefined

interface ICraigContext {
    messages?: ICraigMessage[]
    sending: boolean
    sendMessage: (message: string, url: string | null, onSuccess?: () => void) => void
    route?: ICraigRoute
    setRoute: (route: ICraigRoute) => void
}

const CraigContext = createContext<ICraigContext>(undefined as any)

export default CraigContext
