import api from "api"
import Loader from "components/loaders/Loader"
import { FC, useEffect, useState } from "react"
import { Navigate, useNavigate, useParams } from "react-router"

const CodeAuthPage: FC = () => {
  const code = useParams()?.code
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    try {
      localStorage.setItem("beenInApp", "true")
    } catch {
      // localStorage is disabled
    }
    if (!code || loading) {
      return
    }
    setLoading(true)
    api.authMagic(code).catch(() => navigate("/auth", { replace: true }))
  }, [code, navigate, loading])

  if (!code) {
    return <Navigate to="/auth" replace />
  }

  return (
    <div className="w-full h-full flex items-center justify-center">
      <Loader colorClass="indigo-600" />
    </div>
  )
}

export default CodeAuthPage
