import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { IBadgeTranslation, ICandidate, IRecruiter, IUser } from "./types"
import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  signInWithCustomToken,
  signInWithEmailAndPassword,
} from "firebase/auth"
import { handleError } from "helpers/errors"

class Api {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async authEmail(email: string, password: string) {
    return signInWithEmailAndPassword(firebase.auth, email, password)
  }

  async authMagic(magic: string) {
    return this.api.post("/auth/magic", { magic }).then(res => signInWithCustomToken(firebase.auth, res.data.token))
  }

  async getProfile(uid?: string) {
    return this.api.get(`/user/${uid || "me"}`).then(res => res.data as IUser | null)
  }

  async updateEmail(email: string) {
    return this.api.patch("/user/email", { email }).then(res => signInWithCustomToken(firebase.auth, res.data))
  }

  async updatePassword(oldPass: string, password: string) {
    if (!firebase.auth.currentUser) {
      handleError("You are not logged in")
      throw new Error()
    }
    try {
      await reauthenticateWithCredential(
        firebase.auth.currentUser,
        EmailAuthProvider.credential(firebase.auth.currentUser.email as string, oldPass),
      )
    } catch (err: any) {
      if (err.code === "auth/wrong-password") {
        handleError("Your old password is incorrect")
      }
      throw err
    }

    return this.api.patch("/user/password", { password }).then(res => signInWithCustomToken(firebase.auth, res.data))
  }

  async updateProfilePic(image: string) {
    return this.api.patch("/user/image", { image }).then(res => res.data as string | undefined)
  }

  async signup(data: {email: string, password: string, name: string, type: string, image?: string}) {
    return this.api.post("/auth/recruiter", data).then(res => {
      return signInWithCustomToken(firebase.auth, res.data.token).then(() => res.data.user as IRecruiter)
    })
  }

  async contactUs(data: {subject: string, message: string}) {
    return this.api.post("/contact-us", data)
  }

  async getCandidates() {
    return this.api.get("/candidates").then(res => res.data as ICandidate[])
  }

  async updateCandidates(updates: {
    changes: Record<string, {note?: string, state?: "contact" | "progress" | "done", inactive?: boolean}>
    newOrder?: string[]
  }) {
    return this.api.patch("/candidates", updates)
  }

  async leaveFeedback(id: string, feedback: string, rating: number) {
    return this.api.post(`/candidates/feedback/${id}`, { feedback, rating })
  }

  async getBadgeTranslations() {
    return this.api.get("/config/badges").then(res => res.data as Record<string, IBadgeTranslation>)
  }

  async getCourses() {
    return this.api.get("/config/courses").then(res => res.data as Record<string, string>)
  }

  async authCookie(data?: {cookie: string}) {
    return this.api.post("/auth/cookie", data).then(res => res.data === "OK" ? undefined : res.data as string)
  }

  async createContact(user: string) {
    return this.api.post("/user/contact/"+user).then(res => res.data as string)
  }
}

const api = new Api()

export default api
