import api from "api"
import Modal from "components/Modal"
import firebase from "config/firebase"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"

const EditPassword: AwaitableModal<{}, {}> = ({ initialData, open, onCancel, resolve }) => {
  const [oldPass, setOldPass] = useState("")
  const [password, setPassword] = useState("")
  const [passwordConfirm, setPasswordConfirm] = useState("")
  const [submitting, setSubmitting] = useState(false)

  const onEdit = () => {
    if (submitting) return
    if (!oldPass) return handleError("Please enter your old password")
    if (!password) return handleError("Please enter your new password")
    if (!passwordConfirm) return handleError("Please confirm your new password")
    if (password !== passwordConfirm) return handleError("Passwords do not match")
    setSubmitting(true)
    api.updatePassword(oldPass, password).then(async() => {
      await firebase.auth.currentUser?.reload()
      resolve()
    }).finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div className="bg-white rounded-2.5 divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-4">
          <span className="font-semibold text-2xl text-slate-900">Edit Password</span>
        </header>
        <main className="flex-cool p-4 gap-3">
          <div className="flex-cool gap-3">
            <input
              type="password"
              placeholder="Old Password"
              value={oldPass}
              onChange={e => setOldPass(e.currentTarget.value)}
            />
            <input
              type="password"
              placeholder="New Password"
              value={password}
              onChange={e => setPassword(e.currentTarget.value)}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={passwordConfirm}
              onChange={e => setPasswordConfirm(e.currentTarget.value)}
            />
          </div>
        </main>
        <footer className="p-4 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={!submitting ? onCancel : () => {}}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onEdit} className="btn-primary">Save Changes</button>
        </footer>
      </div>
    </Modal>
  )
}

export default EditPassword
