import chatsApi from "api/chats"
import { IChatMessage } from "api/types"
import Modal from "components/Modal"
import useAuthContext from "contexts/Auth/useAuthContext"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"
import { useLocation } from "react-router"

const SearchMessagesModal: AwaitableModal<{selected: IChatMessage}> = ({ open, onCancel, resolve }) => {
  const chat = useLocation().pathname.split("/").pop() as string
  const me = useAuthContext()?.uid
  const [query, setQuery] = useState("")
  const [results, setResults] = useState<IChatMessage[]>()
  const [loading, setLoading] = useState(false)

  const onSearch = () => {
    if (loading) return
    if (query.length < 3) return handleError("Search query must be at least 3 characters long")
    setLoading(true)
    setResults(undefined)
    chatsApi.searchMessages(chat, query).then(setResults).finally(() => setLoading(false))
  }

  return (
    <Modal open={open} close={onCancel}>
      <div
        className="bg-white rounded-2.5 divide-y divide-y-slate-200
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        <header className="flex items-center justify-start gap-2.5 p-4">
          <span className="font-semibold text-2xl text-slate-900">Search Results</span>
        </header>
        <main className="flex-cool pt-4 overflow-x-hidden">
          <div className="flex items-center gap-3 border-b border-b-border-section px-4 pb-3">
            <input
              value={query}
              onChange={e => setQuery(e.target.value)}
              placeholder="Search messages"
              className="p-2.5 w-full border border-slate-200 rounded-lg text-sm text-slate-900 outline-none"
            />
            <button onClick={onSearch} className="btn-primary">Search</button>
          </div>
          <div className="w-full h-full overflow-y-auto flex-cool gap-3 overflow-x-hidden py-4">
            {!loading && !results && <span className="text-sm text-slate-500 p-4 w-full text-center">Search messages by typing above</span>}
            {loading && <span className="text-sm text-slate-500 p-4 w-full text-center">Searching...</span>}
            {results && !results.length && <span className="text-sm text-slate-500p-4 w-full text-center">No results found</span>}
            {results && results.slice(0).sort((a, b) => {
              if (a.createdAt > b.createdAt) return -1
              if (a.createdAt < b.createdAt) return 1

              return 0
            }).map(message => (
              <div
                key={message.id}
                onClick={() => resolve({ selected: message })}
                className="flex items-center gap-3.5 px-4 justify-end group cursor-pointer hover:translate-x-1 transition-transform"
              >
                <span className="text-xs text-slate-500">{new Date(message.createdAt).toLocaleString()}</span>
                <span
                  className="text-sm text-slate-900 px-3 py-2 rounded-lg"
                  style={{
                    whiteSpace: "pre-wrap",
                    wordBreak: "break-word",
                    background: message.author === me ? "linear-gradient(316.04deg, #383BF8 3.82%, #6769FF 89.78%)" : "#F3F4F6",
                    color: message.author === me ? "#fff" : "#66628D",
                  }}
                >
                  {message.content}
                </span>
              </div>
            ))}
          </div>
        </main>
        <footer className="p-4 flex items-center justify-center gap-2.5">
          <button onClick={onCancel} className="btn-secondary">Close</button>
        </footer>
      </div>
    </Modal>
  )
}

export default SearchMessagesModal
