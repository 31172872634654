import TalentFinderProvider from "contexts/TalentFinder/TalentFinderProvider"
import { AdminProfile, Accountant } from "pages/admin"
import { FC } from "react"
import { Navigate, Outlet, Route, Routes } from "react-router"
import useAdmin from "contexts/User/useAdmin"
import AdminAppLayout from "layouts/App/Admin"
import EnterpriseSettingsPage from "pages/admin/EnterpriseSettings"
import ChatsPage, { ChatPage } from "pages/Chat"
import TalentFinder from "pages/admin/TalentFinder"
import BooksmatchStatsPage from "pages/admin/Stats"

const AdminRouter: FC = () => {
  const user = useAdmin()

  return (
    <TalentFinderProvider>
      <Routes>
        <Route path="*" element={user ? <AdminAppLayout /> : <Navigate to="/setup" replace />}>
          <Route path="profile" element={<AdminProfile />} />
          <Route path="stats" element={<BooksmatchStatsPage />} />
          <Route path="talent" element={<TalentFinder />} />
          <Route path="enterprise">
            <Route index element={<EnterpriseSettingsPage />} />
            <Route path=":id" element={<div />} />
          </Route>
          <Route path="accountant/:id" element={<Accountant />} />
          <Route path="chat" element={<ChatsPage />}>
            <Route
              index
              element={
                <div className="grow p-10 flex flex-col justify-start items-center">
                  <span className="text-lg text-primary-blue">No chat selected</span>
                </div>
              }
            />
            <Route path=":chatId" element={<ChatPage />} />
          </Route>
        </Route>
      </Routes>
    </TalentFinderProvider>
  )
}

export default AdminRouter
