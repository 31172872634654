import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { FC, useState } from "react"
import { arrayMove } from "@dnd-kit/sortable"
import ColumnContainer from "./Board/Column"
import ReactDOM from "react-dom"
import ProposalCard from "./Board/ProposalCard"
import useCandidates from "hooks/useCandidates"
import { ICandidate } from "api/types"
import FullLoader from "components/loaders/FullLoader"
import useRecruiter from "contexts/User/useRecruiter"

const CandidatesPage: FC = () => {
  const { candidates, setCandidates, setChanges, patchUpdates } = useCandidates()
  const user = useRecruiter()

  const onContactSales = () => {
    const email = "anthonybirch@fastandeasyqbo.com"
    const subject = "Interested in Talent Finder"
    const body = "Hi Anthony, I'm interested in Talent Finder. Can you please provide more information?"
    window.open(`mailto:${email}?subject=${subject}&body=${body}`)
  }

  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  }))

  const [activeTask, setActiveTask] = useState<ICandidate | null>(null)

  const loading = candidates === undefined

  // @ts-ignore
  const locked = user.isEnterprise && !user.hasTalentFinder

  return (
    <div className="w-full h-full">
      <div className="pt-5 px-5 flex-cool h-full">
        <div className="flex items-center pb-5 gap-4">
          <span className="font-semibold text-2xl text-primary-blue">Candidate Tracker</span>
          <div className="grow" />
        </div>
        {locked ? (
          <div className="flex flex-col w-full h-full items-center justify-center gap-4 rounded-2.5 bg-slate-100">
            <main className="flex-cool px-8 items-center justify-center">
              <span className="text-2xl font-medium text-slate-900">Upgrade Plan</span>
              <span className="text-sm text-slate-900 mt-2">
                You don't have access to this feature.
              </span>
            </main>
            <footer className="px-8 flex items-center justify-center">
              <button onClick={onContactSales} className="btn-primary">Contact Sales</button>
            </footer>
          </div>
        ) : (
          <DndContext
            sensors={sensors}
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
          >
            <div
              className="flex min-h-min
                   bg-white rounded-2.5 divide-x flex-grow border border-border-section
        divide-x-border-section overflow-hidden
         mobile:flex-col mobile:overflow-y-auto mobile:divide-x-0 mobile:divide-y mobile:divide-y-border-section"
            >
              {loading ? <FullLoader /> : (
                <>
                  <ColumnContainer
                    column={{ id: "contact" }}
                    deleteTask={deleteProposal}
                    changeProposalNote={changeProposalNote}
                    proposals={candidates.filter(e => e.state === "contact")}
                    resetChanges={resetChanges}
                  >
                    <div className="flex items-center p-4 gap-4">
                      <span className="grow text-base font-medium text-slate-900">Watchlist</span>
                    </div>
                  </ColumnContainer>
                  <ColumnContainer
                    column={{ id: "progress" }}
                    deleteTask={deleteProposal}
                    changeProposalNote={changeProposalNote}
                    proposals={candidates.filter(e => e.state === "progress")}
                    resetChanges={resetChanges}
                  >
                    <div className="flex items-center p-4 gap-4">
                      <span className="grow text-base font-medium text-slate-900">Interview List</span>
                    </div>
                  </ColumnContainer>
                  <ColumnContainer
                    column={{ id: "done" }}
                    deleteTask={deleteProposal}
                    changeProposalNote={changeProposalNote}
                    proposals={candidates.filter(e => e.state === "done")}
                    resetChanges={resetChanges}
                  >
                    <div className="flex items-center p-4 gap-4">
                      <span className="grow text-base font-medium text-slate-900">Hires</span>
                    </div>
                  </ColumnContainer>
                </>
              )}
            </div>
            {ReactDOM.createPortal(
              <DragOverlay className="z-10">
                {activeTask && (
                  <ProposalCard
                    changeProposalNote={changeProposalNote}
                    proposal={activeTask}
                    deleteTask={deleteProposal}
                    resetChanges={() => resetChanges(activeTask.id)}
                  />
                )}
              </DragOverlay>,
              document.body,
            )}
          </DndContext>
        )}
      </div>
    </div>
  )

  function deleteProposal(id: string) {
    setCandidates(o => o?.filter(c => c.id !== id))
    patchUpdates(false)
  }

  function changeProposalNote(id: string, note: string) {
    setChanges(o => ({ ...o, [id]: { ...o[id], note } }))
    setCandidates(o => o?.map(c => c.id === id ? { ...c, note } : c))
    patchUpdates(false)
  }

  function resetChanges(id: string) {
    setCandidates(o => o?.map(c => c.id === id ? { ...c, changes: 0 } : c))
  }

  function onDragStart(event: DragStartEvent) {
    if (event.active.data.current?.type === "Proposal") {
      setActiveTask(event.active.data.current.proposal)

      return
    }
  }

  function onDragEnd(event: DragEndEvent) {
    patchUpdates()
    setActiveTask(null)

    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveAColumn = active.data.current?.type === "Column"
    if (!isActiveAColumn) return
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveATask = active.data.current?.type === "Proposal"
    const isOverATask = over.data.current?.type === "Proposal"

    if (!isActiveATask) return

    // Im dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      setCandidates(old => {
        if (!old) return
        const activeIndex = old.findIndex(t => t.id === activeId)
        const overIndex = old.findIndex(t => t.id === overId)

        if (old[activeIndex].state !== old[overIndex].state) {
          old[activeIndex].state = old[overIndex].state

          setChanges(o => ({ ...o, [activeId]: { ...o[activeId], state: old[overIndex].state } }))

          return arrayMove(old, activeIndex, overIndex - 1)
        }

        return arrayMove(old, activeIndex, overIndex)
      })
    }

    const isOverAColumn = over.data.current?.type === "Column"

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setCandidates(old => {
        if (!old) return
        const activeIndex = old.findIndex(t => t.id === activeId)

        old[activeIndex].state = overId as any

        setChanges(o => ({ ...o, [activeId]: { ...o[activeId], state: overId as any } }))

        return arrayMove(old, activeIndex, activeIndex)
      })
    }
  }
}

export default CandidatesPage
