import { IAdmin } from "api/types"
import useMaybeNotSetupUser from "contexts/MaybeNotSetupUser/useMaybeNotSetupUser"

const useAdmin = () => {
  const maybeNotSetup = useMaybeNotSetupUser()

  if (!maybeNotSetup.user) {
    throw new Error("User is not setup")
  }

  if (maybeNotSetup.user.type !== "admin") {
    throw new Error("User is not an admin")
  }

  const user = maybeNotSetup.user as unknown as IAdmin

  return user
}

export default useAdmin
