import { IAccountant } from "api/types"
import { EmploymentIcon } from "assets/icons"
import { availableCertifications, availableReviews } from "constants/certifications"
import OverviewCard from "pages/accountant/Profile/components/OverviewCard"
import Certification from "pages/accountant/Profile/components/ProfileCard/Certification"
import LinkedIn from "pages/accountant/Profile/components/ProfileCard/LinkedIn"
import Review from "pages/accountant/Profile/components/ProfileCard/Review"
import Section from "pages/accountant/Profile/components/ProfileCard/Section"
import { FC } from "react"
import { useNavigate } from "react-router"
import { activityStatusByDate } from "utils/activityStatusByDate"

interface props {
    user: IAccountant
}

const capitalize = (s?: string) => s ? s.charAt(0).toUpperCase() + s.slice(1) : undefined

const AccountantShortProfile: FC<props> = ({ user }) => {
  const { city, state } = user
  const noCertifications = !Object.keys(user.certifications || {}).length
  const noReviews = !Object.keys(user.reviews || {}).length

  const navigate = useNavigate()

  const goToProfile = () => {
    navigate(`/accountant/${user.id}`)
  }

  const knowledge = user.skillScore > 80 ? "high" : user.skillScore > 20 ? "average" : "low"
  const activity = activityStatusByDate(user.xpData.lastActivity || user.xpData.dateJoined)
  const speed = user.speed

  return (
    <div className="w-full h-full flex flex-col relative">
      <div className="p-5 border-b border-b-border-section font-medium text-lg">
        <span>About <span className="text-primary-purple hover:underline cursor-pointer" onClick={goToProfile}>{user.name}</span></span>
      </div>
      <div className="grow w-full h-full overflow-y-auto">
        <div className="flex flex-col p-3 gap-2.5">
          <div className="w-full px-3">
            <OverviewCard knowledge={knowledge} activity={activity} speed={speed} />
          </div>
          <div className="w-full h-px bg-[#151D4814]" />
          <LinkedIn user={user} />
          <div className="rounded-lg flex flex-col bg-[#F5F8FB] py-3 px-4 gap-1.5">
            {!user.enterprise && (
              <>
                <div className="flex items-center gap-1 py-1.5">
                  <EmploymentIcon />
                  <span className="font-light text-text-secondary text-xs">
                    EMPLOYMENT
                  </span>
                  <span className="text-sm text-text-secondary grow text-right">
                    {user.employmentType}
                  </span>
                </div>
                <div className="w-full h-px bg-[#151D4814]" />
              </>
            )}
            <div className="flex items-center gap-1 py-1.5">
              <div className="w-4 h-4 relative">
                <div
                  className="rounded-full border-4
                   border-primary-purple w-1 h-1 box-content absolute top-1.5 left-1/2 bg-white -translate-x-1/2 -translate-y-1/2 z-10"
                />
                <div
                  className="border-4 border-b-primary-purple border-l-primary-purple
                 -rotate-45 w-2 h-2 rounded-sm absolute top-1.5 left-1/2 -translate-x-1/2 -translate-y-px"
                />
              </div>
              <span className="font-light text-text-secondary text-xs">
                LOCATION
              </span>
              <span className="text-sm text-text-secondary grow text-right">
                {capitalize(city && state ? `${city.toLocaleUpperCase()}, ${state.toUpperCase()}` : city || state.toUpperCase() || "---")}
              </span>
            </div>
          </div>
          <Section header="Certifications" initialExpanded>
            {noCertifications ? (
              <span className="text-sm font-normal text-slate-500 w-full py-4 px-3">
                No certifications added
              </span>
            ) : (
              <div className="flex flex-col gap-2 p-3 pt-0">
                {availableCertifications.map(name => (
                  <Certification
                    key={name}
                    name={name}
                    user={user}
                  />
                ))}
              </div>
            )}
          </Section>
          <Section header="Reviews" initialExpanded>
            <div className="flex flex-col gap-2 p-3 pt-0">
              {noReviews ? (
                <span className="text-sm font-normal text-slate-500 w-full py-1">
                  No reviews yet
                </span>
              ) : (availableReviews.map(name => (
                <Review key={name} name={name} user={user} />
              )))}
            </div>
          </Section>
          <Section
            header={
              <div className="flex items-center justify-between grow">
                <span>
                  Basic Info
                </span>
              </div>
            }
          >
            <div className="flex flex-col gap-2 p-3 pt-0">
              {user.email && (
                <>
                  <div className="flex flex-col gap-0.5">
                    <span className="text-sm text-[#4848498A]">
                      Email
                    </span>
                    <input value={user.email} readOnly className="text-sm font-medium text-[#484849] bg-transparent outline-none" />
                  </div>
                  <div className="w-full h-px bg-[#151D4814]" />
                </>
              )}
              <div className="flex flex-col gap-0.5">
                <span className="text-sm text-[#4848498A]">
                  Software Knowledge
                </span>
                <pre className="text-sm font-medium text-[#484849] font-sans">
                  {Object.keys(user.softwareExperience).join("\n") || "---"}
                </pre>
              </div>
              <div className="w-full h-px bg-[#151D4814]" />
              <div className="flex flex-col gap-0.5">
                <span className="text-sm text-[#4848498A]">
                  About {user.name}
                </span>
                <pre className="text-sm font-medium text-[#484849] font-sans whitespace-pre-line">
                  {user.bio || "---"}
                </pre>
              </div>
            </div>
          </Section>
        </div>
      </div>
    </div>
  )
}

export default AccountantShortProfile
