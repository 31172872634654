import api from "api"
import { IUser } from "api/types"
import Loader from "components/loaders/Loader"
import { FC, useEffect, useState } from "react"
import AccountantShortProfile from "./Accountant"
import useUser from "contexts/User/useUser"

interface props {
    user: string
}

const ShortProfile: FC<props> = ({ user: uid }) => {
  const me = useUser()
  const [user, setUser] = useState<IUser | null | undefined>()
  useEffect(() => {
    if (me.type === "accountant") return
    setUser(undefined)
    api.getProfile(uid).then(setUser)
  }, [uid, me.type])

  if (user === null || (user !== undefined && user.type !== "accountant") || me.type === "accountant") {
    return null
  }

  return (
    <div className="w-[20rem] shrink-0 bg-white h-full rounded-2.5 overflow-y-auto drop-shadow-xl">
      {user === undefined
        ? <div className="flex items-center justify-center w-full h-full"><Loader /></div> : (
          user.type === "accountant" ? (
            <AccountantShortProfile user={user} />
          ) : (
            null

          /*<NotAccountantShortProfile user={user} />*/
          )
        )}
    </div>
  )
}

export default ShortProfile
