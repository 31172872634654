import { IAccountant } from "api/types"
import { CertificationIcon, PenIcon } from "assets/icons"
import { availableCertifications } from "constants/certifications"
import { handleError } from "helpers/errors"
import { FC, MouseEvent, useEffect, useState } from "react"

interface props {
    name: typeof availableCertifications[number]
    disabled?: boolean
    user: IAccountant
    change?: (name: string, url: string) => void
}

const Certification: FC<props> = ({ name, user, disabled, change }) => {
  const editable = !!change
  const [updating, setUpdating] = useState(false)
  const [editing, setEditing] = useState(false)
  const [url, setUrl] = useState<string | undefined>(user.certifications?.[name])

  useEffect(() => {
    if (!disabled) {
      setUpdating(false)
    }
  }, [disabled])

  const onEdit = (e: MouseEvent) => {
    e.stopPropagation()
    e.preventDefault()
    if (url && !url.includes("credly.com/badges/")) {
      handleError("Enter a valid Credly Link")

      return
    }
    if (updating) return
    setUpdating(true)
    setEditing(false)
    change?.(name, url || "")
  }

  const present = !!user.certifications?.[name]

  if (!present && !editable) return null

  if (!editable) {
    return (
      <a
        href={url}
        target="_blank"
        rel="noreferrer"
        className={[
          "flex cursor-pointer transition-colors",
          "bg-primary-section-dark hover:bg-[#5D5FEF08]",
          "rounded-2.5 py-2 px-3 items-center justify-center gap-2.5 border border-[#5D5FEF26]",
        ].asClass}
      >
        <CertificationIcon className="w-6 h-6" />
        <span
          className={[
            "text-xs font-normal text-primary-purple w-max truncate grow",
            "text-primary-purple",
          ].asClass}
        >
          {name}
        </span>
      </a>
    )
  }

  return (
    <a
      href={url || "#"}
      target="_blank"
      rel="noreferrer"
      className={[
        "flex cursor-pointer transition-colors",
        present ? "bg-primary-section-dark [&:hover:not(:has(label:hover))]:bg-[#5D5FEF08]"
          : "border-dashed bg-[#5D5FEF08] [&:hover:not(:has(label:hover))]:bg-primary-section-dark",
        "rounded-2.5 py-2 px-3 items-center justify-center gap-2.5 border border-[#5D5FEF26]",
        (disabled || updating) && "pointer-events-none",
        (updating) && "animate-pulse",
      ].asClass}

    >
      <CertificationIcon className="w-6 h-6" />
      {editing ? (
        <input
          type="text"
          value={url}
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          onChange={e => setUrl(e.target.value)}
          placeholder={name}
          className="text-xs font-normal !text-primary-blue truncate grow shrink !min-w-0
           border-none bg-transparent focus:outline-none !p-0 !rounded-none"
        />
      ) : (
        <span
          className={[
            "text-xs font-normal text-primary-purple w-max truncate grow",
          ].asClass}
        >
          {name}
        </span>
      )}
      {editing ? (
        <button
          onClick={onEdit}
          className="text-primary-purple text-base rounded-full hover:bg-[#5D5FEF26]
           transition-colors p-1.5 -mr-1.5 w-6 h-6 flex items-center justify-center"
        >
          ✓
        </button>
      ) : (
        <label
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            setEditing(true)
          }}
          htmlFor={name}
          className="p-1 rounded-full hover:bg-[#5D5FEF26] transition-colors cursor-pointer"
        >
          <PenIcon className="w-4 h-4" />
        </label>
      )}
    </a>
  )
}

export default Certification
