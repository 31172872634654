import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { IAccountant, IBadgeTranslation } from "./types"

class EnterpriseApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async createEmployee(data: { email: string, firstName: string, lastName: string }) {
    return this.api.post("/enterprise/employees", data).then(res => res.data as IAccountant)
  }

  async getEmployeeUpdates(id: string) {
    return this.api.get(`/enterprise/employee/${id}/updates`).then(res => res.data as {skills: Record<string, IBadgeTranslation> })
  }
}

const enterpriseApi = new EnterpriseApi()

export default enterpriseApi
