import { IAccountant } from "api/types"
import { FC, useMemo, useState } from "react"
import useElementDimensions from "./useElementDimensions"
import Tooltip from "components/Tooltip"

interface props {
    user: IAccountant
}

const LifetimeSkillChart: FC<props> = ({ user }) => {
  const [wrapperRef, setWrapperRef] = useState<HTMLDivElement | null>(null)
  const wrapperSize = useElementDimensions(wrapperRef)

  const skillsEarnedByDay = useMemo(() => {
    const skillsByDay = user.activityOverview.skillsEarned.reduce((acc, curr) => {
      const date = new Date(curr.createdAt).toDateString()
      if (!acc[date]) {
        acc[date] = 0
      }
      acc[date]++

      return acc
    }, {} as Record<string, number>)

    const earliestDate = new Date(Math.min(...user.activityOverview.skillsEarned.map(sk => new Date(sk.createdAt).getTime())))
    const nowDate = new Date().toDateString()

    const filled = Array.from({
      length: Math.ceil((new Date(nowDate).getTime() - earliestDate.getTime()) / (1000 * 60 * 60 * 24)) + 1,
    }).map((_, i) => {
      const date = new Date(earliestDate.getTime() + i * (1000 * 60 * 60 * 24)).toDateString()

      return { date, count: skillsByDay[date] || 0, label: date.split(" ").slice(1, 3).join(" ") }
    })

    return filled
  }, [user.activityOverview.skillsEarned])

  const groupedByMonth = useMemo(() => {
    const mapped = skillsEarnedByDay.reduce((acc, curr) => {
      const [, month,, year] = curr.date.split(" ")
      const monthYear = `${month} ${year}`
      if (!acc[monthYear]) {
        acc[monthYear] = 0
      }
      acc[monthYear] += curr.count

      return acc
    }, {} as Record<string, number>)

    return Object.entries(mapped).map(([label, count]) => ({ label, count }))
  }, [skillsEarnedByDay])

  const sensibleSource: {label: string, count: number, date?: string}[] = useMemo(() => {
    const source = groupedByMonth.length > 3 ? groupedByMonth : skillsEarnedByDay

    const cumulative: number[] = []
    source.forEach((curr, i) => {
      if (i === 0) {
        cumulative.push(curr.count)

        return
      }

      cumulative.push(cumulative[i - 1] + curr.count)
    }, [] as number[])

    return source.map((sk, i) => ({ label: sk.label, count: cumulative[i] }))
  }, [groupedByMonth, skillsEarnedByDay])

  const ySteps = 5
  const yMax = Math.max(...sensibleSource.map(sk => sk?.count)) + 10
  const xSteps = Math.min(10, sensibleSource.length)

  const wrapperWidth = wrapperSize?.width || 0
  const wrapperHeight = wrapperSize?.height || 0

  const sourceColWidth = sensibleSource.length > 0 ? wrapperWidth / sensibleSource.length : 0

  return (
    <div className="bg-white col-span-2 flex flex-col rounded-2.5 p-4 min-h-full drop-shadow-md">
      <div className="flex items-center border-b-[0.3px] border-b-[rgba(211, 224, 235, 1)] pb-4">
        <span className="text-base text-primary-blue font-semibold">Lifetime Skill Growth Chart</span>
      </div>
      {sensibleSource.length === 0 ? (
        <div className="w-full flex items-center justify-center grow">
          <span className="text-text-secondary text-sm font-light">No data available</span>
        </div>)
        : (
          <div className="flex gap-4 pt-8">
            <div className="w-max flex flex-col justify-between h-[17rem] -translate-y-[0.5rem]">
              {Array.from({ length: ySteps + 1 }).map((_, i) => (
                <span key={i} className="text-xs text-text-secondary font-light">
                  {Math.round((yMax / ySteps) * (ySteps - i))}
                </span>
              ))}
            </div>
            <div className="grow">
              <div className="grow h-[16rem]" ref={setWrapperRef}>
                <svg
                  viewBox={[0, 0, wrapperWidth, wrapperHeight].join(" ")}
                  className="w-full h-full"
                  fill="none"
                  preserveAspectRatio="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  {Array.from({ length: ySteps + 1 }).map((_, i) => (
                    <path
                      key={i}
                      d={`M 0 ${wrapperHeight / ySteps * i} L ${wrapperWidth} ${wrapperHeight / ySteps * i}`}
                      stroke="rgba(240, 244, 249, 1)"
                      strokeWidth="1"
                    />
                  ))}
                  <path
                    d={`M 0 ${wrapperHeight} L ${wrapperWidth} ${wrapperHeight}`}
                    stroke="#D3E0EB"
                    strokeWidth="1"
                  />
                  <mask id="maskForChart">
                    <path
                      d={`M 0 ${wrapperHeight * (1 - ((sensibleSource[0]?.count || yMax) / yMax))} 
                      ${
          sensibleSource.map((sk, i) => `L ${(i+0.5) * sourceColWidth} ${wrapperHeight * (1 - sk.count / yMax)}`).join(" ")
          } 
                      L ${wrapperWidth} ${wrapperHeight * (1 - ((sensibleSource[sensibleSource.length - 1]?.count || yMax) / yMax))}
                        L ${wrapperWidth} ${wrapperHeight} 
                        L 0 ${wrapperHeight}
                      `}
                      // stroke="rgba(97, 99, 240, 1)"
                      fill="white"
                    />
                  </mask>
                  <linearGradient id="gradientForChart" x1="0" x2="0" y1="0" y2="1">
                    <stop stopColor="rgba(94, 96, 240, 0.3)" offset="25.87%" />
                    <stop stopColor="rgba(144, 145, 245, 0)" offset="97.98%" />
                  </linearGradient>
                  <rect x="0" y="0" width={wrapperWidth} height={wrapperHeight} fill="url(#gradientForChart)" mask="url(#maskForChart)" />
                  <path
                    d={`M 0 ${wrapperHeight * (1 - ((sensibleSource[0]?.count || yMax) / yMax))} ${
                      sensibleSource.map((sk, i) => `L ${
                        (i+0.5) * sourceColWidth
                      } ${wrapperHeight * (1 - (sk.count / yMax))}`).join(" ")
                    } L ${wrapperWidth} ${wrapperHeight * (1 - ((sensibleSource[sensibleSource.length - 1]?.count || yMax) / yMax))}`}
                    stroke="rgba(97, 99, 240, 1)"
                    strokeWidth="4"
                  />
                  {sensibleSource.map((sk, i) => (
                    i % Math.round(sensibleSource.length / xSteps) === 0 && (
                      <foreignObject
                        key={i}
                        x={(i+0.5) * sourceColWidth - 4}
                        y={wrapperHeight * (1 - (sk.count / yMax)) - 4}
                        width="8"
                        height="8"
                      >
                        <Tooltip
                          key={i}
                          position="top"
                          text={`${sk.label}, ${sk.count} Skill${sk.count !== 1 ? "s" : ""}`}
                        >
                          <svg>
                            <circle
                              key={i}
                              r="4"
                              fill="rgba(97, 99, 240, 1)"
                              cx="4"
                              cy="4"
                            />
                          </svg>
                        </Tooltip>
                      </foreignObject>
                    )))}
                </svg>
              </div>
              <div
                className="w-full grid pt-2"
                style={{
                  gridTemplateColumns: `repeat(${xSteps}, 1fr)`,
                }}
              >
                {Array(xSteps).fill(null).map((_, i) => {
                  const entry = sensibleSource[Math.floor((((i+0.5) * sensibleSource.length) / xSteps))]

                  if (!entry) return null

                  return (
                    <span key={i} className="text-xs text-text-secondary font-light w-full text-center relative">
                      {entry.label}
                    </span>
                  )
                })}
              </div>
            </div>
          </div>)}
    </div>
  )
}

export default LifetimeSkillChart
