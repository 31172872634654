import { FC, useMemo } from "react"
import { activityStatusByDate } from "utils/activityStatusByDate"
import { highActivityCutoff, lowActivityCutoff } from "constants/xp"
import GroupComparison from "pages/accountant/Profile/components/GroupComparison"
import LifetimeSkillChart from "pages/accountant/Profile/components/LifetimeSkillChart/LifetimeSkillChart"
import MetricCard from "pages/accountant/Profile/components/MetricCard"
import OverviewCard from "pages/accountant/Profile/components/OverviewCard"
import ProfileCard from "pages/accountant/Profile/components/ProfileCard"
import RecentTrainingActivity from "pages/accountant/Profile/components/RecentTrainingActivity"
import SkillGroupsBreakdown from "pages/accountant/Profile/components/SkillGroupsBreakdown"
import { IAccountant } from "api/types"
import { useNavigate } from "react-router"
import { ArrowBackIcon } from "assets/icons"

const LoadedAccountantProfilePage: FC<{user: IAccountant}> = ({ user }) => {
  const navigate = useNavigate()
  const knowledge = user.skillScore > 80 ? "high" : user.skillScore > 20 ? "average" : "low"
  const activity = activityStatusByDate(user.xpData.lastActivity || user.xpData.dateJoined)
  const speed = user.speed

  const activityDescription = useMemo(() => {
    if (activity === "low") {
      return "Over " + lowActivityCutoff + " days ago"
    }
    if (activity === "average") {
      return "Within " + lowActivityCutoff + " days"
    }

    return "Within " + highActivityCutoff + " days"
  }, [activity])

  const addedPastWeek = user.activityOverview.skillsEarned.filter(skill => {
    const date = new Date(skill.createdAt)
    const today = new Date()
    const diffTime = Math.abs(today.getTime() - date.getTime())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

    return diffDays <= 7
  }).length

  const totalSkills = user.activityOverview.skillsEarned.length

  return (
    <div className="w-full h-full overflow-hidden p-5 flex flex-col">
      <div className="flex items-center pb-5 gap-2">
        <button
          onClick={() => navigate(-1)}
          className="flex items-center gap-2 p-2 hover:bg-white
           transition-all hover:drop-shadow-sm rounded-full text-primary-blue"
        >
          <ArrowBackIcon />
        </button>
        <span className="font-semibold text-2xl text-primary-blue">Accountant Profile</span>
      </div>
      <div className="grow w-full overflow-y-auto border-t-[0.3px] border-t-[#D3E0EB] pt-4 relative">
        <div
          className="flex grow w-full gap-4 overflow-x-hidden"
        >
          <ProfileCard user={user} />
          <div className="grid grid-cols-2 2xl:grid-cols-4 gap-4 grow h-max pb-10">
            <OverviewCard activity={activity} speed={speed} knowledge={knowledge} />
            <MetricCard
              metric="knowledge"
              value={knowledge}
              numberValue={totalSkills}
              description={`${totalSkills} skill${totalSkills !== 1 ? "s" : ""} verified`}
            />
            <MetricCard
              metric="consistency"
              value={activity}
              description={activityDescription}
            />
            <MetricCard
              metric="speed"
              value={speed}
              numberValue={addedPastWeek}
              description={`${addedPastWeek} skill${addedPastWeek !== 1 ? "s" : ""} within a week`}
            />
            <SkillGroupsBreakdown user={user} />
            <GroupComparison user={user} />
            <LifetimeSkillChart user={user} />
            <RecentTrainingActivity user={user} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoadedAccountantProfilePage
