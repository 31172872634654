import { FC, PropsWithChildren } from "react"
import Context from "./talentFinderContext"
import useTalentFinder from "hooks/useTalentFinder"

const TalentFinderProvider: FC<PropsWithChildren> = (({ children }) => {
  const out = useTalentFinder()

  return (
    <Context.Provider value={out}>
      {children}
    </Context.Provider>
  )

})

export default TalentFinderProvider
