import { AxiosInstance } from "axios"
import { noAuthAxios } from "./axios"
import { IAccountant } from "./types"

class ShareApi {
  api: AxiosInstance
  constructor() {
    this.api = noAuthAxios
  }

  async getSharedAccountantProfile(token: string) {
    return this.api.get("/share/profile/", { headers: { Authorization: `Bearer ${token}` } }).then(res => res.data as IAccountant)
  }

}

const shareApi = new ShareApi()

export default shareApi
