import enterpriseApi from "api/enterprise"
import { ITalentFinderEntry } from "api/types"
import { BadgeIcon } from "assets/icons"
import ProfileImage from "components/ProfileImage"
import { skillColors } from "constants/skills"
import { FC, useState } from "react"
import { useNavigate } from "react-router"
import Diff from "./modals/Diff"
import useAwaitableModal from "hooks/useAwaitableModal"
import Badge from "../TalentFinder/Badge"
import { availableCertifications } from "constants/certifications"
import { TopTalentIcon, GoodTalentIcon, PoorTalentIcon } from "assets/images"

interface props {
    talent: ITalentFinderEntry,
}

const Employee: FC<props> = ({ talent }) => {
  const navigate = useNavigate()
  const [submitting, setSubmitting] = useState(false)
  const [showDiff, DiffModal] = useAwaitableModal(Diff, {} as any)
  const [addedSkillsPreview, setAddedSkillsPreview] = useState(talent.addedSkillsPreview)

  const goTo = talent.preview ? undefined : () => {
    navigate(`/accountant/${talent.id}`, { state: { showBack: true } })
  }

  const totalUpskills = addedSkillsPreview?.total || 0

  const upSkills = Object.entries(addedSkillsPreview?.skills || {})

  const more = totalUpskills - upSkills.length

  const onShowDiff = () => {
    if (submitting) return
    setSubmitting(true)
    enterpriseApi.getEmployeeUpdates(talent.id)
      .then(d => {
        showDiff({ ...d, name: talent.preview ? undefined : talent.name })
        setAddedSkillsPreview({ skills: {}, total: 0 })
      }).finally(() => setSubmitting(false))
  }

  const [_city, _state] = talent.location?.split(", ") || []
  const city = _city?.toLowerCase() || ""
  const state = _state?.toUpperCase() || ""
  const location = [city, state].filter(Boolean).join(", ")

  const knowledge = talent.skillScore > 80 ? "high" : talent.skillScore > 20 ? "average" : "low"
  const activity = talent.activity
  const speed = talent.speed

  const icon = talent.top === "high" ? <TopTalentIcon /> : talent.top === "average" ? <GoodTalentIcon /> : <PoorTalentIcon />

  const rankingText = {
    low: "Poor Candidate",
    average: "Good Candidate",
    high: "Top Candidate",
  }

  return (
    <tr
      style={{ height: 0 }}
      className={[!talent.preview && "cursor-pointer hover:opacity-75 transition-all"].asClass}
    >
      {DiffModal}
      {
        talent.preview ? (
          <td className="blur-md">
            <div className="flex items-center gap-2 relative group">
              <ProfileImage isPowerTeam={talent.isPowerTeam} size={34} className="border-2 p-px drop-shadow-md" />
              <ProfileImage
                size={100}
                className="border-2 p-px pointer-events-none opacity-0 group-hover:opacity-100 transition-all
                 absolute left-0 top-1/2 -translate-y-1/2"
              />
              <span>Johnny Doeey</span>
            </div>
          </td>
        ) : (
          <td
            onClick={goTo}
          >
            <div className="flex items-center gap-2 relative group">
              <ProfileImage size={34} isPowerTeam={talent.isPowerTeam} src={talent.image} className="drop-shadow-md" />
              <ProfileImage
                src={talent.image}
                size={100}
                className="border-2 p-px pointer-events-none opacity-0 group-hover:opacity-100 transition-all
                 absolute left-0 top-1/2 -translate-y-1/2"
              />
              <span>{talent.name}</span>
            </div>
          </td>)
      }
      <td
        className="capitalize w-max"
      >
        {!totalUpskills ? "No upskills" : (
          <div className="flex items-center gap-2 cursor-pointer w-max" onClick={onShowDiff}>
            {upSkills.map(([tag, { name, group }], i) => {
              const color = skillColors[group as keyof typeof skillColors]

              if (!color || !name) return null

              const backColor = color + "1a"

              return (
                <div
                  key={`${tag}${name}${group}`}
                  className="flex gap-2 items-center rounded-full px-3 py-2 text-sm"
                  style={{ backgroundColor: backColor }}
                >
                  <BadgeIcon fill={color} className="w-4 h-4" />
                  <span style={{ color: color }}>
                    {name}
                  </span>
                </div>
              )
            })}
            {more > 0 && (
              <button
                className="w-10 h-10 rounded-full flex items-center justify-center text-white font-medium
                 text-center bg-indigo-500 hover:bg-indigo-600 transition-colors"
              >
                +{more}
              </button>
            )}
          </div>
        )}
      </td>
      <td
        onClick={goTo}
        className="capitalize"
      >
        {location}
      </td>
      <td
        className="!text-center"
        onClick={goTo}
      >
        {talent.certifications.length} / {availableCertifications.length}
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        <div className="flex justify-center">
          <Badge level={knowledge}>{knowledge}</Badge>
        </div>
      </td>
      <td
        onClick={goTo}
        className="!text-center"
      >
        <div className="flex justify-center">
          <Badge level={activity}>{activity}</Badge>
        </div>
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        <div className="flex justify-center">
          <Badge level={speed}>{speed}</Badge>
        </div>
      </td>
      <td
        onClick={goTo}
        className="text-center"
      >
        <div className="flex justify-center">
          <Badge level={talent.top} className="!w-[14rem] shrink-0">
            <div className="flex items-center justify-center gap-2">
              {icon}
              {talent.rank}
            </div>
          </Badge>
        </div>
      </td>
    </tr>
  )
}

export default Employee
