import recruiterApi from "api/recruiter"
import { ITalentPreview } from "api/types"
import useUser from "contexts/User/useUser"
import { handleError } from "helpers/errors"
import { useCallback, useEffect, useRef, useState } from "react"
import { useLocation } from "react-router-dom"

const useTalentFinder = () => {
  const [loading, setLoading] = useState(false)
  const lastPathRef = useRef<string>()
  const [talents, setTalents] = useState<ITalentPreview[]>()
  const [page, setPage] = useState(0)
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [error, setError] = useState(false)
  const location = useLocation()
  const currentSearch = useRef<string>("")
  const scroll = useRef<number>(0)
  const user = useUser()

  const fetchTalents = useCallback(async(page: number, employees?: boolean) => {
    // @ts-ignore
    const noAccess = !employees && user.isEnterprise && !user.hasTalentFinder
    if (noAccess) return
    if (error) return
    setLoading(true)
    setPage(page)
    recruiterApi.getTalents(page + 1).then(res => {
      if (employees && window.location.pathname === "/employees") {
        setTalents(res.talents)
        res.total !== undefined && setTotal(res.total)
        res.pages !== undefined && setPages(res.pages)
      }
      if (!employees && window.location.pathname === "/talent") {
        setTalents(res.talents)
        res.total !== undefined && setTotal(res.total)
        res.pages !== undefined && setPages(res.pages)
      }
    })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false)
      })
  }, [error])

  useEffect(() => {
    if (lastPathRef.current === location.pathname) return
    if (!["/talent", "/employees"].includes(location.pathname)) return
    lastPathRef.current = location.pathname
    setTalents(undefined)
    setPages(0)
    fetchTalents(0, location.pathname === "/employees")
    scroll.current = 0
  }, [location, fetchTalents])

  const forceRefetch = useCallback(async(page: number) => {
    setError(false)
    setLoading(true)
    setPage(page)
    recruiterApi.getTalents(page + 1).then(res => {
      setTalents(res.talents)
      res.total !== undefined && setTotal(res.total)
      res.pages !== undefined && setPages(res.pages)
    })
      .catch(() => setError(true))
      .finally(() => {
        setLoading(false)
      })
  }, [])

  useEffect(() => {
    if (!window.location.pathname.startsWith("/talent")) return
    if (currentSearch.current !== location.search) forceRefetch(page)
    currentSearch.current = location.search
    scroll.current = 0
  }, [location.search])

  useEffect(() => {
    fetchTalents(page)
  }, [page, fetchTalents])

  return {
    loading,
    talents,
    page,
    pages,
    setPage,
    scroll,
    total,
  }
}

export default useTalentFinder
