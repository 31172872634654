import Modal from "components/Modal"
import useRecruiter from "contexts/User/useRecruiter"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useNavigate } from "react-router"

const Subscribe: AwaitableModal<{}, {}> = ({ open, resolve, onCancel }) => {
  const recruiter = useRecruiter()
  const navigate = useNavigate()

  const upgrade = !!recruiter.subscription?.plan

  const onSubscribe = () => navigate("/subscription")

  return (
    <Modal open={open} close={resolve}>
      <div
        className="bg-white rounded-3xl divide-y divide-y-slate-200 overflow-hidden
      flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px] max-h-[calc(100vh-64px)]"
      >
        {!upgrade ? (
          <main className="flex-cool p-8 items-center justify-center">
            <span className="text-2xl font-medium text-slate-900">Subscription Required</span>
            <span className="text-sm text-slate-900 mt-2">Check Out Our Plans to See Which One Fits You Best</span>
          </main>
        ): (
          <main className="flex-cool p-8 items-center justify-center">
            <span className="text-2xl font-medium text-slate-900">Upgrade Subscription</span>
            <span className="text-sm text-slate-900 mt-2">Learn About The Benefits of Our Different Plans</span>
          </main>
        )}
        <footer className="p-8 flex items-center justify-center">
          <button onClick={onSubscribe} className="btn-primary">{upgrade ? "Upgrade Now" : "See Plans"}</button>
        </footer>
      </div>
    </Modal>
  )
}

export default Subscribe
