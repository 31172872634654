import api from "api"
import { ICandidate } from "api/types"
import { useCallback, useEffect, useState } from "react"

const useCandidates = () => {
  const [candidates, setCandidates] = useState<ICandidate[]>()
  const [changes, setChanges] = useState<Record<string, {state?: ICandidate["state"], inactive?: boolean, note?:string}>>({})
  const [patching, setPatching] = useState(false)

  useEffect(() => {
    api.getCandidates().then(ca => setCandidates(ca))
  }, [])

  const patchUpdates = useCallback(async(patchOrder = true) => {
    if (patching) return
    setPatching(true)
    setChanges(old => {
      setCandidates(oldC => {
        api.updateCandidates({
          changes: old,
          newOrder: patchOrder ? oldC!.map(c => c.id) : undefined,
        }).then(() => setChanges({})).finally(() => setPatching(false))

        return oldC
      })

      return old
    })

  }, [patching])

  return {
    candidates,
    setCandidates,
    setChanges,
    patchUpdates,
    patching,
  }
}

export default useCandidates
