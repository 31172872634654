import { PropsWithChildren, forwardRef, useEffect, useState, useImperativeHandle, useRef } from "react"
import MaybeNotSetupUserContext, { IMaybeNotSetupUserContext } from "./maybeNotSetupUserContext"
import { IUser } from "api/types"
import useAuthContext from "contexts/Auth/useAuthContext"
import api from "api"
import FullLoader from "components/loaders/FullLoader"
import HelpHero from "config/helphero"
import { HelpHero as HelpHeroType } from "helphero"

const MaybeNotSetupUserProvider = forwardRef<IMaybeNotSetupUserContext, PropsWithChildren>(({ children }, ref) => {
  const [user, setUser] = useState<IUser | null>()
  const auth = useAuthContext()
  const [loading, setLoading] = useState(false)
  const helpHero = useRef<HelpHeroType>()

  useEffect(() => {
    if (loading) return
    if (!auth) {
      setUser(null)
      setLoading(false)

      return
    }
    setLoading(true)
    api.getProfile().then(setUser).catch(() => setUser(null))
  }, [auth, loading])

  useImperativeHandle(ref, () => ({
    user,
    setUser,
  }), [user, setUser])

  useEffect(() => {
    if (!auth) {
      helpHero.current?.reset()
    } else if (user) {
      if (user.type !== "accountant") {
        helpHero.current = HelpHero()

        helpHero.current?.identify(auth.uid, {
          role: user.type,
          created_at: auth.metadata.creationTime,
        })
      }
    }
  }, [user, auth])

  return (
    <MaybeNotSetupUserContext.Provider value={{ user, setUser }}>
      {user === undefined ? <FullLoader /> : children}
    </MaybeNotSetupUserContext.Provider>
  )

})

export default MaybeNotSetupUserProvider
