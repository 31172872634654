import recruiterApi from "api/recruiter"
import Modal from "components/Modal"
import Selector from "components/Selector"
import { handleError } from "helpers/errors"
import { AwaitableModal } from "hooks/useAwaitableModal"
import { useState } from "react"

interface props {
    name: string,
    type: string
}

const EditProfile: AwaitableModal<props, props> = ({ initialData, open, onCancel, resolve }) => {
  const [name, setName] = useState(initialData.name)
  const [type, setType] = useState<string>(initialData.type)
  const [submitting, setSubmitting] = useState(false)

  const onEdit = () => {
    if (submitting) return
    if (!name) return handleError("Please enter your name")
    if (!type) return handleError("Please select your recruiter type")
    setSubmitting(true)
    recruiterApi.updateProfile({ name, type }).then(() => {
      resolve({ name, type })
    }).finally(() => setSubmitting(false))
  }

  return (
    <Modal open={open} close={!submitting ? onCancel : () => {}}>
      <div className="bg-white rounded-3xl divide-y divide-y-slate-200 flex-cool h-max my-12 mx-4 max-w-[768px] min-w-[512px]">
        <header className="flex items-center justify-start gap-2.5 p-8">
          <span className="font-semibold text-2xl text-slate-900">Edit Profile</span>
        </header>
        <main className="flex-cool p-8 gap-8">
          <div className="flex-cool gap-4">
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={e => setName(e.currentTarget.value)}
            />
            <Selector value={type || "Type"}>
              <div
                onClick={e => {
                  setType("Recruiter")
                }}
                className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
              >
                Recruiter
              </div>
              {/*<div
                onClick={e => {
                  setType("Potato")
                }}
                className="px-5 py-4 flex items-center text-sm text-slate-900 cursor-pointer select-none"
              >
                Potato
              </div>*/}
            </Selector>
          </div>
        </main>
        <footer className="p-8 flex items-center justify-between gap-2.5">
          <button className="btn-secondary" disabled={submitting} onClick={onCancel}>
            Cancel
          </button>
          <button disabled={submitting} onClick={onEdit} className="btn-primary">Save Changes</button>
        </footer>
      </div>
    </Modal>
  )
}

export default EditProfile
