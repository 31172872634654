import { FC } from "react"
import { Navigate, Route } from "react-router"
import { Routes } from "react-router"
import ShareRouter from "./share"
import FullLoader from "components/loaders/FullLoader"
import { useSearchParams } from "react-router-dom"

const PublicRouter: FC = () => {
  const [search] = useSearchParams()
  const tokenInUrl = search.get("token")

  if (tokenInUrl) return <FullLoader />

  return (
    <Routes>
      <Route path="share/*" element={<ShareRouter />} />
      <Route path="*" element={<Navigate to="/auth" replace />} />
    </Routes>
  )
}

export default PublicRouter
