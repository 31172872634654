import { IAccountant } from "api/types"
import { FC } from "react"

interface props {
    user: IAccountant
}

const skillGroupsOrder = [3, 4, 1, 8, 6, 7, 9, 5, 2, 10]
const skillGroupColors = [
  ["#BCDCFF", "#2E90FA"],
  ["#F5B9B9", "#FF3636"],
  ["#FFD7A2", "#FF9100"],
  ["#FFBAEB", "#EE46BC"],
  ["#C3ECFF", "#0BA5EC"],
  ["#B9C3FF", "#4E5BA6"],
  ["#FFD8C2", "#FB6514"],
  ["#A4FFD4", "#12B76A"],
  ["#FFB3C5", "#F63D68"],
  ["#E0D1FF", "#9E77ED"],
] as [string, string][]

const RecentTrainingActivity: FC<props> = ({ user }) => {
  const getSkillGroup = (id: number) => {
    const group = user.activityOverview.groupsData.find(g => g.id === id)

    const color = skillGroupColors.find((_, i) => i === skillGroupsOrder.indexOf(id))

    return {
      ...group,
      color,
    }
  }

  return (
    <div className="bg-white col-span-2 flex flex-col rounded-2.5 py-4 min-h-full drop-shadow-md">
      <div className="flex items-center border-b-[0.3px] border-b-[rgba(211, 224, 235, 1)] pb-4 px-4">
        <span className="text-base text-primary-blue font-semibold">Recent Training Activity</span>
      </div>
      <div className="w-full h-[16rem] grow overflow-y-auto px-4">
        <table className="w-full">
          <thead
            className="sticky top-0 [&_th]:text-xs [&_th]:text-[#66628D]
           [&_th]:bg-white [&_th]:font-light [&_th]:uppercase [&_th]:py-2"
          >
            <tr>
              <th>Verified Skill</th>
              <th>Skill Group</th>
              <th>Date</th>
            </tr>
          </thead>
          <tbody>
            {
              user.activityOverview.skillsEarned.length > 0 && (
                user.activityOverview.skillsEarned.map((skill, i) => (
                  <tr key={i} className="[&>*]:py-4 [&>*]:px-2 border-b-8 border-b-white h-0">
                    <td className="text-xs text-text-secondary text-center bg-[#F4F4F4] rounded-l-2.5 !pl-4">
                      <div className="flex items-center gap-2">
                        <div
                          className="w-2 h-2 rounded-full shrink-0"
                          style={{ backgroundColor: getSkillGroup(skill.skillGroupId)?.color?.[1] }}
                        />
                        <span className="text-left">
                          {skill.skillName}
                        </span>
                      </div>
                    </td>
                    <td className="text-xs text-text-secondary text-center bg-[#F4F4F4]">{getSkillGroup(skill.skillGroupId)?.name}</td>
                    <td className="text-xs font-medium text-text-secondary fitwidth text-center bg-[#F4F4F4] rounded-r-2.5 !pr-4">
                      {new Date(skill.createdAt).toLocaleString("en-UK", {
                        day: "numeric",
                        month: "short",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: true,
                        timeZoneName: "short",
                      }).split(", ").reverse().join(", ")}
                    </td>
                  </tr>
                ))
              )
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default RecentTrainingActivity
