import { AxiosInstance } from "axios"
import axios from "./axios"
import firebase from "config/firebase"
import { IBooksmatchStats, IEnterpriseEntry } from "./types"

class AdminApi {
  api: AxiosInstance
  constructor() {
    this.api = axios

    this.api.interceptors.request.use(async config => {
      const bearer = await firebase.auth.currentUser?.getIdToken()
      config.headers.setAuthorization(`Bearer ${bearer}`)

      return config
    })

    this.api.interceptors.response.use(res => {
      return res
    })
  }

  async createEnterprise(data: {email: string, name: string}) {
    return this.api.post("/admin/enterprises", data).then(res => res.data as {
      user: IEnterpriseEntry,
      passwordResetLink?: string
    })
  }

  async getEnterprises() {
    return this.api.get("/admin/enterprises").then(res => res.data as IEnterpriseEntry[])
  }

  async getEnterprisePasswordResetLink(id: string) {
    return this.api.get(`/admin/enterprises/${id}/password-reset`).then(res => res.data as string)
  }

  async updateEnterpriseTalentFinderAccess(id: string, data: {hasTalentFinder: boolean, maxEmployees: number}) {
    return this.api.patch(`/admin/enterprises/${id}`, data).then(res => res.data as IEnterpriseEntry)
  }

  async getStats() {
    return this.api.get("/admin/stats").then(res => res.data as IBooksmatchStats)
  }
}

const adminApi = new AdminApi()

export default adminApi
