import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core"
import { FC, useEffect, useState } from "react"
import { arrayMove } from "@dnd-kit/sortable"
import ColumnContainer from "./Board/Column"
import ReactDOM from "react-dom"
import ProposalCard from "./Board/ProposalCard"
import Toggle from "components/Toggle"
import useCandidates from "hooks/useCandidates"
import { ICandidate } from "api/types"
import FullLoader from "components/loaders/FullLoader"
import accountantApi from "api/accountant"

const CandidatesPage: FC = () => {
  const [contactsOnlyActive, setContactsOnlyActive] = useState(true)
  const [inProgressOnlyActive, setInProgressOnlyActive] = useState(true)
  const [clientsOnlyActive, setClientsOnlyActive] = useState(true)
  const { candidates, setCandidates, setChanges, patchUpdates, patching } = useCandidates()
  const [watchlistCount, setWatchlistCount] = useState<number>()

  const sensors = useSensors(useSensor(PointerSensor, {
    activationConstraint: {
      distance: 10,
    },
  }))

  const [activeTask, setActiveTask] = useState<ICandidate | null>(null)

  useEffect(() => {
    accountantApi.getCandidateListCount().then(setWatchlistCount)
  }, [])

  const loading = candidates === undefined

  return (
    <div className="w-full h-full">
      <div className="pt-5 px-5 flex-cool h-full">
        <div className="flex items-center pb-5 gap-4">
          <span className="font-semibold text-2xl text-primary-blue">Contact Tracker</span>
          <div className="grow" />
          <div className="flex items-center gap-4">
            <span className="text-sm text-slate-500">
              You are on&nbsp;
              <span className="text-sm text-indigo-600 hover:underline font-medium cursor-pointer" onClick={() => {}}>
                {watchlistCount === undefined ? "..." : watchlistCount} Watchlist{watchlistCount === 1 ? "" : "s"}
              </span>
            </span>
          </div>
        </div>
        <DndContext
          sensors={sensors}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={onDragOver}
        >
          <div
            className="flex min-h-min
                   bg-white rounded-2.5 divide-x flex-grow border border-border-section
        divide-x-border-section overflow-hidden
         mobile:flex-col mobile:overflow-y-auto mobile:divide-x-0 mobile:divide-y mobile:divide-y-border-section"
          >
            {loading ? <FullLoader /> : (
              <>
                <ColumnContainer
                  column={{ id: "contact" }}
                  deleteTask={deleteProposal}
                  changeProposalNote={changeProposalNote}
                  proposals={candidates.filter(e => e.state === "contact")}
                >
                  <div className="flex items-center p-4 gap-4">
                    <span className="grow text-base font-medium text-slate-900">Contact List Details</span>
                  </div>
                </ColumnContainer>
                <ColumnContainer
                  column={{ id: "progress" }}
                  deleteTask={deleteProposal}
                  changeProposalNote={changeProposalNote}
                  proposals={candidates.filter(e => e.state === "progress")}
                >
                  <div className="flex items-center p-4 gap-4">
                    <span className="grow text-base font-medium text-slate-900">In Progress</span>
                  </div>
                </ColumnContainer>
                <ColumnContainer
                  column={{ id: "done" }}
                  deleteTask={deleteProposal}
                  changeProposalNote={changeProposalNote}
                  proposals={candidates.filter(e => e.state === "done")}
                >
                  <div className="flex items-center p-4 gap-4">
                    <span className="grow text-base font-medium text-slate-900">Active Clients</span>
                  </div>
                </ColumnContainer>
              </>
            )}
          </div>
          {ReactDOM.createPortal(
            <DragOverlay className="z-10">
              {activeTask && (
                <ProposalCard
                  changeProposalNote={changeProposalNote}
                  proposal={activeTask}
                  deleteTask={deleteProposal}
                />
              )}
            </DragOverlay>,
            document.body,
          )}
        </DndContext>
      </div>
    </div>
  )

  function deleteProposal(id: string) {
    setCandidates(o => o?.filter(c => c.id !== id))
    patchUpdates(false)
  }

  function changeProposalNote(id: string, note: string) {
    setChanges(o => ({ ...o, [id]: { ...o[id], note } }))
    setCandidates(o => o?.map(c => c.id === id ? { ...c, note } : c))
    patchUpdates(false)
  }

  function onDragStart(event: DragStartEvent) {
    if (patching) return
    if (event.active.data.current?.type === "Proposal") {
      setActiveTask(event.active.data.current.proposal)

      return
    }
  }

  function onDragEnd(event: DragEndEvent) {
    patchUpdates()
    setActiveTask(null)

    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveAColumn = active.data.current?.type === "Column"
    if (!isActiveAColumn) return
  }

  function onDragOver(event: DragOverEvent) {
    const { active, over } = event
    if (!over) return

    const activeId = active.id
    const overId = over.id

    if (activeId === overId) return

    const isActiveATask = active.data.current?.type === "Proposal"
    const isOverATask = over.data.current?.type === "Proposal"

    if (!isActiveATask) return

    // Im dropping a Task over another Task
    if (isActiveATask && isOverATask) {
      setCandidates(old => {
        if (!old) return
        const activeIndex = old.findIndex(t => t.id === activeId)
        const overIndex = old.findIndex(t => t.id === overId)

        if (old[activeIndex].state !== old[overIndex].state) {
          old[activeIndex].state = old[overIndex].state

          setChanges(o => ({ ...o, [activeId]: { ...o[activeId], state: old[overIndex].state } }))

          return arrayMove(old, activeIndex, overIndex - 1)
        }

        return arrayMove(old, activeIndex, overIndex)
      })
    }

    const isOverAColumn = over.data.current?.type === "Column"

    // Im dropping a Task over a column
    if (isActiveATask && isOverAColumn) {
      setCandidates(old => {
        if (!old) return
        const activeIndex = old.findIndex(t => t.id === activeId)

        old[activeIndex].state = overId as any

        setChanges(o => ({ ...o, [activeId]: { ...o[activeId], state: overId as any } }))

        return arrayMove(old, activeIndex, activeIndex)
      })
    }
  }
}

export default CandidatesPage
