import CraigProvider from "contexts/Craig/CraigProvider"
import CraigRoute from "contexts/Craig/CraigRoute"
import useHideSupportChat from "hooks/useHideSupportChat"
import CraigChatPage from "pages/accountant/craig/Chat"
import { FC } from "react"

const CraigRouter: FC = () => {
  useHideSupportChat()

  return (
    <CraigProvider>
      <CraigRoute element={<CraigChatPage />} />
    </CraigProvider>
  )
}

export default CraigRouter
