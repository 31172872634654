import firebase from "config/firebase"
import { UploadTask, getDownloadURL, ref, uploadBytesResumable } from "firebase/storage"
import { useCallback, useRef, useState } from "react"

const useTempFile = (finalPath: string) => {
  const tempPath = `temp/${finalPath}`
  const [progress, setProgress] = useState(0)
  const [url, setUrl] = useState<string>()
  const activeTask = useRef<UploadTask | null>(null)

  const setFile = useCallback((file: File) => {
    setProgress(0)
    const fileRef = ref(firebase.storage, tempPath)
    const uploadTask = uploadBytesResumable(fileRef, file)
    activeTask.current?.cancel()
    activeTask.current = uploadTask
    uploadTask.on("state_changed", snapshot => {
      const progress = Math.round((snapshot.bytesTransferred * 100) / snapshot.totalBytes)
      setProgress(Math.min(progress / 100, 0.99))
    })
    uploadTask.then(async({ task }) => {
      activeTask.current = null

      return getDownloadURL(task.snapshot.ref).then(url => {
        setProgress(1)
        setUrl(url)
      })
    })

  }, [tempPath])

  return { progress, url, setFile, cancel: activeTask.current?.cancel }
}

export default useTempFile
