import { Logo } from "assets"
import { BadgeIcon } from "assets/icons"
import Tooltip from "components/Tooltip"
import { FC } from "react"
import { Outlet, useNavigate } from "react-router"

const ShareAppLayout: FC = () => {
  const navigate = useNavigate()

  return (
    <div className="w-full h-full bg-slate-100 flex p-2">
      <div className="h-full rounded-3xl bg-primary-blue flex-cool gap-2.5 items-center py-4 px-4">
        <Logo className="w-[2.75rem] h-[2.75rem] p-1.5 rounded-2xl bg-primary-purple fill-white shrink-0" />
        <div className="grow flex-cool gap-4 w-full px-4 items-center overflow-y-auto relative mt-10" />
        <Tooltip position="right" text="Join Up" className="bg-slate-800">
          <BadgeIcon
            onClick={() => navigate("/auth")}
            className="w-[2.75rem] h-[2.75rem] p-2.5 rounded-full
             fill-white hover:bg-[rgba(255,255,255,0.12)] cursor-pointer transition-color"
          />
        </Tooltip>
      </div>
      <div className="h-full grow w-full overflow-hidden">
        <Outlet />
      </div>
    </div>
  )
}

export default ShareAppLayout
