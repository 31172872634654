import { ICraigMessage } from "api/types"
import { FC } from "react"
import Payload from "./Payload"

interface props {
    message: ICraigMessage;
}

const CraigBubble: FC<props> = ({ message }) => {
  return (
    <div
      className="flex items-center w-full justify-start pr-8"
    >
      <span className="text-base font-normal relative group bg-slate-200 rounded-2xl text-slate-950 p-4">
        <div className="flex flex-col gap-3">
          <span>{message.message}</span>
          {!!message.payload && (<Payload payload={message.payload} />)}
        </div>
        <span
          className={[
            "opacity-0 group-hover:opacity-100 transition-all",
            "w-max absolute bottom-0 text-slate-950 group-hover:translate-y-[150%] text-xs",
            message.user ? "right-0" : "left-0",
          ].asClass}
        >
          {new Date(message.createdAt).toLocaleString()}
        </span>
      </span>
    </div>
  )
}

export default CraigBubble
