/* eslint-disable max-len */
import { FC, useMemo, useRef, useState } from "react"
import usePathFinder from "./usePathFinder"

interface props {
    pieId: string
    index: number
    color: string
    position?: "left" | "right"
}


const PathToGraphArc: FC<props> = ({ index, pieId, color, position }) => {
  const ref = useRef<SVGSVGElement>(null)
  const [start, setStart] = useState<HTMLDivElement | null>(null)
  const point = usePathFinder(pieId, index, start)

  const w = Math.abs(point.x)
  const h = Math.abs(point.y)
  const x = point.x < 0 ? point.x + Math.abs(point.y) : point.x - Math.abs(point.y)
  // const y = point.y < 0 ? point.y + Math.abs(point.x) : point.y - Math.abs(point.x)

  const pathD = useMemo(() => {
    if (w<h) {

      /*if (index % 2 === 0 || true) {
        return `M ${0} ${0} L ${0} ${y} L ${point.x} ${point.y}`
      }*/
      // return `M ${0} ${0} L ${point.x} ${0} L ${point.x} ${point.y}`
      return `M ${0} ${0} L ${point.x} ${point.y}`
    } else {
      return `M ${0} ${0} L ${x} ${0} L ${point.x} ${point.y}`
    }
  }, [w, h, point.x, point.y, x])

  return (
    <div
      ref={setStart}
      className="absolute bottom-0 translate-y-[0.5px] h-0 w-0"
      style={{
        ...(position === "right" ? { left: 0 } : { right: 0 }),
      }}
    >
      <svg
        ref={ref}
        width={w}
        height={1}
        className="transition-all"
        style={{
          overflow: "visible",
        }}
        viewBox={`0 0 ${w} ${1}`}
      >
        <path
          d={pathD}
          stroke={color}
          strokeLinecap="round"
          fill="transparent"
          strokeWidth={1}
        />
        <circle
          cx={point.x}
          cy={point.y}
          r={2}
          fill={color}
        />
      </svg>
    </div>
  )
}

export default PathToGraphArc
